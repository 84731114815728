.companySettings {
  width: 57rem;
}
.companySettings form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.companySettings__leftSide {
  width: 25rem;
}
.companySettings__leftSide .form__DocumentUpload {
  height: 7.5rem;
  background-color: white;
}
.companySettings__leftSide .form__DocumentUpload .dropzoneFile {
  background-size: 3.4rem !important;
}
/* .companySettings__leftSide .form__DocumentUpload .uploadPlaceholder {
  font-size: 1.375rem !important;
  line-height: 1.36 !important;
  word-spacing: 6.2px;
}
.companySettings__leftSide .form__DocumentUpload .fileUpload__button {
  font-size: 1.375rem !important;
} */
.companySettings_rightSide {
  width: 25rem;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.companySettings .group {
  margin-bottom: 3.063rem;
  width: 100%;
}
.companySettings__verified .group{
  margin-bottom: 0rem;
}
.companySettings .group .form-input {
  width: 100%;
  /*border: none !important;*/
}
.companySettings .companySettings_rightSide .group-textarea {
  margin-bottom: 2.5rem;
}
.companySettings
  .companySettings_rightSide
  .group-textarea
  .form-textareaInput {
  height: 13.6875rem;
}
.companySettings_rightSide .blueButton {
  display: initial;
  margin: 0rem;
}

.companySettings__leftSide--uploaded {
  position: relative;
}
.companySettings__leftSide--uploaded label {
  position: absolute;
  top: -1.44rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  font-family: SF Pro Display Regular;
}

/*Date picker*/

.companySettings .react-datepicker-popper {
  z-index: 9999999;
}
.companySettings .react-datepicker-wrapper {
  width: 100%;
  margin-bottom: 3.063rem;
}
.companySettings .react-datepicker__input-container input {
  width: 100%;
  height: 3.125rem;
  border-radius: 1.563rem;
  /* border: none; */
  background-color: transparent;
  padding-left: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  position: relative;
  z-index: 9999;
}
.companySettings .react-datepicker__input-container input:focus {
  border: 2px solid #2699fb !important;
}
.companySettings
  .react-datepicker__input-container
  input:focus
  ~ .companySettings__labelClass,
.companySettings
  .react-datepicker__input-container
  input:valid
  ~ .companySettings__labelClass {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
}

.companySettings__dateLabel {
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}

.companySettings__labelClass {
  line-height: 1.31 !important;
  color: #06016c;
  position: absolute;
  top: 0.9rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  left: 1.5rem;
  display: block;
  /*z-index: -1;*/
}
.companySettings__labelClass--exp {
  top: -1.5em;
  left: 0rem;
  color: #7977a4;
  cursor: pointer;
  position: absolute;
}
.companySettings__borderStyle {
  border: solid 2px rgba(121, 119, 164, 0.5) !important;
  font-size: 1rem !important;
  line-height: 1.31 !important;
  color: #06016c !important;
  border-radius: 25px !important;
  padding: 0rem 1.25rem 0rem 1.25rem !important;
  background-color: transparent;
}
.companySettings .react-datepicker__input-container {
  background-color: white;
  border-radius: 1.563rem;
  height: 3.125rem;
}
.companySettings .fileUpload .fileAside{
  background-color: transparent;
}
.registrationDate__borderStyle{
  font-size: 1.125rem !important;
  line-height: 1.33 !important;
  color: #06016c !important;
  border-radius: 1.563rem !important;
  padding: 0rem 1.25rem 0rem 1.25rem !important;
  /* background-color: transparent; */
  background-color: white !important;
  border: none !important;
}
.popover__unverified{
  position: absolute;
  right: -8.188rem;
  top: -0.188rem;
  background: #06016c;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-family: SF Pro DisplayMedium;
  color: white;
  letter-spacing: 0.1rem;
  box-shadow: -0.1rem 0.6rem 1.5rem 0 rgb(6 1 108 / 19%);

}
@media only screen and (max-width: 500px) {
  .companySettings__leftSide .form__DocumentUpload .dropzoneFile {
    background-size: 7.4rem !important;
  }
  .companySettings__leftSide--uploaded label {
    top: -4rem;
    font-size: 3rem;
  }
  .companySettings .group {
    margin-bottom: 7.063rem;
  }
  .companySettings__verified .group {
    margin-bottom: 0;
}
.mainSettings__verified{
  margin-bottom: 7.063rem;
}
.popover__unverified{
  right: -6.188rem;
  font-size: 3rem;
  top: 6.812rem;
  border-radius: 0.5rem;
}
}
