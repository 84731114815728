.anfragenCard {
  margin-right: 3.375rem /* 54px */ !important;
}
.anfragen__button {
  border-radius: 1.5625rem; /* 25px */
  border: solid 0.125rem /* 2px */ #2699fb;
  background-color: white;
  color: #2699fb;
  font-size: 1rem; /* 16px */
  /* width: 7.5438rem; */
  width:fit-content;
  padding:0rem 0.7rem;
  height: 2.5rem; /* 40px */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.6125rem; /* 9.8px */
  margin-bottom: 0.2625rem; /* 4.2px */
  line-height: 2.4;
}
.anfragen__button:hover {
  background-color: #2699fb;
  color: #fff;
  cursor: pointer;
}
.anfragen__bids {
  font-family: SF Pro Display Regular;
  font-size: 0.75rem; /* 12px */
  line-height: 1.17;
  color: #7977a4;
}
.anfragen__bids span {
  color: #2699fb;
}
.anfragenCard__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 0.625rem; /* 10px */
}
.anfragenCard__bottom button {
  background: none;
  border: none;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem; /* 18px */
  line-height: 1.17;
  color: #06016c;
  cursor: pointer;
}
.anfragenCard__bottom .timeCounter {
  font-family: SF Pro Display Regular;
  font-size: 1.125rem; /* 18px */
  line-height: 1.17;
  color: #7579ac;
}
.anfragenCard__bottom .timeCounter span {
  color: #7426fb;
  font-family: SFProDisplay-Bold;
}
.styleLeftSide {
  padding: 1.8125rem 0rem 0.625rem 2.875rem; /* 29px 0px 10px 46px */
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width:870px){
  .anfragenCard .card{
    height: 10.375rem;
  }
  .anfragenCard .card .card__rightSide{
    height: 10.375rem;
  }
}