.transportCard {
  box-shadow: 0 1.25rem 2.5rem 0 /* 20px 40px */ rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 1.5625rem; /* 25px */
  width: max-content;
  display: flex;
  margin-right: 8.75rem !important; /* 140px */
  position: relative;
}
.transportCard__leftSide {
  position: relative;
  width: 20.8875rem; /* 334.2px */
}
.transportCard__leftSide .detailImg {
  position: absolute;
  right: 5.9375rem; /* 95px */
  bottom: 0.0625rem; /* 1px */
  width: 16.3125rem; /* 261px */
  height: 21.7875rem; /* 348.6px */
}
.transportCard__leftSide .truckImg {
  position: absolute;
  top: 2.0625rem; /* 33px */
  left: -3.5rem; /* -56px */
  width: 29rem; /*464px*/
  height: 21.75rem; /*348px*/
}
.transportCard__rightSide {
  padding: 2.5rem 2.0125rem 1.5625rem 0rem; /* 19px 32.2px 25px 0px */
  text-align: center;
  min-width: 21.2375rem; /* 339.8px */
}
.transportCard__rightSide .fromToSection {
  justify-content: center;
}
.transportCard__rightSide .fromTo__city {
  line-height: 1.32;
  font-size: 1.938rem;
}
.transportCard__rightSide .fromTo__country {
  line-height: 1.33;
  font-size: 0.938rem;
}
.transportCard__rightSide .fromToSection__arrow {
  width: 1.168rem;
  height: 0.909rem;
  margin-top: 1rem;
  margin-left: 1.725rem;
  margin-right: 1.669rem;
}
.transportCard__number {
  position: absolute;
  font-size: 8.75rem; /* 140px */
  right: 0.5rem; /* 8px */
  font-family: SF Pro Display Regular;
  top: -1.6875rem; /* -27px */
  color: rgba(6, 1, 108, 0.1);
}
.transportCard__rightSide h4 {
  font-family: SFProDisplay-Bold;
  font-size: 1.625rem; /* 26px */
  line-height: 1.19;
  color: #7977a4;
  text-align: left;
  margin-bottom: 1.125rem; /* 18px */
}
.rightSide__Currently {
  border-radius: 1.5625rem; /* 25px */
  line-height: 1.17;
  color: #7977a4;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem; /* 18px */
  text-align: center;
}
.stateCurrently {
  color: #7426fb;
  font-family: SFProDisplay-Bold;
}
.currently__exept {
  background-color: #06016c;
  padding: 0.9063rem 4.4437rem; /* 14.5px 71.1px */
  margin: 0rem /* 0px */ auto;
  margin-bottom: 1.125rem; /* 18px */
  width: fit-content;
  margin-top: 1.875rem;
}
.currently__exept .stateCurrently {
  color: #2699fb;
}
.rightSide_Details {
  font-family: SFProDisplay-Bold;
  font-size: 1.125rem; /* 18px */
  line-height: 1.17;
  color: #06016c;
  background: none;
  border: none;
  margin-top: 2.5rem; /* 40px */
}
