* {
  margin: 0rem; /* 0px */
  padding: 0rem; /* 0px */
  box-sizing: border-box;
}
.fuhrpark__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 3.9375rem; /* 63px */
  padding-left: 5.5rem; /* 88px */
  position: relative;
  padding-right: 8.0625rem; /* 129px */
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow: hidden;
}
.fuhrparkContent__section {
  height: 70vh;
}
.section__top {
  margin-bottom: 2.375rem; /* 38px */
  display: flex;
  justify-content: space-between;
}
.section__top .sectionTop__paragraph {
  font-family: SF Pro Display Regular;
  font-size: 1.375rem; /* 22px */
  line-height: 1.18;
  color: #06016c;
  margin-top: 1.25rem; /* 20px */
}
.section__top .sectionTop__paragraph span {
  font-family: SF Pro DisplayMedium;
  font-weight: 500;
  color: #7701fc;
}
.section__top .blueButton {
  margin: 0.75rem 0rem 0rem 0rem;
}
.section__bottom {
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  height: inherit;
  align-items: baseline;
  /* padding: 0px 19px; */
}
.section__bottom--row {
  display: flex;
  flex-direction: column;
}
.showTruckDetails {
  display: block;
}
.hideTruckDetails {
  display: none;
}
.fuhrpark__content-blur {
  position: absolute;
  top: 0rem; /* 0px */
  bottom: 0rem; /* 0px */
  left: 0rem; /* 0px */
  right: 0rem; /* 0px */
  z-index: 99999;
  background-color: rgba(119, 1, 252, 0.02);
  backdrop-filter: blur(9px);
}
.fuhrpark__content-blurDisplay {
  display: none;
}
::-webkit-scrollbar {
  width: 0rem; /* 0px */
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(163, 162, 162);
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 2021px) {
  .fuhrparkContent__section .content__header {
    margin: 0px 0px !important;
  }
}
