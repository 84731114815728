.shipmentsCard {
  width: fit-content;
}
.shipmentCard__userName {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}
.shipmentCard__check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
.shipmentCard__userName label {
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  color: #06016c;
}
.shipmentsCard__card {
  width: 15.625rem;
  height: 21.813rem;
  border-radius: 1.25rem;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(6, 1, 108, 0.2);
  background-color: #ffffff;
  position: relative;
}
.shipmentsCard__person {
}
.shipmentsCard__top {
  height: 12.063rem;
  width: 100%;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.shipmentsCard__bottom {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shipmentsCard__bottom .fromTo__city,
.shipmentsCard__bottom .fromTo__country {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 5.1rem;
  white-space: nowrap;
}
.shipmentsCard__carType {
  width: 12.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #2699fb;
  margin-top: 0.569rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 14.4rem;
  white-space: nowrap;
}
.shipmentsCard__button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem;
  line-height: 1.36;
  letter-spacing: 0.088rem;
  color: #fff;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  height: 3.125rem;
  background-color: #06016c;
  border: none;
  margin-top: 1rem;
}
