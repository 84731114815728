.mainSettings {
  width: 57rem;
  display: flex;
  justify-content: space-between;
}
.mainSettings__leftSide {
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainSettings__leftSide button {
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  padding-bottom: 0.625rem;
  text-decoration: none;
  color: #06016c;
  cursor: pointer;
  border: none;
  background: none;
}
.leftSide__profileImage {
  background-color: white;
  width: 100%;
  height: 16.688rem;
  margin-bottom: 1.438rem;
  border-radius: 1.875rem;
  background-position: center;
  background-size: cover;
}

.mainSettings__rightSide {
  width: 25rem;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.mainSettings__rightSide div .group {
  width: 100%;
}
.mainSettings__rightSide .group .form-input {
  width: 100%;
  /* border: none !important; */
}
.mainSettings__rightSide .group:nth-last-child(1) {
  margin-bottom: 2.5rem;
}
.mainSettings__rightSide .blueButton {
  margin: 0rem;
}
.phoneNumber__verify{
  color:#E6BD37;
  text-transform: uppercase;
  font-family: SFProDisplay-Bold;
  font-size: 1.15rem;
  letter-spacing: 0.1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.phoneInputNumber {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mainSettings__verified .react-tel-input{
  /* min-width: 22.5rem; */
}
.phoneInputNumber .group {
  width: fit-content !important;
}
.phoneInputNumber .group:nth-child(1) .form-input {
  width: 4.563rem;
  text-align: center;
  padding: 0px;
}
.phoneInputNumber .group:nth-child(2) .form-input {
  width: 20rem;
}

.phoneInputNumber .form-input:focus ~ .form-label,
.phoneInputNumber .form-input:valid ~ .form-label {
  left: -6.3rem;
}
.phoneNumber__paragraph {
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  font-family: SF Pro Display Regular;
  text-align: left;
  margin-bottom: 2px;
}
.phoneNumber__input {
  border-radius: 1.563rem !important;
  border: 2px solid rgb(121, 119, 164) !important;
  outline: none !important;
  /* padding: 14px 23px !important; */
  height: 3.125rem !important;
  font-family: "SF Pro Display Regular", sans-serif !important;
  font-size: 1.125rem !important;
  color: #06016c !important;
  width: 100% !important;
}
.mainSettings__rightSide .flag-dropdown {
  border: 2px solid rgb(121, 119, 164) !important;
  width: 6.375rem !important;
}
.phoneNumber__input:focus,
.mainSettings__rightSide .flag-dropdown:focus {
  border: 2px solid #2699fb !important;
}
.mainSettings__rightSide .react-tel-input .country-list {
  width: 250px;
}
.mainSettings__rightSide .react-tel-input .form-control {
  padding-left: 9.938rem !important;
}
.mainSettings__rightSide .react-tel-input .selected-flag {
  width: 4.375rem;
}
.mainSettings__rightSide .react-tel-input .flag-dropdown.open {
  z-index: 9999;
}
.mainSettings__rightSide .react-tel-input .selected-flag .arrow{
 left:2rem;
}
.mainSettings__rightSide .react-tel-input .selected-flag .flag {
  margin-top: -0.5rem;
}

.mainSettings__rightSide .search-box {
  height: 26px !important;
  width: 95% !important;
}
.mainSettings__rightSide .react-tel-input .country-list .search {
  padding: 10px 19px 6px 10px;
}
.mainSettings__verified{
  display: flex;
  align-items: center;
  margin-bottom: 3.063rem;

}
.mainSettings_verifiedIcon{
  margin-left: 1rem;
  height: auto;
  width: 1.8rem;
  cursor: pointer;
  fill: #2699FB;
}
@media only screen and (max-width: 500px) {
  .phoneNumber__input {
    height: 8.125rem !important;
    border-radius: 4.563rem !important;
    font-size: 3.125rem !important;
  }
  .mainSettings_verifiedIcon {
    width: 3.8rem;
}
.mainSettings__rightSide .flag-dropdown{
  width: 11.375rem !important;
}
.mainSettings__rightSide .react-tel-input .form-control {
  padding-left: 14.938rem !important;
}
.mainSettings__rightSide .react-tel-input .selected-flag {
  width: 7.375rem;
}
.react-tel-input .flag {
  width: 3.5rem!important;
  height: 2rem!important;
}
.mainSettings__rightSide .react-tel-input .selected-flag .arrow {
  left: 4rem;
}
.mainSettings__rightSide .react-tel-input .selected-flag .flag {
  margin-top: -1rem;
}
.mainSettings__rightSide .blueButton{
  margin: 0px auto;
}
}
