.shipments__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 6.063rem; /* 63px */
  padding-left: 6.438rem;
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
}
.shipmentsContent__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.75rem;
}
.shipmentsContent__lineWidth {
  width: 13.25rem;
}
.shipmentsContent__paragraph {
  margin-top: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.36;
  color: #06016c;
}
.shipmentsContent__getBid {
  display: flex;
  margin-bottom: 6.063rem;
  width: inherit;
  /* align-items: center; */
}
.shipmentsContent__getBid .form-input {
  width: 20.625rem;
}

.shipmentsContent__getBid .group:nth-child(2) {
  margin-left: 0.688rem;
  margin-right: 1.875rem;
}
.getBid__button {
  width: 12.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  background-color: #06016c;
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-left: 1.563rem;
  margin-top: 0.3rem;
}
.shipmentsContent__slider {
  overflow-x: hidden;
}
.shipmentsContent__slider .slick-list {
  padding-top: 2.25rem;
  padding-bottom: 3.875rem;
  padding-left: 3rem;
}
.shipmentsContent__slider .slick-slider {
 width: 60.3rem !important; 
  /* display: flex; */
  position: relative;
}
.shipmentsContent__myShipments {
  margin-top: 16.063rem;
}
.shipmentsContent__myShipments .content__header {
  width: fit-content;
}
.shipments__content-blur {
  position: absolute;
  top: 0rem; /* 0px */
  bottom: 0rem; /* 0px */
  left: 0rem; /* 0px */
  right: 0rem; /* 0px */
  z-index: 99999;
  backdrop-filter: blur(14px);
  background-color: rgba(119, 1, 252, 0.02);
}
.shipments__content-blurDisplay {
  display: none;
}

.shipmentsContent__left {
  width: 61.65rem;
}
.shipmentsContent__right {
  display: flex;
  justify-content: center;
  width: calc(100% - 61.65rem);
}
.shipmentsContent__notifications {
  width: 21.875rem;
  height: 53.5rem;
  background-color: rgba(119, 1, 252, 0.05);
  border-radius: 1.25rem;
  padding: 2.313rem 3rem 1.719rem 3rem;
  overflow-y: auto;
}
.notifications__paragraph {
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.33;
  color: #06016c;
  margin-bottom: 2.188rem;
}

@media screen and (min-width: 2021px) {
  .shipmentsContent__left {
    width: 83.65rem;
  }
  .shipmentsContent__getBid {
    justify-content: left;
  }
}

.shipmentsContent__getBid input {
  width: 20.625rem;
  height: 3.125rem;
  border-radius: 1.563rem;
  padding-left: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  border: 2px solid rgb(121, 119, 164);
  background-color: white;
}

.shipmentsContent__getBid .inputHolder {
  position: unset;
  margin-right: 1.625rem;
}

.slick-arrow {
  cursor: pointer;
  width: 11.5rem;
  position: absolute;
  top: 11.5rem;
  z-index: 99;
}
.shipmentsContent__slider .slick-next {
  margin-left: 1rem;
  right: -9rem;
}
svg title {
  display: none;
  visibility: hidden;
}

.slick-slide.slick-center > div {
  transform: scale(1.1);
  margin: 0px auto;
  width: 100%;
}
.slick-slide.slick-center > div > div {
  margin: 0px auto;
  padding: 9.25rem;
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .shipmentsContent__slider .slick-slider {
    width: 100% !important;
  }
  .shipmentsContent__slider .slick-slide {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .shipmentsContent__slider .slick-track {
    align-items: center;
    display: flex;
  }
  .shipmentsCard__card {
    width: 100%;
    height: 54.813rem;
    border-radius: 4.25rem;
  }
  .shipmentsCard__top {
    height: 30.063rem;
    border-top-left-radius: 4.25rem;
    border-top-right-radius: 4.25rem;
  }
  .shipmentsCard__carType {
    height: 8.5rem;
    font-size: 3.125rem;
    width: 30.4rem;
  }
  .shipmentsCard__bottom .fromTo__city,
  .shipmentsCard__bottom .fromTo__country {
    width: 14.1rem;
    padding: 0rem 0.5rem;
  }
  .shipmentsCard__bottom .fromTo__city {
    font-size: 3.5rem;
  }
  .shipmentsCard__bottom .fromTo__country {
    font-size: 1.75rem;
  }
  .shipmentsCard__bottom .fromToSection__arrow {
    width: 4.0625rem;
    margin-top: 1.375rem;
  }
  .shipmentsCard__button {
    font-size: 2.875rem;
    border-bottom-left-radius: 4.25rem;
    border-bottom-right-radius: 4.25rem;
    height: 8.25rem;
    margin-top: 4rem;
  }
  .shipmentView__content--bottom .blueButton{
    width: 31.5rem !important;
    height: 7.5rem !important;
  }
  .shipmentsContent__left .content__section{
    padding:0rem 5rem;
  }
  .shipmentsContent__getBid .inputHolder{
    width: 100%!important;
  }
}
