 .burgerNavbar{
     width: 300px;
     display: flex;
    justify-content: flex-end;
 }
 #menuToggle
 {
   display: none;
   position: relative;
   z-index: 999999;  
   -webkit-user-select: none;
   user-select: none;
   margin-right: 30px;
 }

 @media screen and (max-width: 856px) {
  #menuToggle
  {
    margin-right: 30px;
  }
  
 }
 
 #menuToggle a
 {
   text-decoration: none;
   color: #232323;
   
   transition: color 0.3s ease;
 }

 #menuToggle input
 {
   display: block;
   width: 40px;
   height: 32px;
   position: absolute;
   top: -7px;
   left: -5px;
   cursor: pointer; 
   opacity: 0; 
   z-index: 9999999;  
   -webkit-touch-callout: none;
 }
 #menuToggle span
 {
   display: block;
   width: 33px;
   height: 4px;
   margin-bottom: 5px;
   position: relative; 
   background: #fff;
   border-radius: 3px;
   z-index: 999999; 
   transform-origin: 4px 0px; 
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
   transform-origin: 0% 0%;
 }
 
 #menuToggle span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 
 #menuToggle input:checked ~ span
 {
   opacity: 1;
   transform: rotate(45deg) translate(-8px, -19px);
   background: rgb(6, 1, 108, 0.75);
 }

 #menuToggle input:checked ~ span:nth-last-child(3)
 {
   opacity: 0;
   transform: rotate(0deg) scale(0.2, 0.2);
 }

 #menuToggle input:checked ~ span:nth-last-child(2)
 {
   transform: rotate(-45deg) translate(0, 13px);
 }

 #menu
 {
   width: 300px;
   padding-top: 125px;
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
 }
 
 #menu li
 {
   padding: 10px 50px;
   font-size: 23px;
   font-family: SF Pro DisplayMedium;
    color:rgb(6, 1, 108, 0.75);
    text-transform: uppercase;
 }
 #menu li:hover,  #menu li:focus{
     background-color: #eaeaea;
 }
#menu a{
    text-decoration: none;
}
 #menuToggle input:checked ~ ul
 {
   transform: none;
 }
.burgerAnimation{
    position: absolute;
    z-index: 99999;
    right: 0;
    bottom: 0px;
    height: 100vh;
    background-color: white;
}
 @media only screen and (max-width:856px){
    #menuToggle{
        display: block;
    }
    .nav__menu{
        display: none!important;
    }
    nav{
        margin-right: 0px!important;
    }
    .burgerMenu__flags .flag-select{
        margin:0px;
        width: fit-content;
        height: auto;
    }
    .burgerMenu__flags .flag-select__btn{
        padding:0px;
    }
    .burgerMenu__flags .flag-select__option{
        padding:0px;
        margin:0px;
    }
    .burgerMenu__flags .flag-select__options{
        left:0px;
        box-shadow: -1px 6px 13px -8px rgba(0,0,0,0.2);
        border-color: #bdbbbb26;
        padding: 8px 8px;
    }
    .burgerMenu__flags .flag-select__option__label {
        font-size: 18px;
        text-transform: uppercase;
    }
    .burgerMenu__flags .flag-select__option.has-label {
        padding: 0 20px 11px 8px!important;
    }
 }