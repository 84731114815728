.chatlist-root {
  background-color: white;
  height: calc(100vh - 7.5rem);
  position: absolute;
  left: 0;
  width: 300px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 999999;
}

.chatlist-root button {
  box-shadow: none;
  border: none;
  outline: none;
  background: #2699fb;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  width: 200px;
  align-self: center;
  cursor: pointer;
  margin-top: 6.5px !important;
  font-family: SF Pro Display Semibold;
  justify-content: center;
}

.chatlist-mainlist {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 7px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.chatlist-mainlist li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
}

.chatlist-mainlist li h2,
.chatlist-mainlist li h3 {
  padding: 0;
  margin: 0;
}

.chatlist-mainlist li h2 {
  font-size: 16px;
  color: #43444f;
  font-family: SF Pro Display-Bold;
}

.chatlist-mainlist li h3 {
  font-size: 13px;
  color: #8a8d91;
  font-weight: normal;
  font-family: SF Pro Display Semibold;
}

.selectedChat {
  background: rgba(0, 0, 0, 0.08);
}
.unreadChat {
  background:  rgba(6, 1, 108, 0.1);
}
.avatarIcon {
  background: lightgray;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-family: SF Pro Display-Bold;
}

.avatarIcon span {
  padding: 0;
  margin: 0;
  line-height: 1;
}
