.leaveReviewsCard {
  width: fit-content;
  height: fit-content;
}
.leaveReviews__name {
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
}
.leaveReviews__name--check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
.reviews__card {
  width: 18.75rem; /* 300px */
  height: 33.25rem; /* 532px */
  border-radius: 1.25rem; /* 20px */
  box-shadow: 0 1.25rem 2.5rem 0 /* 20px 40px */ rgba(6, 1, 108, 0.2);
  background-color: #ffffff;
  position: relative;
}
.leaveReviews__person {
  position: absolute;
  width: 6.25rem;
  height: 6.25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  left: -1.563rem;
  top: -2.813rem;
  border-radius: 999px;
}
.leaveReviews__top {
  height: 12.063rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 1.5625rem;
  border-top-right-radius: 1.5625rem;
}
.leaveReviews__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.625rem;
}
.leaveReviews__bottom--emoticons {
  display: flex;
  margin: 0.938rem auto 1.438rem auto;
}
.leaveReviews__bottom--emoticons .emoticonsImg {
  margin-right: 1.563rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
}
.leaveReviews__bottom--emoticons .emoticonsImg:nth-last-child(1) {
  margin-right: 0rem;
}
.leaveReviews__button {
  width: 100%;
  height: 3.125rem; /* 50px */
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  background-color: #06016c;
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem; /* 14px */
  letter-spacing: 0.0875rem; /* 1.4px */
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}
.bottom__textarea {
  width: 15.625rem; /* 250px */
  height: 8.75rem; /* 140px */
  border-radius: 1.125rem; /* 18px */
  border: solid 1px #2699fb;
  background-color: #ffffff;
  padding: 0.563rem 0.938rem;
  font-size: 0.875rem;
  color: #7977a4;
  font-family: SF Pro Display Regular;
  line-height: 1.36;
  letter-spacing: 0.088rem;
  margin-bottom: 0.75rem;
  resize: none;
}
