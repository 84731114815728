.mapsHolder {
  border: 0.0625rem /* 1px */ solid rgb(187, 187, 187);
  padding: 0.5rem 0rem; /* 8px 0px */
  width: 12.5rem; /* 200px */
  border-radius: 1.25rem; /* 20px */
  margin-top: 0.3125rem; /* 5px */
  background: white;
  z-index: 9999;
}

.customLi {
  padding: 0.75rem 1.15rem;
  list-style: none;
  cursor: pointer;
  font-family: SF Pro Display Semibold;
}

.customLi small {
  font-family: SF Pro Display Regular;
  color: rgb(140, 140, 140);
  font-size: 1rem;
}

.customLi:hover {
  background: #eee;
}

.mapsHolder {
  position: absolute;
}

.row {
  margin-top: 1.875rem; /* 30px */
}

.row input {
  width: 100%;
}
