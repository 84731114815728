.mobileNotifications {
  height: calc(100vh - 25rem) !important;
  width: 100%;
  overflow-y: scroll;
  background-color: rgba(6, 1, 108, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.mobileNotifications a{
  width: 85%;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 500px) {
  .notifications {
    background-color: white;
    border-radius: 4.25rem;
    width: 100%;
    padding: 2rem;
    box-shadow: 0 1rem 1.1875rem 0 rgba(6, 1, 108, 0.1);
  }
  .notification__content {
    border-bottom: none;
    margin-left: 2.188rem;
  }
  .notification__userPicture {
    width: 12.313rem;
    height: 12.313rem;
  }
  .notification__contentTitle {
    overflow: unset;
    width: unset;
    font-size: 3rem;
    line-height: 1.5;
  }
  .notification__contentMessage {
    font-size: 2.3rem;
    line-height: 1.35;
    white-space: unset;
    width: unset;
    overflow: unset;
  }
  .notification__contentMessage--paragr {
    width: 100%;
  }
  .notification__contentHour {
    font-size: 2.2rem;
  }
}
