.paymentSuccessful {
  padding: 10.875rem 8.125rem 0rem 8.125rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.paymentSuccessful__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paymentSuccessful__content--heading {
  text-align: center;
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 0.3125rem;
}
.paymentSuccessful__content--paragraph {
  margin-bottom: 79px;
  font-size: 1.25rem;
  line-height: 1.35;
  text-align: center;
}
.paymentSuccessful__contentImage {
  width: 21.884rem;
  height: 11.563rem;
  margin-bottom: 3.813rem;
}

.paymentSuccessful__orderID {
  font-family: SF Pro Display Regular;
  font-size: 1rem;
  line-height: 1.31;
  color: #06016c;
}
.paymentSuccessful .blueButton {
  margin-top: 6.125rem;
}
