.fileUpload__button {
  color: #2699fb !important;
  background: none !important;
  font-size: 1.35rem  !important;
  font-family: SFProDisplay-Bold !important;
  width: fit-content !important;
  height: fit-content !important;
  line-height: 1.21 !important;
  margin-top: 0rem /* 0px */ !important;
  margin: 0rem /* 0px */ auto !important;
  padding-bottom: 1.5rem /* 24px */ !important;
  user-select: none;
  border: none;
  cursor: pointer;
}
.fileUpload .uploadPlaceholder {
  font-size: 1.2rem !important;
  line-height: 1.21 !important;
  color: rgba(6, 1, 108, 0.25) !important;
  font-family: SF Pro Display Regular !important;
  width: fit-content !important;
  /* margin: 0rem 0px auto !important; */
  margin: 5rem auto 1rem auto !important
}

.dropzoneFile {
  min-height: 7.5rem; /* 120px */
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  background-image: url("../../../assets/images/document.png");
  background-repeat: no-repeat;
  background-position: right;
  margin-right: 2.2625rem; /* 36.2px */
  justify-content: center;
  padding-bottom: 0.625rem; /* 10px */
  background-size: 3.5rem;
}
.dropzoneFileUpdate {
  padding-bottom: 0rem;
  margin-right: 1.6rem;
  background-size: 37px;
}
.dropzoneFile .fileUpload__button {
  padding-bottom: 0rem /* 0px */ !important;
}
.fileUpload .dragOver {
  border: dashed 2px rgba(6, 1, 108, 0.25) !important;
}
.fileUpload .fileAside {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.3125rem; /* 5px */
  background-color: white;
  margin-bottom: 20px;
  position: relative;
  flex-flow: wrap;
}
.fileUpload .thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
}
.fileUpload .Toastify__toast-container--top-right {
  top: 8em;
}
@media only screen and (max-width: 500px) {
  .fileUpload .uploadPlaceholder {
    font-size: 3.5rem !important;
  }
  .fileUpload__button {
    font-size: 3.5rem !important;
  }
}
