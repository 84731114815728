.truckDetails {
  padding: 3.125rem 0rem 1.9375rem 0rem; /* 50px 119px 31px 120px */
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  overflow: auto;
}
.truckDetails--heading {
  font-size: 2.25rem; /* 36px */
  line-height: 1.19;
  font-family: SFProDisplay-Bold;
  margin-bottom: 0.5625rem; /* 9px */
  padding: 0rem 7.5rem;
}
.truckDetails--paragraph {
  font-size: 1.25rem /* 20px */ !important;
  line-height: 1.35 !important;
  margin-bottom: 2.5rem; /* 40px */
  margin-top: 0rem /* 0px */ !important;
  padding: 0rem 7.5rem;
}
.truckDetails__form{
  padding: 0rem 7.5rem;
}
.truckDetails__form > div:nth-of-type(2) .group,
.truckDetails__form > div:nth-of-type(2) .group .form-input {
  width: 100%;
}
/*.truckDetails__form .group:nth-child(1) input {
  width: 100%;
} */
.truckDetails__form--exception {
  display: flex;
  margin: 2.125rem 0rem;
  justify-content: space-between;
}

.truckDetails__form--exception input {
  width: 12.5rem;
}
.truckDetails ::placeholder {
  color: #06016c;
  font-size: 1.125rem; /* 18px */
  align-items: center;
}
.truckDetails__form .blueButton {
  margin: 0rem /* 0px */ auto !important;
}
.form__ImageUpload {
  width: 100%;
  min-height: 16.125rem; /* 258px */
  height: fit-content;
  margin-bottom: 2.125rem; /* 34px */
  border-radius: 1.125rem; /* 18px */
  border: dashed 2px #2699fb;
}
.imageUpload__images {
  position: relative;
}
.addTruck .form__ImageUpload .imageUpload__button {
  display: none;
}
.addTruck .imageUpload .imageUpload__placeholder {
  /* padding: 0rem 3.625rem; */
  padding: 0rem;
  opacity: 0.6;
}
.imageUpload__firstImage {
  width: 17.75rem;
  height: 10rem;
}
.imageUpload__images img:nth-child(1) {
  width: 8.813rem;
  height: 7rem;
  position: absolute;
  top: -1.725rem;
}
.imageUpload__images img:nth-child(2) {
  width: 11.25rem;
  height: 6.313rem;
  position: absolute;
  left: 7.7rem;
  top: -1.2rem;
}
.form__DocumentUpload {
  width: 100%;
  min-height: 7.5rem; /* 120px */
  height: fit-content;
  border-radius: 1.125rem; /* 18px */
  border: dashed 2px #2699fb;
  margin-bottom: 2.1875rem; /* 35px */
}
.addTruckAnimated {
  position: absolute;
  z-index: 99999;
  right: 0rem; /* 0px */
  top: 0rem; /* 0px */
  bottom: 0rem; /* 0px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  background-color: white;
}
.showTruckDetails {
  visibility: visible;
}
.hideTruckDetails {
  visibility: hidden;
}
textarea:focus {
  border: 0.125rem /* 2px */ solid #2699fb !important;
  line-height: 1 !important;
}
@media only screen and (max-height: /*61.1875rem*/ 979px) {
  .truckDetails__form {
    overflow-y: scroll;
    height: 65.5vh;
    overflow-x: hidden;
  }
}
@media only screen and (max-height: 736px) {
  .truckDetails__form {
    height: 63.5vh;
  }
}
@media only screen and (max-width:700px){
  .truckDetails__form--exception input {
    width: 16.5rem;
}
}
@media only screen and (max-height: 687px) {
  .truckDetails__form {
    height: 55.5vh;
  }
}
@media only screen and (max-height: 613px) {
  .truckDetails__form {
    height: 50.5vh;
  }
}
@media only screen and (max-height: 545px) {
  .truckDetails__form {
    height: 47.5vh;
  }
}
@media only screen and (max-width: 500px) {
  .truckDetails {
    width: 100%;
    height: calc(100vh - 25rem);
  }
  .truckDetails--heading {
    font-size: 5.25rem;
  }
  .truckDetails--paragraph {
    font-size: 3.5rem !important;
    margin-bottom: 4.5rem;
  }
  .imageUpload .dropzone {
    height: 41.125rem;
  }
  .imageUpload__firstImage {
    width: 42.75rem;
    height: unset;
  }
  .imageUpload__images img:nth-child(1) {
    width: 23.813rem;
    height: unset;
  }
  .imageUpload__images img:nth-child(2) {
    width: 30.25rem;
    height: unset;
    left: 34%;
  }
  .form__ImageUpload {
    margin-bottom: 6.125rem;
    border-radius: 6.125rem;
  }
  .truckDetails__form--exception {
    flex-direction: column;
    margin: 6rem 0rem;
  }
  .truckDetails__form--exception .group,
  .truckDetails__form--exception .form-input {
    width: 100%;
  }
  .truckDetails__form--exception > div:nth-child(1) {
    margin-bottom: 6rem !important;
  }
  .truckDetails__form .group-textarea {
    margin-bottom: 6rem;
  }
  .truckDetails__form .form__DocumentUpload,
  .truckDetails__form .dropzoneFile {
    min-height: 18.5rem;
  }
  .truckDetails__form .dropzoneFile {
    background-size: 7.5rem;
  }
}
