.imageUpload__button {
  color: #2699fb !important;
  background: none !important;
  font-size: 1.5rem !important;
  font-family: SFProDisplay-Bold !important;
  width: fit-content !important;
  height: fit-content !important;
  line-height: 1.21 !important;
  margin-top: 0rem /* 0px */ !important;
  margin: 0rem /* 0px */ auto !important;
  padding-bottom: 1.5rem /* 24px */ !important;
  user-select: none;
  border: none;
}
.imageUpload .uploadPlaceholder {
  font-size: 1.5rem !important;
  line-height: 1.21 !important;
  color: rgba(6, 1, 108, 0.25) !important;
  font-family: SF Pro Display Regular !important;
  width: fit-content !important;
  margin: 0rem /* 0px */ auto !important;
  /* padding-top:176px!important; */
}
.imageUpload .dropzone {
  display: flex;
  flex-direction: row;
  height: 16.125rem; /* 258px */
  justify-content: flex-end;
}

.imageUpload .dragOver {
  border: dashed 2px rgba(6, 1, 108, 0.25) !important;
}
.imageUpload .fileAside {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0.3125rem; /* 5px */
  background-color: white;
  margin-bottom: 20px;
  position: relative;
  flex-flow: wrap;
}
.imageUpload .thumbsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
  width: 26.438rem;
  height: 15.25rem;
  margin: 0.5rem;
}
.imageUpload .thumbsSecondContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  width: 26.438rem;
  height: 19rem;
  margin: 0.313rem;
}
/* .imageUpload .thumbsContainer .thumbImage:nth-child(1) {
  width: 14.813rem !important;
  height: 16.25rem !important;
}
.imageUpload .thumbsContainer .thumbImage:nth-child(1) img {
  width: 14.813rem !important;
} */
.divHover {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999999999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.divHover:hover {
  backdrop-filter: blur(12px);
}
.divHover:hover .buttonDelete {
  visibility: visible;
}
.divHover:hover .buttonReplace {
  visibility: visible;
}
.buttonDelete {
  width: 6.663em !important;
  height: 2.3em !important;
  background-color: #2699fb !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 0.875em !important;
  color: white !important;
  visibility: hidden;
  border: none;
}
.buttonReplace {
  width: 6.663em !important;
  height: 2.3em !important;
  background-color: #fb2626 !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 0.875em !important;
  color: white !important;
  margin-bottom: 0.575rem !important;
  visibility: hidden;
  border: none;
}
.imageUpload .imageUpload__placeholder {
  display: flex;
  flex-direction: column;
  padding-top: 11rem;
  margin: 0px auto;
}
.imageUpload .Toastify__toast-container--top-right {
  top: 0rem;
  z-index: 999999999999;
  position: absolute;
}
