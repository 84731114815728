.slider__buttons button {
  background-color: transparent;
  border: none;
  height: 3.125rem;
  width: 33%; 
  font-size: 20px;
  color: #06016c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5625rem;
  font-family: SF Pro Display Light !important;
}
.slider__buttons button:hover {
  cursor: pointer;
  color: #2699fb;
}
.middleButton {
  border-left: 0.0625rem solid rgba(6, 1, 108, 0.1);
  border-right: 0.0625rem solid rgba(6, 1, 108, 0.1);
}

.slider__buttons .buttonFocused {
  color: #2699fb;
}
