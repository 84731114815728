.card {
  background-color: white;
  min-width: 25rem; /* 400px */
  width: fit-content;
  height: 9.375rem; /* 150px */
  border-radius: 1.5625rem; /* 25px */
  display: flex;
  box-shadow: 0 1rem 1.1875rem 0 /* 16px 19px */ rgba(6, 1, 108, 0.1);
  position: relative;
  justify-content: space-between;
}
.card__leftSide {
  /* padding:29px 0px 10px 46px;
    display: flex;
    flex-direction: column;
    align-items: center; */
  width: fit-content;
  margin-right: -1.25rem; /* -20px */
}
.card__rightSide {
  width: 17.1875rem; /* 275px */
  height: 9.375rem; /* 150px */
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-right-radius: 1.5625rem; /* 25px */
  border-bottom-right-radius: 1.5625rem; /* 25px */
  position: relative;
  z-index: 9999;
  /* position: absolute;
    right:0px; */
}
.card__rightSideDashboard {
  clip-path: polygon(100% 100%, 0% 119%, 27% 0%, 100% 0%);
}
.card__rightSideFuhrpark {
  clip-path: polygon(100% 100%, 0% 180%, 38% 0%, 100% 0%);
}
.card__leftSideFuhrpark {
  margin-right: -3.8125rem; /* -61px */
}
.cardFuhrpark {
  margin-bottom: 4.9375rem; /* 79px */
  margin-left: 1.6rem;
}
