.acceptedOffer__name {
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
  margin-left: 0.875rem;
}
.acceptedOffer__name--check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
