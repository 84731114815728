* {
  padding: 0;
  margin: 0;
  outline: none;
}
html {
  font-size: 100%;
}
body {
  background-color: #fff;
}

nav {
  margin-left: 70px;
  margin-right: 70px;
}

@font-face {
  font-family: SF Pro Display Semibold;
  src: url("./assets/fonts/sf_prodisplay_Semibold.ttf");
}

@font-face {
  font-family: SF Pro Display Regular;
  src: url("./assets/fonts/sf_prodisplay_regular.ttf");
}

@font-face {
  font-family: SF Pro Display Light;
  src: url("./assets/fonts/sf_prodisplay_light.ttf");
}

@font-face {
  font-family: SF Pro DisplayMedium;
  src: url("./assets/fonts/sf_prodisplay_medium.ttf");
}

@font-face {
  font-family: SF Pro Display-Black;
  src: url("./assets/fonts/sf_prodisplay_Black.otf");
}

@font-face {
  font-family: SFProDisplay-Bold;
  src: url("./assets/fonts/sf_prodisplay_bold.ttf");
}
.nav__menu--links a {
  font-family: SF Pro Display Regular;
  text-decoration: none;
  /* font-size: 22px; */
  font-size: 1.5rem;
  line-height: 1.36;
  color: #bbbbbb;
  position: relative;
  margin-right: 30px;
  white-space: nowrap;
}

.nav__menu--links a:hover {
  color: white;
}

.nav__menu--links a:nth-of-type(1):hover:after,
.nav__menu--links a:nth-of-type(2):hover:after,
.nav__menu--links a:nth-of-type(3):hover:after {
  content: "";
  color: #fff;
  display: inline-block;
  position: absolute;
  top: 35px;
  left: 0px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 2px solid #fff;
  animation-name: widthExpand_1;
  animation-duration: 0.7s;
}
@keyframes widthExpand_1 {
  from {
    width: 10px;
  }
  to {
    width: 100%;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__menu {
  display: flex;
  align-items: center;
}

.whatsappIcon {
  margin-right: 7.6px;
  width: 40px;
  height: 42px;
}

.nav__menu--social {
  margin-left: 20px;
  margin-right: 37.6px;
  cursor: pointer;
}

.whatsappIcon:hover,
.viberIcon:hover {
  animation: shake 0.42s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.nav__menu--buttons {
  display: flex;
}

.nav__menu--buttons button {
  font-family: SF Pro DisplayMedium;
  background: transparent;
  text-align: center;
  font-size: 22px;
  color: #2699fb;
  border: 2px solid #2699fb;
  border-radius: 25px;
  line-height: 1;
  width: 147px;
  height: 59px;
}

.nav__menu--buttons button:hover {
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #fff;
  color: #06016c;
}

.nav__menu--buttons .languageSelector {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.nav__menu--buttons .languageSelector span {
  font-family: SF Pro Display Semibold;
  font-size: 22px;
  color: #2699fb;
  margin-right: 7.7px;
}

main h1 {
  font-family: SF Pro Display Semibold;
  font-size: 61px;
  color: #fff;
  text-align: center;
  margin-top: 86.5px;
}

.searchArea {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 73px;
  position: relative;
}

.searchArea input {
  width: 270px;
  border: 0;
  padding-left: 20px;
  height: 66px;
  border-radius: 8px;
  font-size: 21px;
  line-height: 1.35;
  text-align: left;
  color: #06016c;
  font-family: SF Pro Display Light;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(255, 255, 255, 0.8);
  padding-right: 20px;
}

.searchArea input:focus {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.inputHolder {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 58px;
  left: calc(50% - 100px);
}

.theFirstLine {
  position: absolute;
  top: 118px;
  left: calc(50% + 150px);
}

.theSecondLine {
  position: absolute;
  top: 118px;
  left: calc(50% + 441px);
}

.searchIcon {
  position: absolute;
  top: 88px;
  left: calc(50% + 483px);
}

.inputHolder:nth-of-type(2) {
  left: calc(50% + 190px);
}

.inputHolder label {
  color: #fff;
  text-transform: uppercase;
  font-family: SF Pro DisplayMedium;
  font-size: 17px;
  margin-bottom: 7px;
}

.steps {
  display: flex;
  width: 620px;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 630px;
  right: 100px;
}

.steps__single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.steps__single h2 {
  font-family: "Titillium Web", sans-serif;
  font-size: 43px;
  color: #fff;
}

.steps__single p {
  font-family: SF Pro Display Light;
  font-size: 17px;
  color: #fff;
  text-align: center;
  width: 160px;
}

.stepLine_1 {
  position: absolute;
  left: 120px;
  top: 35px;
}

.stepLine_2 {
  position: absolute;
  left: 350px;
  top: 35px;
}

.line {
  width: 1600px;
  height: 1px;
  background: #00def8;
  margin: 0 auto;
  z-index: 9999999;
  margin-bottom: 35.5px;
  margin-top: 30px;
}

footer {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  width: 1600px;
  margin: 0 auto;
}

.footer__kucice {
  margin-left: 100px;
  width: 900px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-bottom: 40px;
}

.footer__kucice--single h3 {
  font-family: SF Pro Display Semibold;
  font-size: 12px;
  color: #fff;
  margin-bottom: 20px;
}
.footer__kucice--single a {
  text-decoration: none;
}
.footer__kucice--single p {
  font-family: SF Pro Display Light;
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}

.footer__social {
  margin-right: 100px;
  display: flex;
}

.fbIcon {
  color: #fff;
  margin-right: 43px;
  width: 33px;
  height: 32px;
}
.InstaIcon {
  color: #fff;
  width: 35px;
  height: 35px;
}
.modal-box {
  background: transparent;
}

.modal-box__content {
  position: relative;
}

.loginPopUp__container {
  width: 440px;
  /* height: 440px; */
  border-radius: 20px;
  background-color: #ffffff;
  position: absolute;
  /* top: 450px; */
  left: 600px;
  padding: 40px;
  bottom: 90px;
  max-height: 440px;
}

.loginPopUp__button {
  width: 200px;
  height: 45px;
  border-radius: 25px;
  background-color: #06016c;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginPopUp__button span {
  font-family: SF Pro Display Regular;
  color: #fff;
  margin-right: 16.6px;
  font-size: 14px;
  line-height: 1.14;
}

.loginPopUp__container--buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.loginPopUp__container {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.loginPopUp__line {
  width: 152px;
  height: 1px;
  background: #06016c;
}

.loginPopUp__or {
  margin-top: 45px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginPopUp__or span {
  margin-left: 18px;
  margin-right: 18px;
  font-family: SF Pro Display Regular;
  font-size: 14px;
}

.loginPopUp__holder {
  position: relative;
}

.loginPopUp__holder:nth-child(1) {
  margin-bottom: 20px;
}

.loginPopUp__holder input {
  border: 0;
  width: 360px;
  height: 50px;
  border-radius: 25px;
  border: solid 2px #06016c;
  background-color: #ffffff;
  padding-left: 20px;
  padding-right: 45px;
}

.loginPopUp__holder input,
.loginPopUp__holder input::placeholder {
  font-family: SF Pro Display Regular;
  font-size: 14px!important;
  text-align: left;
  color: #06016c;
}
.loginPopUp__holder input:focus {
  border: solid 2px rgba(6, 1, 108, 0.3);
}

.loginPopUp__holder img, .row img {
  position: absolute;
  right: 20px;
  top: 34%;
}

.loginPop__lostPassword {
  color: #06016c;
  font-family: SF Pro Display Regular;
  font-size: 12px;
  padding-top: 9px;
  float: right;
  cursor: pointer;
}
.loginPop__lostPassword a {
  text-decoration: none;
  color: #06016c;
}
.loginPopUp__form--submit {
  border: 0;
  font-family: SF Pro Display Semibold;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 10px;
  color: #fff;
  width: 145px;
  height: 50px;
  border-radius: 25px;
  background-color: #06016c;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal-box__content {
  width: 1212px;
  margin: 0 auto;
}
.mobileButton {
  display: none;
}

.ReactModal__Overlay--after-open {
  z-index: 9999;
  background-color: rgba(6, 1, 108, 0.05) !important;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.ReactModal__Content--after-open {
  border: none !important;
  background: rgba(6, 1, 108, 0.01) !important;
  background-image: url("./assets/images/login_bg.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  position: relative !important;
  height: 90vh !important;
  width: 1280px !important;
  margin: 0px auto;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
}

.ReactModalPortal,
.ReactModal__Body--open {
  overflow: hidden;
}

/*Media*/
@media screen and (max-width: 1920px) {
  .loginPopUp__container {
    left: 570px;
  }
}
@media screen and (max-width: 1580px) {
  .modal-box__content img:nth-child(1) {
    margin-left: -300px;
  }
}
@media screen and (max-width: 1330px) {
  .ReactModal__Content--after-open img:nth-child(1) {
    width: 1000px;
  }
  .loginPopUp__container {
    left: 478px;
    /* top: 430px; */
  }
  .ReactModal__Content--after-open {
    width: 1000px !important;
  }
}
@media screen and (max-width: 1040px) {
  .loginPopUp__container {
    position: relative;
    left: 0;
    top: 0;
  }
  .ReactModal__Content--after-open {
    background-image: none !important;
    width: 100vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0px !important;
    right: 0px !important;
    padding: 0px !important;
  }
}
@media screen and (max-width: 520px) {
  .loginPopUp__container {
    width: 90%;
    height: 470px;
    margin: 0 auto;
  }

  .loginPopUp__holder input {
    width: 100%;
  }
  .loginPopUp__button {
    width: 56px;
    height: 56px;
  }
}

.pac-container:nth-of-type(1) {
  left: 1.3px !important;
  top: 93px !important;
  width: 250px !important;
}
.pac-container:nth-of-type(2) {
  left: 1.3px !important;
  top: 93px !important;
  width: 251px !important;
}

footer,
.line {
  width: 100%;
}

@media screen and (max-width: 1530px) {
  .steps {
    top: 775px;
  }
}

@media screen and (max-width: 1160px) {
  .inputHolder {
    left: calc(50% - 200px);
  }

  .inputHolder:nth-of-type(2) {
    left: calc(50% + 90px);
  }

  .theFirstLine {
    left: calc(50% + 50px);
  }

  .theSecondLine {
    left: calc(50% + 341px);
  }

  .searchIcon {
    left: calc(50% + 383px);
  }

  .coverPhotoSimulated {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1340px) {
  .nav__menu--social {
    display: none;
  }

  .nav__logo img,
  .logo {
    width: 150px !important;
  }

  .coverPhotoSimulated {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1313px) {
  .steps {
    position: sticky;
    margin: 0 auto;
    margin-top: -100px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 1230px) {
  #addShipment .loginPopUp__container {
    top: 300px;
  }
  #vehicleType .loginPopUp__container {
    left: 294px;
    /* top: 241px; */
  }
}
@media screen and (max-width: 1024px) {
  .inputHolder {
    left: calc(50% - 315px);
  }

  .inputHolder:nth-of-type(2) {
    left: calc(48% + 3px);
  }

  .theFirstLine {
    left: calc(38% + 67px);
  }

  .theSecondLine {
    left: calc(50% + 244px);
  }

  .searchIcon {
    left: calc(50% + 282px);
  }

  .footer__social {
    display: flex;
    padding-top: 11px;
    margin-left: 10px;
  }
  .fbIcon {
    margin-right: 30px;
    height: 30px;
  }
  .InstaIcon {
    height: 30px;
  }
}
@media screen and (max-width: 1000px) {
  #vehicleType .loginPopUp__container {
    left: auto;
    /* top: 70px; */
  }

  #vehicleType .flexRow img {
    display: block;
  }
  #addShipment .loginPopUp__container {
    top: 0px;
  }
}
@media screen and (max-width: 990px) {
  .inputHolder {
    left: calc(50vw - 300px);
    top: 7px;
  }

  .inputHolder:nth-of-type(2) {
    left: calc(50vw - 10px);
  }

  .theFirstLine {
    left: calc(50vw - 46px);
    top: 67px;
  }

  .theSecondLine {
    left: calc(50vw + 241px);
    top: 67px;
  }

  .searchIcon {
    left: calc(50vw + 283px);
    top: 48px!important;
  }

  .footer__kucice {
    width: 500px;
  }

  .footer__kucice--single {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 930px) {
  .theFirstLine {
    left: calc(37% + 74px);
  }

  .theSecondLine {
    left: calc(50% + 244px);
  }
}
@media screen and (max-width:904px){
  .theFirstLine {
    left: calc(37% + 71px);
}
}
@media only screen and (max-width: 824px) {
  .theFirstLine {
    left: calc(37% + 59px);
  }

  .theSecondLine {
    left: calc(50% + 240px);
  }
}
@media screen and (max-width: 797px) {
  .fbIcon {
    margin-right: 0px !important;
    margin-bottom: 15px;
    height: 30px !important;
  }
  .InstaIcon {
    height: 30px !important;
    margin-bottom: 7px;
  }
  .footer__social {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .inputHolder:nth-of-type(2) {
    left: calc(47% + 3px);
  }
  .theFirstLine {
    left: calc(36% + 55px) !important;
  }

  .searchIcon {
    left: calc(50% + 277px);
  }
}
@media only screen and (max-width: 700px) {
  .searchArea img.theFirstLine,
  .searchArea img.theSecondLine {
    display: none;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: 27% !important;
  }
  .inputHolder:nth-child(2) {
    top: 131px;
  }
  .inputHolder:nth-child(1) {
    top: 45px;
  }
  .inputHolder label {
    display: none;
  }
  main > h1 {
    font-size: 48px;
  }
  .searchArea {
    margin-top: 20px;
    padding-bottom: 70px;
  }
  .searchIcon {
    display: none;
  }
  .searchArea input {
    width: 322px!important;
    height: 57px;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  div.steps {
    margin-left: 14px;
  }

  button#searchButton {
    display: block !important;
    left: calc(50% - 61px);
    top: 225px!important;
    padding: 17px 36px;
    border-radius: 8px;
    border: none;
    font-family: SF Pro Display Semibold;
    line-height: 1.35;
    font-size: 16px;
    background-color: rgb(0, 152, 203);
    color: whitesmoke;
    opacity: 0.95;
    user-select: none;
    text-transform: uppercase;
    width: auto;
  }
  button#searchButton:hover {
    cursor: pointer;
  }
  button#searchButton:active {
    transform: translateY(1px);
  }
  .nav__logo img {
    width: 215px;
  }

  .nav__menu--buttons {
    position: relative;
  }
}
#addShipment button {
  outline: none;
  border: 2px solid #c1c0db;
  background: transparent;
  width: 300px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 18px;
  font-family: SF Pro Display Regular;
  margin: 0 auto;
}

#addShipment .loginPopUp__or {
  margin-top: 29px;
}

#addShipment button:hover {
  border: 2px solid #2699fb;
  cursor: pointer;
}

#addShipment .loginPopUp__container {
  height: 255px;
  top: 440px;
}

#addShipment h2,
#vehicleType h2 {
  font-family: SF Pro Display Semibold;
  font-size: 28px;
  color: white;
  line-height: 1;
  position: absolute;
  top: -40px;
  left: 0;
}

@media screen and (max-width: 650px) {
  .steps {
    flex-direction: column;
    width: 100%;
    align-items: center;

  }
  div.steps {
    margin-left: 0px;
  }
  .stepLine_1,
  .stepLine_2 {
    width: 51px;
    transform: rotate(90deg);
  }
  .stepLine_1 {
    top: 123px;
    height: 1px;
  }
  .stepLine_2 {
    top: 266px;
    height: 2px;
  }
  .steps__single {
    margin-bottom: 55px;
  }
  .steps__single:nth-last-child(1) {
    margin-bottom: 0px;
  }
  .footer__kucice {
    margin-left: 35px;
  }
  .fbIcon,
  .InstaIcon {
    height: 25px !important;
  }
  .footer__kucice--single p {
    font-size: 14px;
  }
  main > h1 {
    font-size: 41px;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: 13% !important;
    width: 74%;
  }
  nav {
    justify-content: space-around;
  }
  .footer__social {
    padding-top: 0px;
    margin-right: 35px;
  }
  .searchArea {
    padding-bottom: 149px;
  }
  .searchArea input {
    width: 100%!important;
  }
  .searchArea input::placeholder{
    font-size: 18px!important;
  }
  nav {
    margin-left: 0px;
    margin-right: 0px;
  }
  .nav__logo img {
    width: 180px;
  }
  .stepLine_1, .stepLine_2 {
    left: 46%;
}
}
@media only screen and (max-width:620px){
  .loginPopUp__form--submit {
    margin-top: 55px;
  }
  .loginPopUp__container {
    max-height: 463px;
}
}
@media only screen and (max-width:600px){
  .stepLine_1, .stepLine_2 {
    left: 45.5%;
}
}
@media only screen and (max-width:550px){
  .stepLine_1, .stepLine_2 {
    left: 45%;
}
}

@media screen and (max-width: 520px) {
  .steps {
    flex-direction: column;
    width: 100%;
  }
  div.steps {
    margin-left: 0px;
  }
  .stepLine_1,
  .stepLine_2 {
    width: 51px;
    transform: rotate(90deg);
  }
  .stepLine_1 {
    top: 132px;
    height: 1px;
  }
  .stepLine_2 {
    top: 298px;
    height: 2px;
  }
  .steps__single {
    margin-bottom: 55px;
  }
  .steps__single:nth-last-child(1) {
    margin-bottom: 0px;
  }
  .footer__kucice {
    margin-left: 35px;
  }
  .fbIcon,
  .InstaIcon {
    height: 25px !important;
  }
  .footer__kucice--single p {
    font-size: 14px;
  }
  main > h1 {
    font-size: 41px;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: 13% !important;
  }
  nav {
    justify-content: space-around;
  }
  .footer__social {
    padding-top: 0px;
    margin-right: 35px;
  }
  .searchArea {
    padding-bottom: 149px;
  }
  .searchArea input {
    width: 385px;
  }
  nav {
    margin-left: 0px;
    margin-right: 0px;
  }
  .nav__logo img {
    width: 180px;
  }
}
@media screen and (max-width: 510px) {
  #vehicleType .loginPopUp__container {
    top: auto;
  }

  #vehicleType .loginPopUp__container {
    height: 461px;
    padding: 20px;
  }

  #vehicleType .flexRow:nth-of-type(1) {
    margin-top: 30px;
  }
}
@media only screen and (max-width:460px){
  .stepLine_1, .stepLine_2 {
    left: 44.5%;
}
}
@media screen and (max-width: 420px) {
      #addShipment button {
        width: 250px;
      }

  .footer__kucice {
    margin-left: 18px!important;
  }
  .fbIcon,
  .InstaIcon {
    height: 25px !important;
  }
  .footer__kucice--single p {
    font-size: 14px;
  }
  main > h1 {
    font-size: 41px;
  }
  .footer__social {
    padding-top: 0px;
    margin-left: 0px;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: 16.4% !important;
  }
  .searchArea input {
    width: 291px;
  }
  .nav__logo img {
    width: 146px;
  }
  .nav__menu--buttons button {
    font-size: 21px;
    width: 142px;
    height: 54px;
  }
  nav {
    margin-left: 17px;
    margin-right: 17px;
  }
  .loginPopUp__holder input {
    width: 100%;
  }
}
@media only screen and (max-width:410px){
  .stepLine_1, .stepLine_2 {
    left: 43.5%;
}
}
#vehicleType button {
  width: 150px;
  height: 50px;
  border: 0;
  background: #06016c;
  border-radius: 25px;
  font-size: 18px;
  color: #fff;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-family: SF Pro Display Regular;
  margin: 0 auto;
}

#vehicleType .flexRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#vehicleType .flexRow__single img {
  margin: 0;
}

#vehicleType .flexRow__single {
  display: flex;
  font-family: SF Pro Display Regular;
  font-size: 18px;
  flex-direction: column;
  align-items: center;
  color: #06016c;
  justify-content: center;
  width: 100px;
}

#vehicleType .flexRow__single span {
  margin-top: 15px;
}

.flexRow:nth-of-type(2),
.flexRow:nth-of-type(3) {
  margin-top: 50px;
}

.flexRow:nth-of-type(3) {
  margin-bottom: 30px;
}

#vehicleType .loginPopUp__container {
  height: 480px;
  padding-bottom: 0px;
  padding-top: 50px;
}
#vehicleType .flexRow__single:hover {
  cursor: pointer;
}
#vehicleType .flexRow__single:hover span {
  color: #2699fb;
}

@media screen and (max-width: 414px) {
  .searchArea {
    padding-bottom: 200px;
  }
  button#searchButton {
    left: calc(50% - 69px);
  }
  .footer__kucice {
    margin-left: 14px!important;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: 14.7% !important;
  }
}
@media screen and (max-width: 411px) {
  button#searchButton {
    left: calc(50% - 68px);
  }
}

@media screen and (max-width: 375px) {
  main > h1 {
    font-size: 37px;
  }
  .searchArea input {
    width: 315px;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: calc(50% - 127px) !important;
  }
  .searchArea {
    padding-bottom: 213px;
  }
  .searchArea input {
    width: 257px;
  }
  .footer__kucice {
    margin-left: 15px;
  }
  .footer__kucice--single {
    margin-right: 15px;
  }
  .fbIcon,
  .InstaIcon {
    height: 21px !important;
  }
  .footer__kucice--single p {
    font-size: 12px;
  }
  .footer__social {
    padding-top: 0px;
    margin-left: 0px;
  }

  .nav__menu--buttons button {
    font-size: 19px;
    width: 139px;
    height: 51px;
  }
  .nav__logo img {
    width: 136px;
  }
}

@media screen and (max-width: 360px) {
  .footer__kucice--single p {
    font-size: 11px;
  }
  .footer__kucice {
    margin-left: 12px;
  }
  .loginPopUp__holder input {
    width: 100%;
  }
}
@media only screen and (max-width:350px){
  .stepLine_1, .stepLine_2 {
    left: 42.5%;
}
}
@media screen and (max-width: 320px) {
  .footer__kucice--single p {
    font-size: 10px;
  }
  .footer__kucice--single h3 {
    font-size: 11px;
  }
  .footer__kucice--single {
    margin-right: 10px;
  }
  .fbIcon,
  .InstaIcon {
    height: 19px !important;
  }
  .footer__kucice {
    margin-left: 11px;
  }
  .footer__social {
    margin-right: 9px;
  }
  .searchArea input {
    width: 213px;
    height: 51px;
    font-size: 17px;
  }
  .inputHolder:nth-child(1),
  .inputHolder:nth-child(2) {
    left: calc(50% - 116px) !important;
  }
  .inputHolder:nth-child(1) {
    top: 36px;
  }
  .inputHolder:nth-child(2) {
    top: 116px;
  }
  button#searchButton {
    left: calc(50% - 68px);
    top: 208px;
  }
  .steps__single p {
    font-size: 14px;
  }
  .steps__single h2 {
    font-size: 40px;
  }
  .stepLine_1, .stepLine_2 {
    left: 42%;
}
  .stepLine_1 {
    top: 113px;
  }
  .stepLine_2 {
    top: 275px;
  }
  .searchArea {
    padding-bottom: 235px;
  }
  .steps {
    margin-bottom: 88px;
  }
  main > h1 {
    font-size: 31px;
  }
  .nav__menu--buttons button {
    font-size: 17px;
    width: 89px!important;
    height: 47px;
  }
  .nav__logo img {
    width: 100px;
  }
}

.closeIcon {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 1000;
  padding: 0;
  width: 4rem;
  height: 4rem;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}

.flag-select {
  font-family: SF Pro DisplayMedium !important;
  padding-bottom: 0px !important;
  background: transparent;
  text-align: center;
  font-size: 22px !important;
  color: #2699fb !important;
  border-radius: 25px;
  line-height: 1;
  width: 147px;
  height: 59px;
  margin-left: 10px;
  margin-top: 6px;
}
.flag-select__btn:hover {
  cursor: pointer;
}
.flag-select__options {
  font-size: 20px !important;
  left: 8px;
}
.flag-select__btn {
  color: #2699fb !important;
  font-size: 22px !important;
}
.flag-select__btn:after {
  display: none !important;
}

@media screen and (max-height: 850px) {
  .loginPopUp__container {
    bottom: 0px;
  }
}

@media screen and (min-height: 1100px) {
  .loginPopUp__container {
    bottom: 0px;
    top: 450px;
  }
}

@media screen and (min-height: 1400px) {
  .loginPopUp__container {
    top: 540px;
  }
}

@media screen and (min-height: 1600px) {
  .loginPopUp__container {
    top: 635px;
  }
}

@media screen and (min-height: 1800px) {
  .loginPopUp__container {
    top: 720px;
  }
}
@media screen and (min-height: 2000px) {
  .loginPopUp__container {
    top: 820px;
  }
}

/* UPDATES */

.nav__logo img {
  width: 200px;
}

.whatsappIcon,
.viberIcon {
  width: 30px;
  height: 30px;
}

.viberIcon {
  margin-left: 5px;
}

.nav__menu--buttons button {
  font-size: 18px;
  border: 1px solid #2699fb;
  width: 130px;
  height: 45px;
}

.nav__menu--buttons--filled {
  background: #2699fb !important;
  color: #06016c !important;
  margin-left: 10px;
}

.flag-select__btn {
  font-size: 17px !important;
  padding-top: 6px;
  padding-left: 15px;
}

main h1 {
  font-size: 52px;
}

.flag-select__option__label {
  font-size: 16px;
}

.flag-select__options {
  padding-top: 8px;
}

.inputHolder label {
  font-size: 15px;
}

.searchArea input {
  font-size: 20px;
  width: 261px;
}

.searchIcon {
  width: 46px;
  top: 95px;
}

@media screen and (min-height: 1230px) {
  .homepageMain {
    height: 100vh;
  }
}
/*DASHBOARD ROUTE*/
.component__content {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}
.content__main {
  display: flex;
  height: calc(100vh - 7.5rem);
}

/* .nav__menuPublic .nav__menu--links a {
  color: #06017a;
} */

@media screen and (max-width: 1388px) {
  .nav__menu--links a {
    font-size: 1.6rem;
  }
}
@media screen and (max-width:1100px){
  .nav__menu--links a {
    margin-right: 19px;
}
}
@media only screen and (max-width:1066px){
  .nav__menu--links a:nth-of-type(2),  .nav__menu--links a:nth-of-type(3){
    display: none;
  }
  .nav__menu--links a:nth-of-type(1) {
    font-size: 2.2rem;
    margin-right: 28px;
}
}

@media only screen and (max-width:856px){
  .nav__menu--links a:nth-of-type(1){
    display: none;
  }
}
