.makePayment {
  padding: 6.938rem 7.5rem 0rem 7.5rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: 100%;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.makePayment__content--heading {
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 5px;
}
.makePayment__content--paragraph {
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 5.813rem;
}
.makePayment__chooseCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.makePayment__klarna {
  width: 18.75rem;
  height: 8.125rem;
  border-radius: 1.25rem;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(116, 38, 251, 0.2);
  border: none;
  background-color: #fff;
  background-image: url("../../../assets/images/klarna.svg");
  background-position: center;
  background-size: 8.088rem;
  background-repeat: no-repeat;
}
.makePayment__klarna:focus,
.makePayment__creditCard:focus {
  border: solid 0.125rem #2699fb;
}
.makePayment__creditCard {
  width: 18.75rem;
  height: 8.125rem;
  border-radius: 1.25rem;
  box-shadow: 0 1.25rem 1.875rem 0 rgba(116, 38, 251, 0.2);
  border: none;
  background-color: #fff;
  background-image: url("../../../assets/images/creditCard.svg");
  background-position: center;
  background-size: 12.662rem;
  background-repeat: no-repeat;
}
.makePayment__chooseCard .blueButton {
  margin-top: 9.25rem;
}
.makePayment__sep {
  display: flex;
  align-items: center;
  margin: 2.5rem 0rem;
  justify-content: space-between;
}
.makePayment__sep p {
  font-family: SF Pro Display Regular;
  font-size: 0.875rem;
  line-height: 1.36;
  color: #06016c;
}
.makePayment__sep div {
  border-top: 1px solid rgba(6, 1, 108, 0.05);
  width: 8.125rem;
}

@media only screen and (max-width:800px){
  .makePayment{
    width: 44.25rem;
  }
  .makePayment__content--heading {
    font-size: 3.25rem;
  }
  .makePayment__content--paragraph {
    font-size: 2.25rem;
  }
  .makePayment__sep p {
    font-size: 1.5rem;
  }
  .makePayment__klarna, .makePayment__creditCard {
    width: 25.75rem;
    height: 12.125rem;
  }
  .makePayment__creditCard {
    background-size: 18.662rem;
  }
  .makePayment__klarna {
    background-size: 11.088rem;
  }
}
@media only screen and (max-width:575px){
  .makePayment {
    width: 53.25rem;
}
}
@media only screen and (max-width:500px){
  .makePaymentAnimated{
    height: calc(100vh - 25rem) !important;
    width: 100%;
    top:12.5rem!important;
    overflow: hidden;
  }
  .makePayment {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
  }
  .makePayment__content--heading {
    font-size: 7.25rem;
}
.makePayment__content--paragraph {
  font-size: 4.25rem;
}
.makePayment__klarna, .makePayment__creditCard {
  width: 78%;
  height: 27.125rem;
}
.makePayment__klarna {
  background-size: 27.088rem;
}
.makePayment__creditCard {
  background-size: 30.662rem;
}
.makePayment__sep p {
  font-size: 3.5rem;
}
.makePayment__sep div {
  width: 22.125rem;
}
}
@media only screen and (max-width:413px){
  .makePayment__sep div {
    width: 17.125rem;
}
}
@media only screen and (max-width:352px){
  .makePayment__sep div {
    width: 13.125rem;
}
}