.personalSettings {
  width: 57rem;
}
.personalSettings form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.personalSettings__leftSide {
  width: 25rem;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.personalSettings__leftSide .form__DocumentUpload {
  height: 7.5rem;
  background-color: white;
}
.personalSettings__leftSide .form__DocumentUpload .dropzoneFile {
  background-size: 2.219rem !important;
}
.personalSettings__leftSide .form__DocumentUpload .uploadPlaceholder {
  font-size: 1.375rem !important;
  line-height: 1.36 !important;
  word-spacing: 6.2px;
}
.personalSettings__leftSide .form__DocumentUpload .fileUpload__button {
  font-size: 1.375rem !important;
}
.personalSettings_rightSide {
  width: 25rem;
  display: flex;
  flex-direction: column;
}
.personalSettings .group {
  margin-bottom: 3.063rem;
  width: 100%;
}
.personalSettings .group .form-input {
  width: 100%;
  /*border: none !important;*/
}

.personalSettings_rightSide .blueButton {
  display: initial;
  margin: 0rem;
  align-self: flex-end;
}

.personalSettings__leftSide--uploaded {
  position: relative;
}
.personalSettings__leftSide--uploaded label {
  position: absolute;
  top: -1.44rem;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  font-family: SF Pro Display Regular;
}

.personalSettings__labelClass {
  line-height: 1.31 !important;
  color: #06016c;
  position: absolute;
  top: 0.9rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  left: 1.5rem;
  display: block;
  /*z-index: -1;*/
}
.personalSettings__labelClass--exp {
  top: -1.5em;
  left: 0rem;
  color: #7977a4;
  cursor: pointer;
  position: absolute;
}
.personalSettings__borderStyle {
  border: solid 2px rgba(121, 119, 164, 0.5) !important;
  font-size: 1rem !important;
  line-height: 1.31 !important;
  color: #06016c !important;
  border-radius: 25px !important;
  padding: 0rem 1.25rem 0rem 1.25rem !important;
  background-color: transparent;
}
