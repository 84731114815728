.addInformationFinished {
  padding: 10.875rem 8.125rem 0rem 8.125rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.addInformationFinished__content--heading {
  text-align: center;
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 0.3125rem;
}
.addInformationFinished__content--paragraph {
  margin-bottom: 79px;
  font-size: 1.25rem;
  line-height: 1.35;
  text-align: center;
}
.addInformationFinished__contentImage {
  margin-left: 1.563rem;
  width: 21.884rem;
  height: 11.563rem;
}
.addInformationFinished__content .blueButton {
  width: 100%;
  margin: 4.5625rem auto 0rem auto;
}
.addInformationFinished__content a {
  text-decoration: none;
}

