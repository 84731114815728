.accountMobile {
  width: 100%;
  overflow-y: scroll;
}
.accountMobileProfile {
  height: 337px;
  background-color: rgba(6, 1, 108, 0.05);
}
.accountMobileProfile__top {
  height: 50%;
  background-color: #06016c;
}
.accountMobileProfile__bottom {
  height: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userImage__mobile {
  margin-top: 0px;
  width: 24.25rem;
  height: 24.25rem;
  position: absolute;
  top: -55px;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(6, 1, 108, 0.2);
}
.userImage__mobileName {
  margin-top: 80px;
  font-size: 4.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SF Pro DisplayMedium;
  color: #06016c;
}
.userImage__mobileName a {
  color: grey;
  font-size: 2.5rem;
  font-family: SF Pro Display Regular;
  margin-top: 5rem;
  text-decoration: none;
}
.accountMobile__navbar {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.accountMobile__navbar a {
  width: 100%;
  font-size: 4rem;
  text-decoration: none;
  color: rgb(71, 71, 71);
  padding: 15px 20px;
  border-bottom: 0.063rem solid rgb(168, 168, 168);
  font-family: SF Pro Display Regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accountMobile__navbar a:nth-of-type(1) {
  border-top: 0.063rem solid rgb(168, 168, 168);
}
.accountMobile__navbar a svg {
  fill: #bebdbd;
}
.shipmentViewUserCard__mobile {
  margin: 22px auto;
  width: 85%;
  height: 155px;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(6, 1, 108, 0.2);
}
.accontMobile__links {
  margin: 0px auto;
  width: 85%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.accontMobile__links a {
  color: #06016c;
  text-decoration: none;
  font-size: 3rem;
  font-family: SF Pro Display Regular;
}
