.shipmentViewUserCard {
  /* width:71.938rem; */
  width: 100%;
  height: 11.438rem;
  display: flex;
  margin-bottom: 5rem;
  justify-content: space-between;
}
.shipmentViewUserCard__left {
  display: flex;
  width: 75%;
}
.shipmentViewUserCard__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}
.shipment__userImage {
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 99999px;
  margin-bottom: 1.438rem;
  background-position: center;
  background-size: cover;
  background-color: #915df4;
  cursor: pointer;
}
.shipment__userName {
  display: flex;
  align-items: center;
}
.shipmentView__check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
.shipment__userName:nth-last-child(1) {
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.33;
  color: #06016c;
  white-space: nowrap;
  display: flex;
}
.shipmentViewUserCard__message {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.shipmentViewUserCard__infoBox {
  display: flex;
  position: relative;
  width: 100%;
}
.infoBox {
  box-shadow: 0 20px 33px 0 rgba(6, 1, 108, 0.1);
  width: 98%;
  height: 7.5rem;
  border-radius: 1.25rem;
  background-color: #ffffff;
  margin-top: 0.813rem;
  position: absolute;
  right: 0px;

  padding: 1.563rem 1.625rem 1.25rem 1.875rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  overflow: hidden;
  cursor: pointer;
}
.infoBox > div {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 4.5rem;
}
.infoBox ~ div div div {
  height: fit-content;
  padding: 1.5rem 2.5rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
.infoBoxModal__close {
  text-decoration: none;
  display: block;
  width: fit-content;
  margin: 0px auto;
  margin-top: 2rem;
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  background-color: rgba(6, 1, 108, 0.05);
  font-family: SF Pro Display Regular;
  line-height: 1.33;
  color: #06016c;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 0.688rem solid transparent;
  border-bottom: 0.688rem solid transparent;
  position: absolute;
  border-right: 1.188rem solid white;
  top: 2.156rem;
  left: 0.57%;
  z-index: 99;
}
.shipmentViewUserCard__messageButton {
  width: 15rem;
  height: 2.188rem;
  border-radius: 0.688rem;
  background-color: #7426fb;
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  text-align: center;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shipmentViewUserCard__right {
  width: 21.875rem;
  height: 100%;
  border-radius: 1.438rem;
  background: linear-gradient(120deg, #2699fb 6%, #7426fb 96%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2.481rem;
}
.shipmentViewUserCard__right p {
  font-family: SFProDisplay-Bold;
  font-size: 1.188rem;
  line-height: 1.37;
  color: #ffffff;
  margin-bottom: 1.125rem;
}
.shipmentViewUserCard__rightButtons {
  display: flex;
  justify-content: space-between;
  width: 16.688rem;
}
.shipmentViewUserCard__rightButtons button {
  width: 7.625rem;
  height: 2.938rem;
  border-radius: 1.063rem;
  border: solid 0.188rem #ffffff;
  background-color: transparent;
  font-family: SFProDisplay-Bold;
  font-size: 0.688rem;
  line-height: 1.36;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shipment__whatsappIcon {
  width: 1.606rem;
  height: 1.619rem;
  margin-right: 0.344rem;
}
.shipment__viberIcon {
  width: 1.656rem;
  height: 1.75rem;
  margin-right: 0.406rem;
}
.shipment__chat {
  width: 1.269rem;
  height: 1.313rem;
  margin-left: 0.831rem;
}

.shipmentViewUserCard .CircularProgressbar {
  width: 6.25rem !important;
  height: 6.25rem !important;
}
.shipmentViewUserCard__rightButtons a {
  text-decoration: none;
}
.shipmentViewUserCard .circularProgressbar__value {
  font-size: 0.75rem;
}
.shipmentViewUserCard .circularProgressbar__value span {
  font-size: 1.2rem;
  color: #7426fb;
}
#CircularProgressbarWithChildren__children {
  background-color: rgb(145, 93, 244);
  border-radius: 500px;
}

@media only screen and (max-width: 500px) {
  .shipmentViewUserCard .CircularProgressbar {
    width: 16.25rem !important;
    height: 16.25rem !important;
  }
  .shipmentViewUserCard .circularProgressbar__value {
    font-size: 2.75rem;
  }
  .circularProgressbar__value {
    top: -0.5rem !important;
    right: -4.875rem !important;
  }
  .shipmentViewUserCard .circularProgressbar__value span {
    font-size: 4.2rem;
  }
  .shipmentViewUserCard__message{
    height: 27rem;
  }
  .shipmentViewUserCard__infoBox {
    width: 92%;
  }
  .infoBox {
    height: 19.5rem;
    border-radius: 3.25rem;
    font-size: 2.5rem;
  }
  .shipment__userName:nth-last-child(1) {
    font-size: 2.25rem;
  }
  .shipmentViewUserCard__right {
    width: 100%;
    height: 55%;
    border-radius: 5.438rem;

    margin-left: 0rem;
  }
  .shipmentViewUserCard__right p {
    font-size: 3rem;
    margin-bottom: 4.125rem;
  }
  .shipmentViewUserCard__rightButtons button {
    width: 22.625rem;
    height: 8.938rem;
    border-radius: 3.063rem;
    font-size: 2.688rem;
  }
  .shipment__viberIcon {
    width: 3.656rem;
    height: 3.75rem;
  }
  .shipment__whatsappIcon {
    width: 4.606rem;
    height: 3.619rem;
  }
  .shipmentViewUserCard__rightButtons {
    width: 47.688rem;
  }
  .arrow-left {
    border-top: 1.688rem solid transparent;
    border-bottom: 1.688rem solid transparent;
    border-right: 2.188rem solid white;
    top: 3.156rem;
    left: -0.43%;
  }
  .shipmentViewUserCard__message .chatButton{
    width: 21rem;
    height: 5.188rem;
    font-size: 2.1rem;
  }
  .chatButton__chatIcon {
    width: 2.269rem;
    height: 2.313rem;
}
}
