.shipmentViewCard {
  height: 23.688rem;
  /* width:71.938rem; */
  width: 100%;
  background-color: white;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  border-radius: 1.25rem;
  display: flex;
  margin-bottom: 1.813rem;
}
.shipmentViewCard__left {
  height: 23.688rem;
  /* height: auto; */
  width: 35.44rem;
  /* width: 23.32%; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}
.image-slider,
.image-slider > div {
  height: 23.688rem !important;
  width: 21.875rem !important;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}
.image-slider {
  background: transparent !important;
}
.image-slider > div div {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
}
.shipmentViewCard__right {
  height: 100%;
  padding: 3.375rem 4.063rem 2.438rem 5.188rem;
  width: calc(100% - 35.44rem);
  /* width: 76.68%; */
  position: relative;
}
.shipmentViewCard__right--content {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.shipmentViewCard__right .blueButton {
  position: absolute;
  box-shadow: 0 5px 15px 0 rgba(6, 1, 108, 0.15);
  right: 3.219rem;
  top: -1.563rem;
  width: 9.375rem;
  margin: 0rem;
}
.right__content--secondRow {
  max-width: 11rem;
}
.image-gallery {
  height: 100% !important;
}

.image-gallery-image,
.image-gallery-slides {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}
.image-gallery-svg {
  width: 31px !important;
}
.image-gallery-image {
  /* height:100%; */
  object-fit: cover !important;
  min-height: 100%;
}
.image-gallery-slide > div {
  height: 100% !important;
}
.image {
  width: 100%;
  height: 100%;
}
.image-gallery-slides,
.image-gallery-swipe,
.image-gallery-slide-wrapper .bottom,
.image-gallery-content .bottom,
.image-gallery-content {
  height: 100% !important;
}

.shipmentViewCard__main > .blueButton{
  display: none;
}
.right__content--rows{
  width: 30%;
}

@media only screen and (max-width:770px){
  .shipmentViewCard__right .blueButton {
    width: 9.85rem;
}
}
@media only screen and (max-width:745px){
  .shipmentViewCard__right .blueButton {
    width: 10.5rem;
    right: 2.219rem;
}
}
@media only screen and (max-width:633px){
  .shipmentViewCard__right .blueButton {
    width: 11.5rem;
    right: 1.219rem;
}
}