.documentUpload {
  background-position: center;
  background-repeat: no-repeat;
  width: 6.9375rem!important;
  min-height: 7.8125rem!important;
  border-radius: 0.625rem !important;
  box-shadow: 0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #ffffff !important;
  padding: 1rem 1.0875rem 1.0125rem 1.2875rem;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0.313rem;
  cursor: pointer;
}
.document__fileName {
  font-family: SFProDisplay-Bold !important;
  font-size: 0.75rem!important;
  line-height: 1.33 !important;
  color: #06016c !important;
  margin-bottom: 0.4px !important;
  width: 4.75rem;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.document__fileType {
  position: absolute;
  top: 2.769rem;
  align-self: center;
  color: white;
  font-family: SFProDisplay-Bold !important;
  font-size: 0.688rem;
}
.document__fileSize {
  font-family: SF Pro Display Regular !important;
  font-size: 0.625rem!important;
  line-height: 1.4 !important;
  color: #7977a4 !important;
}
.document__image {
  margin: 0px auto;
  margin-bottom: 0.156rem;
  width: 2.8125rem;
  height: 4.0625rem;
}
@media only screen and (max-width:500px){
  .documentUpload {
    width: 17.9375rem!important;
    min-height: 22.8125rem!important;
    padding-top:2rem;
  }
  .document__image {
    width: 7.8125rem;
    height: 11.0625rem;
    margin-bottom: 2.156rem;
}
.document__fileType {
  top: 6.769rem;
  font-size: 2.688rem;
}
.document__fileName {
  font-size: 2rem!important;
  width: 15.75rem;
}
}