.updateTruck .truckDetails--paragraph {
  margin-bottom: 0.563rem;
}
.updateTruck .truckDetails__form--exception {
  margin-bottom: 1.25rem;
}
.updateTruck__firstInputGroup .group,
.updateTruck__firstInputGroup .form-input {
  width: 100%;
}
