footer,
.line {
  width: 100%;
}

@media screen and (max-width: 1530px) {
  .steps {
    top: 775px;
  }
}

@media screen and (max-width: 1160px) {
  .inputHolder {
    left: calc(50% - 200px);
  }

  .inputHolder:nth-of-type(2) {
    left: calc(50% + 90px);
  }

  .theFirstLine {
    left: calc(50% + 50px);
  }

  .theSecondLine {
    left: calc(50% + 341px);
  }

  .searchIcon {
    left: calc(50% + 383px);
  }

  .coverPhotoSimulated {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1340px) {
  /* .nav__menu {
    display: none;
  } */

  .coverPhotoSimulated {
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (max-width: 1313px) {
  .steps {
    position: sticky;
    margin: 0 auto;
    margin-top: -100px;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 990px) {
  .inputHolder {
    left: calc(50vw - 300px);
    top: 7px;
  }

  .inputHolder:nth-of-type(2) {
    left: calc(50vw - 10px);
  }

  .theFirstLine {
    left: calc(50vw - 50px);
    top: 67px;
  }

  .theSecondLine {
    left: calc(50vw + 241px);
    top: 67px;
  }

  .searchIcon {
    left: calc(50vw + 283px);
    top: 35px;
  }

  .footer__kucice {
    width: 500px;
  }

  .footer__kucice--single {
    margin-right: 20px;
  }
}

@media screen and (max-width: 797px) {
  .footer__social img:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .footer__social {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

#addShipment button {
  outline: none;
  border: 2px solid #c1c0db;
  background: transparent;
  width: 300px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 18px;
  font-family: SF Pro Display Regular;
  margin: 0 auto;
}

#addShipment .loginPopUp__or {
  margin-top: 29px;
}

#addShipment button:hover {
  border: 2px solid #2699fb;
  cursor: pointer;
}

#addShipment .loginPopUp__container {
  height: 255px;
  top: 440px;
}

#addShipment h2,
#vehicleType h2 {
  font-family: SF Pro Display Semibold;
  font-size: 28px;
  color: white;
  line-height: 1;
  position: absolute;
  top: -40px;
  left: 0;
}

@media screen and (max-width: 1230px) {
  #addShipment .loginPopUp__container {
    top: 300px;
  }
}

@media screen and (max-width: 1000px) {
  #addShipment .loginPopUp__container {
    top: 0px;
  }
}

@media screen and (max-width: 420px) {
  #addShipment button {
    width: 250px;
  }
}

#vehicleType button {
  width: 150px;
  height: 50px;
  border: 0;
  background: #06016c;
  border-radius: 25px;
  font-size: 18px;
  color: #fff;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  font-family: SF Pro Display Regular;
  margin: 0 auto;
}

#vehicleType .flexRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#vehicleType .flexRow__single img {
  margin: 0;
}

#vehicleType .flexRow__single {
  display: flex;
  font-family: SF Pro Display Regular;
  font-size: 18px;
  flex-direction: column;
  align-items: center;
  color: #06016c;
  justify-content: center;
  width: 100px;
}

#vehicleType .flexRow__single span {
  margin-top: 15px;
}

.flexRow:nth-of-type(2),
.flexRow:nth-of-type(3) {
  margin-top: 50px;
}

.flexRow:nth-of-type(3) {
  margin-bottom: 30px;
}

#vehicleType .loginPopUp__container {
  height: 480px;
  padding-bottom: 0px;
  padding-top: 50px;
}

/* HEY EjLEM, PLEASE DON'T FORGET TO CHANGE THIS IN THE ORIGINAL CSS */
@media screen and (max-width: 1230px) {
  .tingle-modal-box__content img:nth-child(1) {
    width: auto;
  }
}

@media screen and (max-width: 1230px) {
  #vehicleType .loginPopUp__container {
    left: 294px;
    top: 241px;
  }
}

@media screen and (max-width: 1000px) {
  #vehicleType .loginPopUp__container {
    left: auto;
    top: 70px;
  }

  #vehicleType .flexRow img {
    display: block;
  }
}
@media only  screen and (max-width:520px){
  .steps__single {
    margin-bottom: 75px!important;
}
  .nav__menu--links{
    display: none;
  }
  .homepageMain .searchArea input{
    width: 100%!important;
  }
  .homepageMain .searchArea input::placeholder{
      font-size: 18px!important;
  }
  .homepageMain .inputHolder:nth-child(1), .inputHolder:nth-child(2){
    width: 73%;
  }
  button#searchButton{
    width: auto;
  }
  .loginPopUp__holder input::placeholder {
      font-size: 3rem;
  }
}

@media screen and (max-width: 510px) {
  #vehicleType .loginPopUp__container {
    top: auto;
  }

  #vehicleType .loginPopUp__container {
    height: 461px;
    padding: 20px;
  }

  #vehicleType .flexRow:nth-of-type(1) {
    margin-top: 30px;
  }
}

#vehicleType .flexRow__single:hover {
  cursor: pointer;
}

#vehicleType .flexRow__single:hover span {
  color: #2699fb;
}
@media only screen and (max-width:442px){
  .nav__menu--buttons button{
    width: 110px!important;
  }
  .flag-select__btn:before{
    width: 80%!important;
  }
}

@media only  screen and (max-width:437px){
  .footer__kucice {
    margin-left: 20px!important;
}
}
@media only  screen and (max-width:427px){
  .footer__kucice {
    margin-left: 15px;
}
.footer__kucice--single {
  margin-right: 10px;
}
}
@media only screen and (max-width:411px){
  .footer__kucice--single {
    margin-right: 15px!important;
}
}
@media only screen and (max-width:403px){
  .flag-select{
    margin-left: 0px!important;
    width: 122px!important;
  }
}
@media only screen and (max-width:395px){
  .footer__kucice--single p {
    font-size: 13px;
}
}
@media only screen and (max-width:389px){
  .flag-select{
    width: 126px;
  }
}
@media only screen and (max-width:377px){
  .flag-select__option.has-label {
    padding: 0 10px 3px 8px;
}
}
@media only screen and (max-width:363px){
  .nav__menu--buttons button{
    width: 97px!important;
  }
  .flag-select {
    width: 115px!important;
}
}
@media only screen and (max-width:355px){
  .footer__kucice--single {
    margin-right: 11px;
  }
}
@media only screen and (max-width:343px){
  .footer__kucice--single {
    margin-right: 11px;
  }
}
@media only screen and (max-width:333px){
  .flag-select__options {
    font-size: 18px !important;
    left: 0px;
}
}