.registration {
  background: url("../../assets/images/altBgCar.svg") no-repeat, #06016c;
  background-size: cover;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  position: absolute;
}

.newBg {
  background: url("../../assets/images/altBgTruck.svg") no-repeat, #06016c;
  background-size: cover !important;
}

html {
  font-size: 16px;
}

.logo {
  position: absolute;
  top: 34px;
  left: 70px;
  width: 200px;
}

.row {
  margin-top: 15px;
}

.content {
  width: 40%;
  height: 100vh;
  background: white;
  display: block;
  float: right;
}

.footer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 72px;
  left: 96px;
}

.footer__copyright {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.footer__copyright div {
  display: flex;
  justify-content: center;
}

.footer__copyright--icon {
  cursor: pointer;
}

.footer__copyright--icon:nth-of-type(1) {
  margin-right: 22px;
  margin-left: 22px;
}

.footer h3 {
  font-family: SF Pro Display-Black, sans-serif;
  font-size: 0.75rem;
  color: #fff;
}

.footer p {
  font-family: "SF Pro Display Regular", sans-serif;
  font-size: 1rem;
  color: #fff;
}

.content h1 {
  font-family: SF Pro Display-Bold, sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #06016c;
}

.content h2 {
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 100;
  font-size: 20px;
  margin-top: 10px;
  color: #06016c;
  margin-bottom: 35px;
}

.content {
  display: flex;
  align-items: flex-start;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.registration form input,
.phoneInput {
  border-radius: 25px !important;
  border: solid 2px #b3b1d2 !important;
  outline: none !important;
  padding: 14px 23px !important;
  height: 57px !important;
  font-family: "SF Pro Display Regular", sans-serif !important;
  font-size: 18px !important;
  color: #06016c !important;
}
.react-tel-input .form-control{
  font-size: 18px!important;
}
.registration form input::placeholder, .phoneInput::placeholder{
  font-size: 16px;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent;
}

.flag-dropdown {
  border-radius: 25px !important;
  outline: none !important;
  border: solid 2px #b3b1d2 !important;
  background: white !important;
  width: 100px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.react-tel-input .selected-flag {
  margin: 0 auto;
  transform: scale(1.4);
  background: transparent !important;
}

.react-tel-input .selected-flag:hover {
  background: transparent !important;
}

::placeholder {
  font-family: "SF Pro Display Regular", sans-serif;
  font-size: 20px;
  color: #06016c;
  transform: translate3d(0, 0px, 0) !important;
}

.arrowsRight {
  margin-left: 22px;
  margin-right: 22px;
}

/* .rowHolder {
  display: flex;
  align-items: center;
} */
.contentHide {
  display: none !important;
}

.phoneInput {
  width: 100% !important;
  padding-left: 120px !important;
  font-size: 1.25rem !important;
}

.rowHolder input {
  width: 205px;
  box-sizing: border-box;
}

.customInput {
  display: flex;
  flex-direction: column;
}

.search-box {
  height: 10px !important;
  font-size: 0.9375rem !important;
  width: 97% !important;
  padding-left: 10px !important;
  border-radius: 5px !important;
  margin: 0 !important;
  border-color: #eee !important;
}

.react-tel-input .country-list {
  box-shadow: none !important;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 15px !important;
  height: 212px !important;
}

.search-box::placeholder {
  font-size: 0.9375rem !important;
  color: gray !important;
}

.react-tel-input .country-list .country {
  padding-left: 16px;
}

.react-tel-input .country-list .country-name {
  font-family: "SF Pro Display Semibold", sans-serif !important;
}

.react-tel-input .country-list .country .dial-code {
  font-family: "SF Pro Display Regular", sans-serif !important;
  font-size: 80%;
}

.row textarea {
  height: 145px;
  width: 100%;
  border-radius: 25px !important;
  outline: none !important;
  border: solid 2px #b3b1d2 !important;
  background: white !important;
  padding: 20px;
  color: #06016c;
  font-family: SF Pro DisplayMedium, sans-serif !important;
  resize: none;
  font-size: 1.25rem !important;
}

.row textarea::placeholder {
  font-size: 20px !important;
}

.registration input:focus {
  border: 2px solid #2699fb !important;
}

input:focus + .flag-dropdown {
  border-right: 2px solid #2699fb !important;
}

.registration button[type="submit"],
.secondMiddle__button {
  outline: none;
  width: 145.2px;
  height: 50px;
  background-color: #06016c;
  border-radius: 50px;
  border: none;
  color: #fff;
  font-family: SF Pro Display-Bold, sans-serif !important;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 38px;
  z-index: 9999;
  cursor: pointer;
  text-decoration: none;
}
.rowHolder input {
  width: 100% !important;
}

.rowHolder {
  justify-content: space-between;
}

.registration .content h2 {
  width: auto !important;
}
.registration .SMSholder > h2{
  width: 60% !important;
  margin-left: auto;
  margin-right: auto;
}
.login .content h2:nth-last-of-type(1){
  width: 75%!important;
}

.contentModified {
  text-align: center;
}

.shakeIt {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  /* transform: translate3d(0, 0, 0);
    backface-visibility: hidden; */
  /* perspective: 1000px; */
}

.shakeItAlt {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  /* transform: translate3d(0, 0, 0);
    backface-visibility: hidden; */
  /* perspective: 1000px; */
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.registration .flag-select,
.registration .flag-select__btn {
  outline: none !important;
  font-family: SF Pro Display Semibold;
  cursor: pointer !important;
}

.registration .flag-select {
  position: absolute;
  right: 20px;
  top: 20px;
}

.registration .flag-select__btn:after,
.registration .flag-select__btn[aria-expanded="true"]:after {
  display: none;
}

.customModalOffer {
  position: relative;
}

.contentFirstStep button {
  outline: none;
  width: 300px;
  height: 50px;
  border-radius: 25px;
  border: solid 2px rgba(6, 1, 108, 0.25);
  font-family: "SF Pro Display Regular";
  font-size: 16px;
  color: #06016c;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  margin: 0 auto;
}

.contentFirstStep button:hover {
  border: solid 2px #2699fb;
  cursor: pointer;
}

.contentZeroStep .orHolder,
.contentFirstStep .orHolder,
.secondStep .orHolder{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 45px;
}

.contentZeroStep .orHolder span,
.contentFirstStep .orHolder span,
.secondStep .orHolder span {
  display: block;
  height: 2.55px;
  width: 150px;
  background-color: #E5E5F0;
}
.contentZeroStep .orHolder p,
.contentFirstStep .orHolder p, 
.secondStep .orHolder p {
  margin-left: 18px;
  margin-right: 18px;
  font-family: "SF Pro Display Regular";
  font-size: 14px;
  color: #06016c;
}

/* The container */
.containerCheck {
  display: block;
  position: relative;
  margin-bottom: 12px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 6px;
  margin-top: 20px;
  padding-left: 55px;
  font-family: SF Pro DisplayMedium;
  color: #06016c;
}

/* Hide the browser's default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.row {
  position: relative;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: white;
  border: 3px solid #06016c;
  cursor: pointer;
}

.containerCheck input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.containerCheck input:checked ~ .checkmark:after {
  display: block;
}

.containerCheck .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #2699fb;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rowHolder input:nth-child(1) {
  margin-right: 15px;
}

.mapsHolder {
  width: 100% !important;
  font-size: 1rem !important;
}

.secondStep h1,
.secondStep h2 {
  text-align: left !important;
}

.secondStep h1 {
  margin-top: 20px;
}

.secondStep h2 {
  margin-bottom: 50px;
}

.secondStep button[type="submit"] {
  margin-top: 50px;
}

.illustrationHolder p {
  font-family: SF Pro Display Regular;
  font-size: 16px;
  color: #06016c;
  margin-top: -20px;
}

.contentZeroStep h2 {
  font-family: SF Pro Display Light;
}

.contentZeroStep button {
  margin-top: 100px;
}

.contentZeroStep h1 {
  margin-top: 50px;
}

.illustrationHolder:hover {
  cursor: pointer;
  font-family: SF Pro Display-Bold;
  font-weight: bold;
  font-size: 1.3rem;
}

.SMSholder span {
  font-family: SF Pro DisplayMedium;
  font-size: 16px;
  color: #2699fb;
  cursor: pointer;
  user-select: none !important;
}

.pincode-input-text {
  box-sizing: content-box;
  width: 70px !important;
  height: 30px !important;
  font-family: SF Pro Display-Bold;
  background-color: #ffffff;
  border-radius: 15px !important;
  color: #06016c;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: 2px solid rgb(180, 178, 211) !important;
  font-size: 36px;
  margin-right: 20px !important;
}

.registration input:focus {
  border: 2px solid #06016c !important;
  line-height: 1 !important;
}

.contentZeroStep {
  position: absolute;
  right: -20px;
}

.Toastify__toast {
  height: 70px;
  border-radius: 10px;
  font-family: SF Pro Display Light;
  font-size: 16px;
}

.fontStyle__paragraph {
  font-size: 20px !important;
}

.fontStyle__title {
  font-size: 18px !important;
}

.fontStyle__h2 {
  font-size: 35px !important;
}

.fontStyle__paragraph {
  font-family: SF Pro Display Light !important;
  font-weight: 300;
  line-height: 2;
}
.section__icons p {
  font-size: 20px;
}

.iconStyle {
  width: 45px;
  height: auto;
}

.section__button button {
  font-size: 25px !important;
  width: 327px !important;
  height: 84px !important;
}

button[type="submit"] {
  z-index: 99999999999999;
}

.registration .footer {
  max-width: 500px !important;
}

.submitBefore {
  outline: none;
  width: 145.2px;
  height: 50px;
  background-color: #06016c !important;
  border-radius: 50px;
  border: none;
  color: #fff !important;
  font-family: SF Pro Display-Bold, sans-serif !important;
  font-size: 1.15rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 38px;
  z-index: 9999;
  cursor: pointer;
  margin-top: 30px !important;
  width: 150px !important;
}

.loginPopUp__button {
  background-color: #06016c !important;
  width: 200px !important;
  height: 45px !important;
}

.contentFirstStep .loginPopUp__button {
  margin: 0 !important;
}

.contentFirstStep .loginPopUp__container--buttons {
  width: 350px;
  margin: 0 auto;
}

.contentFirstStep .loginPopUp__container--buttons img {
  width: 27px !important;
  cursor: pointer;
}

.contentFirstStep .loginPopUp__button img {
  width: 20px !important;
}

.contentFirstStep .loginPopUp__container--buttons img:nth-of-type(2) {
  width: 15px !important;
  padding-bottom: 5px;
}
.register__googleFbButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}
.register__googleFbButton img {
  cursor: pointer;
}
.register__googleFbButton .loginPopUp__button {
  margin-right: 30px;
  cursor: pointer;
}
.react-tel-input .form-control {
  padding-left: 120px !important;
}
@media screen and (max-width: 1500px) {
  .content {
    width: 50%;
  }
  html {
    font-size: 13px;
  }
  .row {
    margin-top: 15px;
  }
}
@media only screen and (max-width:1425px){
  .login .content h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width:1400px){
  .registration form input, .phoneInput, .react-tel-input .form-control, .react-tel-input .form-control::placeholder, .registration form input::placeholder{
    font-size: 1.6rem!important;
  }
}
@media only screen and (max-width:1195px){
  .content {
    width: 64%;
}
}
@media only screen and (max-width:1185px){
  .pincode-input-container{
    padding: 2.625rem;
  }
  .pincode-input-text{
    width: 7.375rem!important;
    height: 3.875rem!important;
  }
}
@media screen and (max-width: 1150px) {
  .registration .SMSholder > h2 {
    width: 90% !important;
  }
  .content > div {
    width: 70%;
  }
  .rowHolder input {
    width: 100%;
  }
  .content h2 {
    width: auto !important;
  }
  .pincode-input-text{
    margin-right: 7px !important;
  }
}
@media only screen and (max-width:1135px){
  .pincode-input-container{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}
@media only screen and (max-width:1044px){
  .pincode-input-text {
    width: 6.375rem!important;
  }
}
@media screen and (max-width: 1000px), screen and (max-height: 800px) {
  .content {
    height: 900px;
    margin-top: 100px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    float: none;
    clear: both;
  }
  .content h2{
    width: unset!important;
  }
  .content .flag-select__btn {
    color: white;
  }
  .contentModified {
    float: none;
    right: unset !important;
    top: 80px !important;
    height: fit-content !important;
    padding: 50px 0;
  }
  .logo {
    position: relative;
    top: unset;
    left: unset;
    display: flex;
    margin: 0 auto;
    margin-top: 90px;
    width: 100%;
  }
  .row input,
  .phoneInput,
  .flag-dropdown,
  .row textarea,
  .row input {
    background: transparent !important;
  }
  .footer {
    position: absolute;
    top: unset;
    bottom: -18.313rem;
    padding-top: 30px;
    padding-bottom: 30px;
    left: unset;
    right: 30px;
    width: 100%;
    margin: 0 auto;
    align-items: flex-end;
  }
  .footer h3 {
    display: none;
  }
  .registration button[type="submit"] {
    width: 160.2px !important;
    font-size: 1.2rem;
  }
  main h1{
    margin-top:0px!important;
  }
  .secondStep{
    height: fit-content;
    padding: 3rem 2rem;
    margin-top: 61px;
    margin-bottom: 6rem;
  }
}

@media only screen and (max-width:1000px){
.contentZeroStep .orHolder p, .contentFirstStep .orHolder p, .secondStep .orHolder p {
  font-size: 1.875rem;
}
.registration form input, .phoneInput, .registration form input::placeholder, .react-tel-input .form-control, .react-tel-input .form-control::placeholder{
  font-size: 1.85rem!important;
}
.registration button[type="submit"], .secondMiddle__button {
  font-size: 2.2rem;
}
}
@media only screen and (max-width:935px){
  .pincode-input-text {
    width: 7.375rem!important;
}
}
@media only screen and (max-width:890px){
.registration form input, .registration form input::placeholder, .react-tel-input .form-control, .react-tel-input .form-control::placeholder{
    font-size: 2.4rem !important;
}
.registration button[type="submit"], .secondMiddle__button {
  font-size: 2.6rem;
}
.content h1 {
font-size: 33px;
 }
}
@media only screen and (max-width:740px){
  .contentZero__h2{
    margin-bottom: 5px!important;
  }
  }
@media only screen and (max-width:670px){
.footer p {
  font-size: 2rem;
}
.secondStep input::placeholder{
  font-size: 2rem;
}
.registration button[type="submit"], .secondMiddle__button {
  font-size: 3rem;
}
}
@media only screen and (max-width:630px){ 
.pincode-input-container{
  padding-top:3.625rem;
  padding-bottom:3.625rem;
}
.pincode-input-text {
  width: 9.375rem!important;
  height: 5.875rem!important;
}
}
@media screen and (max-width: 560px) {
  .react-tel-input .form-control, .react-tel-input .form-control::placeholder, .registration form input, .registration form input::placeholder{
    font-size: 2.8rem!important;
  }
  .content h2 {
    width: auto!important;
    font-size: 3.5rem;
  }
  .react-tel-input .flag-dropdown {
    width: 80px !important;
  }
  .phoneInput {
    width: 100% !important;
    padding-left: 89px !important;
  }
  .rowHolder {
    flex-direction: column;
    align-items: center;
  }
  .rowHolder div {
    width: 100%;
  }
  .arrowsRight {
    margin-top: 15px;
    margin-bottom: 15px;
    transform: rotate(90deg);
  }
  .react-tel-input .form-control {
    padding-left: 90px !important;
}
}
@media only screen and (max-width:545px){
  .pincode-input-text {
    width: 11.375rem!important;
  }
  .SMSholder h2{
    width: auto!important;
  }
  .contentZeroStep .orHolder p, .contentFirstStep .orHolder p, .secondStep .orHolder p {
    font-size: 2.5rem;
}
}
@media only screen and (max-width:500px){
.containerCheck {
  font-size: 2.7rem;
}
.registration .react-tel-input .flag {
  width: 3rem!important;
}
.react-tel-input .form-control, .react-tel-input .form-control::placeholder, .registration form input {
  font-size: 3rem !important;
}
}
@media only screen and (max-width:490px){
  .illustrationHolder svg, .illustrationHolder img{
      width: 100%;
  }
  .login .content h2 {
    font-size: 20px;
}
  }
  @media only screen and (max-width:440px){
    .contentFirstStep button{
      width: 100%;
    }
  } 
@media only screen and (max-width:422px){
  .SMSholder{
    width:90%!important;
  }
}
@media only screen and (max-width:328px){
  .pincode-input-container {
    padding-left: 8rem!important;
    padding-right: 8rem!important;
  }
  .pincode-input-text {
    margin-bottom: 2rem!important;
  }
}