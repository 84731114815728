.customBackButton {
  width: 7.5rem;
  height: 2.5rem;
  box-shadow: 0 5px 15px 0 rgba(6, 1, 108, 0.15);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  color: #06016c;
  border: none;
  position: absolute;
  bottom: 2.25rem;
  left: -3.75rem;
  border-radius: 0.625rem;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.customBackButton span {
  margin-left: 0.713rem;
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
}
.customBackButton #Path_36 {
  fill: #06016c;
}
