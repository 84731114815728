.account {
  background-color: white;
  width: 100%;
  min-height: 100vh;
}
.account__main {
  display: flex;
  min-height: calc(100vh - 120px);
}
.account__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.02);
  padding-top: 6.063rem;
  padding-left: 5.438rem;
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
  color: #06016c;
}

.account__lineWidth {
  width: 13.188rem;
}
.accountSettings {
  width: fit-content;
  margin-top: 3.188rem;
}
.accountSettings__nav {
  margin-bottom: 3.188rem;
  font-family: SFProDisplay-Bold;
  border-bottom: 1px solid rgba(116, 38, 251, 0.2);
  padding-bottom: 0.625rem;
}
.accountSettings a {
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  padding: 0.625rem 1.531rem 0.625rem 1.531rem;
  text-decoration: none;
  color: #06016c;
  cursor: pointer;
}
.accountSettings__nav a:nth-last-child(1),
.accountSettings__nav a:nth-last-child(2) {
  margin-left: 2.406rem;
}
.accountSettings a.activeSetting {
  border-bottom: 3px solid #7701fc;
  background-color: rgba(119, 1, 252, 0.1);
}

.Toastify__toast-container--top-right{
  z-index: 999999999999!important;
}