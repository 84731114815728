.truckDetails__header {
  display: flex;
}
.truckDetails__year {
  font-size: 1.063rem;
  color: #2699fb;
}
.truckDetails__capacity {
  font-family: SFProDisplay-Bold;
  font-size: 1rem;
  line-height: 1.31;
  color: #06016c;
  margin-bottom: 0.938rem;
  padding: 0rem 7.5rem;
}
.truckDetails__description {
  font-family: SF Pro Display Regular;
  font-size: 1.25rem;
  line-height: 1.5;
  color: #7977a4;
  margin-bottom: 1.25rem;
  width: 100%;
  padding: 0rem 7.5rem;
}
.truckDetails__images {
  margin-bottom: 2.5rem;
  width: 26.438rem;
  height: 16.25rem;
  clear: both;
}
.truck__image {
  width: 12.625rem;
  height: 8rem;
  float: left;
  margin: 0.225rem;
}
.truckDetails__images img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}
.truckDetails__attachments {
  font-family: SF Pro Display Regular;
  font-size: 1rem;
  line-height: 1.31;
  color: #7977a4;
}
.numOfAttachments {
  font-family: SFProDisplay-Bold;
}
.truckDetails__line {
  width: 100%;
  height: 1px;
  background-color: #7977a4;
  margin: 0.625rem auto 1.563rem auto;
}
.truckDetails__documents {
  margin-bottom: 6.688rem;
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap;
}
.truckDetails__formPreview .blueButton {
  margin: 6.688rem auto 0rem auto !important;
}
.truckDetails__formPreview{
  padding: 0rem 7.5rem;
}
@media only screen and (max-height: 1027px) {
  .truckDetails__formPreview {
    overflow-y: scroll;
    height: 57.5vh;
    overflow-x: hidden;
  }
}
@media only screen and (max-width:500px){
  .truckDetails__year {
    font-size: 2.063rem;
}
.truckDetails__capacity {
  font-size: 2rem;
  line-height: 1.51;
}
.truckDetails__description {
  font-size: 2.25rem;
}
.truckDetails__images{
  width: 100%;
  height: 55.25rem;
}
.truck__image {
  width: 49%;
  height: 49%;
}
.truckDetails__documents div{
  width: 35%;
}
.truckDetails__documents .documentUpload {
  width: 100%!important;
  min-height: 23.8125rem!important;
  border-radius: 2.375rem!important;
  padding-top: 2rem;
  margin:0px;
}
.truckDetails__documents .document__image {
  margin-bottom: 7.5px;
  width: 50%;
  height: 11.0625rem;
}
.truckDetails__documents .document__fileType {
  top: 7.3rem;
}
.truckDetails__documents .document__fileName {
  font-size: 2.75rem /* 12px */ !important;
  margin-bottom: 0.4px !important;
  width: 97%;
}
}
@media only screen and (max-width:340px){
  .truck__image {
    width: 100%;
    height: 33%;
}
.truckDetails__images {
  height: 78.25rem;
}
.truckDetails__attachments {
  font-size: 2.5rem;
}
}