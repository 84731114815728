.loginNew .row:nth-last-child(2) {
  margin-top: 20px;
}

.loginPopUp__container ::placeholder {
  font-size: 1.1rem !important;
}
.backToLogin {
  text-decoration: none;
}
.resetLink h2 {
  font-family: SF Pro DisplayMedium;
  word-spacing: 2px;
}
.resetLink h2:nth-last-of-type(1) {
  font-family: SF Pro Display Light;
}
.backToLogin__button {
  height: 43px !important;
}
