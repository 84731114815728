.phoneVerification{

}

.phoneVerification h1{
    font-family: SF Pro Display-Bold, sans-serif;
    font-size: 2.563rem;
    font-weight: bold;
    color: #06016c;
}
.phoneVerification h2 {
    font-family: "SF Pro Display Regular", sans-serif;
    font-weight: 100;
    font-size: 1.375rem;
    margin-top: 10px;
    color: #06016c;
    margin-bottom: 35px;
}

.phoneVerification__bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.phoneVerification__bottom button{
    
    width: 9rem;
    height: 3rem;
    border-radius: 1rem;
    border: none;
    background-color: #06016c;
    color: white;
    margin-bottom: 70px;
    cursor: pointer;
}