.content__header h2 {
  font-family: SFProDisplay-Bold;
  color: #06016c;
  font-size: 1.875rem; /* 30px */
}
.content__header {
  position: relative;
  z-index: 9999;
  width: fit-content;
}
.content__header--line {
  width: 13.188rem;
}
