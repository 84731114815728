.recievedReviewsCard {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.recievedReviewsCard__card {
  width: 32.5rem; /* 520px */
  height: 15rem; /* 240px */
  border-radius: 20px;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.2);
  background-color: #ffffff;
  display: flex;
}
.recievedReviews__left {
  padding: 1.8125rem 1.75rem 1.125rem 2rem; /* 29px 28px 18px 32px */
  width: 21.25rem;
}
.reviewsLeft__review {
  font-family: SF Pro Display Regular;
  font-size: 0.875rem;
  line-height: 1.36;
  letter-spacing: 0.088rem;
  color: #7977a4;
  margin-top: 0.813rem;
  width: 100%;
  word-break: break-all;
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  height: 64%;
}
.reviewsLeft__top {
  display: flex;
}
.recievedReviews__right {
  height: 100%;
  width: 11.25rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.recievedReviews__person {
  margin-top: 0.438rem;
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  display: flex;
  align-items: center;
}
.recievedReviews__check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
.recievedReviews__emoji {
  width: 3rem;
  height: 3rem;
  margin-right: 1.25rem;
}

.popup__modal {
  height: -moz-fit-content;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  word-break: break-all;
}

.recievedReviewsCard ~ div >div > div:nth-last-of-type(1){
  background: transparent!important;
}