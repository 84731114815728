.addVehicleInformation {
  padding: 2.25rem 7.375rem 0rem 6.75rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.addVehicleInformation__content{
  overflow-y: auto;
  overflow-x: hidden;
    height: 82vh;
}
.addVehicleInformation__content--heading {
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 0.313rem;
}
.addVehicleInformation__content--paragraph {
  font-size: 1.25rem;
  line-height: 1.35;
}
.addVehicleInformation__content--select {
  margin-bottom: 2.125rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 15.75rem;
  align-items: flex-end;
}
.addVehicleInformation__content--input {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.125rem;
}
.addVehicleInformation__content--input .form-input {
  width: 7.5rem;
  line-height: 0.89;
}

.addVehicleInformation__content .form__ImageUpload {
  width: 100%;
  height: 19.875rem;
  margin-bottom: 3.125rem;
  border-radius: 1.125rem;
  border: dashed 2px #2699fb;
}
.firstImgPlaceholder {
  width: 16.688rem;
}
.imagePlaceholder {
  width: 12.313rem;
}
.addVehicleInformation__content .form__ImageUpload .imageUpload__placeholder {
  padding-top: 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 0.5;
  flex-direction: row;
}
.addVehicleInformation__content .form__ImageUpload .imageUpload__button {
  display: none;
}

.customSelectAddShipment {
  width: 12.188rem;
}

.customSelectAddShipment > div {
  border-width: 2px;
  border-radius: 1.563rem;
  box-shadow: none;
  outline: none;
  height: auto;
  overflow-y: -moz-hidden-unscrollable;
}
.css-yk16xz-control {
  border-color: #7977a4 !important;
  overflow-y: scroll;
}
.css-26l3qy-menu {
  overflow-y: auto !important;
  -ms-overflow-style: block !important; 
  scrollbar-width: block !important;
}

.css-26l3qy-menu ::-webkit-scrollbar {
  width: 5px;
}

.css-26l3qy-menu ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.css-26l3qy-menu ::-webkit-scrollbar-thumb {
  background: #888;
}

.css-26l3qy-menu ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-4ljt47-MenuList {
  overflow-y: scroll !important;
  scrollbar-width: block !important;
}
.css-26l3qy-menu::-webkit-scrollbar {
  display: block;
}
.css-yk16xz-control::-webkit-scrollbar {
  display: block;
}
.customSelectAddShipment > div:focus,
.customSelectAddShipment input:focus {
  border: solid 2px #2699fb;
}
.customSelectAddShipment input {
  border: none !important;
  width: 100% !important;
  font-family: SF Pro Display Regular;
  color: #06016c !important;
  font-size: 1rem !important;
}

.customSelectAddShipment > div > div > div {
  color: #06016c !important;
}
.customSelectAddShipment input::placeholder {
  border: none !important;
  width: 100% !important;
  font-family: SF Pro Display Regular;
  color: #06016c !important;
}

.customSelectAddShipment > div:nth-child(3) {
  border: 2px solid #06016c;
  border-radius: 10px;
  height: 140px;
  background: white;
  z-index: 9999;
}

.css-4ljt47-MenuList {
  max-height: 135px !important;
}

.css-9gakcf-option {
  background-color: rgba(6, 1, 108, 0.53) !important;
}

.form-input::placeholder {
  font-size: 1rem;
}
.addVehicleInformation__content--imageParagraph {
  font-size: 1.25rem;
  line-height: 1.35;
  padding-bottom: 1.5rem;
}
/* ALEM TO-DO */
/* .customSelectAddShipment:hover ~ .form-label {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
} */
/* .customSelectAddShipment input:focus .form-label {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
} */
.css-yk16xz-control,
.css-1pahdxg-control {
  min-height: 3.129rem !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0.5rem !important;
}
.serialNum__inputField{
  margin-bottom: 2.5rem;
}
.serialNum__inputField .group, .serialNum__inputField .group .form-input{
  width: 100%!important;
}
.shakeIt {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.shakeItAlt {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
@media only screen and (max-width: 1236px) {
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0.42rem !important;
}
}
@media only screen and (max-width: 1199px) {
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0.3rem !important;
}
}
@media only screen and (max-width:918px){
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0.2rem !important;
  }
  .customSelectAddShipment, .form-input  {
    width: 13.188rem;
  }
  .addVehicleInformation__content--input .form-input {
    width: 8.5rem;
  }
}
@media only screen and (max-width:844px){
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0.08rem !important;
}
}
@media only screen and (max-width:745px){
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding: 0rem !important;
}
}
@media only screen and (max-width:633px){
  .css-6q0nyr-Svg{
    height:3rem;
  }
  .css-g1d714-ValueContainer{
    padding:0px 8px!important;
  }
  .addVehicleInformation .form-input {
    height: 3.5rem!important;
}
}
@media only screen and (max-width: 500px) {
  .customSelectAddShipment input,
  .customSelectAddShipment > div > div > div {
    font-size: 3rem !important;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 2.5rem !important;
  }
  .serialNum__inputField .form-input {
    height: 9rem!important;
}
.addVehicleInformation{
  padding-left: 6rem;
  padding-right: 6rem;
}
.addVehicleInformation__content--input{
  margin-bottom: 6.125rem;
  flex-direction: column;
    height: 39rem;
}
.addVehicleInformation__content--input .group {
  width: 100%;
}
.addVehicleInformation__content--input .form-input {
  width: 100%;
  height: 9rem!important;
}
.addVehicleInformation__content .form-label {
  line-height: 2!important;
}
.form-label span {
  font-size: 1.75rem;
}
.addVehicleInformation__content{
  padding:0rem 1.5rem;
}
.addVehicleInformation__content--select > div:nth-last-of-type(1) .group .form-input{
  width: 100%;
  height: 9rem!important;
}
.addVehicleInformation__content--select > div:nth-last-of-type(1) .group{
  width: 100%;
}
}
