* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: SFProDisplay-Thin;
  src: url("../../assets/fonts/sf_prodisplay_thin.ttf");
}

@font-face {
  font-family: SFProDisplay-Light;
  src: url("../../assets/fonts/sf_prodisplay_light.ttf");
}
.main__about {
  background-color: #f2f2f7;
  /* padding:43px 160px; */
  color: #06016c;
  padding: 43px 0px;
}
.main__sections {
  background-color: #f2f2f7;
  width: 83.33%;
  margin: 0px auto;
}
.illustration {
  width: 100%;
  height: 48.75rem;
  background-color: #ffffff;
  color: #2699fb;
  font-size: 77px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Thin;
  font-weight: bold;
}
nav {
  padding-top: 25.3px;
  margin-bottom: 25.3px;
}

.main__section1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 109px;
}
.section__about {
  width: 53.56%;
}
.section__about--icons {
  display: flex;
  justify-content: space-between;
}
.section__about--icons .section__icons svg{
  width:100%;
  height: 100%;
}
.section__icons {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: center;
}
.section__icons p {
  font-size: 20px;
  font-family: SF Pro Display Light;
  line-height: 1.27;
  display: flex;
    flex-direction: column;
}
.section__services--icons {
  display: flex;
  width: 54.15%;
  justify-content: space-between;
}

.iconStyle {
  /* width: 3.438rem; */
  width:3rem;
  height: 3rem;
  margin-right: 1.688rem;
}
.section__services--icons .iconStyle {
  margin-right: 15px;
}
.section__video--temp {
  background-color: transparent;
  width: 43.75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Thin;
  color: #2699fb;
  font-weight: bold;
  font-size: 77px;
}

.fontStyle__title {
  color: #2699fb;
  font-family: SFProDisplay-Bold;
  font-size: 20px;
}
.fontStyle__h2 {
  font-family: SFProDisplay-Bold;
  font-size: 45px;
  color: #06016c;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.fontStyle__paragraph {
  font-family: SFProDisplay-Light;
  font-size: 24px;
  font-weight: 300;
  line-height: 2;
  margin-bottom: 60px;
  margin-top: 50px;
}
.main__section2 {
  text-align: center;
  margin-bottom: 126px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__section2 .fontStyle__h2 {
  margin-bottom: 7px;
}
.main__section3 {
  display: flex;
  padding-bottom: 118.5px;
}
.section__spediteure {
  width: 49.87%;
}
.section__button {
  width: 50.13%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section__button button {
  border-radius: 25px;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  background-color: #06016c;
  font-family: SFProDisplay-Bold;
  color: #fff;
  font-size: 39px;
  width: 515px;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border: none;
  cursor: pointer;
}
.about__footer {
  border-top: 2px solid rgba(6, 1, 108, 0.1);
  padding-top: 37.5px;
}
.about__footer .footer__kucice--single h3,
.about__footer .footer__kucice--single p {
  color: #06016c;
}
.about__footer .footer__kucice--single p {
  font-family: SF Pro Display Regular;
}
.about__footer .fbIcon,
.about__footer .InstaIcon {
  color: #06016c;
}
@media only screen and (max-width: 1785px) {
  .main__section1 .section__about .fontStyle__paragraph {
    margin-bottom: 30px;
  }
  .active {
    width: 40%;
  }
  .section__services--icons {
    width: 55.15%;
  }
}

@media only screen and (max-width: 1660px) {
  .active {
    width: 43%;
  }
  .section__services--icons {
    width: 61.15%;
  }
}
@media only screen and (max-width: 1560px) {
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 23px;
  }
  .active {
    width: 46%;
  }
  .section__spediteure {
    width: 53.87%;
  }
  .section__services--icons {
    width: 64.15%;
  }
}

@media only screen and (max-width: 1523px) {
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 22px;
    margin-bottom: 19px;
  }
  .section__spediteure {
    width: 55.87%;
  }
  .section__services--icons {
    width: 66.15%;
  }
}

@media only screen and (max-width: 1475px) {
  .iconStyle--wallet {
    width: 81px;
  }
  .active {
    width: 48%;
  }
}
@media only screen and (max-width: 1415px) {
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 21px;
  }
  .iconStyle--wallet {
    width: 76px;
  }
  .active {
    width: 50%;
  }
  .section__button button {
    font-size: 36px;
    width: 486px;
    height: 109px;
  }
  .section__spediteure {
    width: 57.87%;
  }
  .section__services--icons {
    width: 71.15%;
  }
}

@media only screen and (max-width: 1352px) {
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 20px;
    margin-top: 32px;
  }
  .active {
    width: 53%;
  }
  .section__services--icons {
    width: 74.15%;
  }
}
@media only screen and (max-width: 1307px) {
  .main__sections {
    width: 87.33%;
  }
}
@media only screen and (max-width: 1247px) {
  .main__sections {
    width: 89.33%;
  }
}
@media only screen and (max-width:1200px){
  .slider__buttons {
    width: 82%;
}
.showServiceCards {
  padding-top: 5rem;
}
}
@media only screen and (max-width: 1024px) {
  .section__services--icons{
    width: 100%;
    margin-top:10rem;
  }
  .slider__buttons{
    width:100%;
  }
  .main__sections {
    width: 79.33%;
  }
  .main__section1 {
    flex-direction: column;
  }
  .main__section1 .section__about {
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 25px;
    margin-top: 43px;
    margin-bottom: 35px;
  }
  .main__section1 .section__about .section__about--icons {
    justify-content: space-evenly;
  }
  .main__section1 .section__video--temp {
    width: 100%;
  }
  .active {
    width: 67%;
  }
  .main__section3 {
    flex-direction: column;
    text-align: center;
  }
  .main__section3 .section__spediteure {
    width: 100%;
  }
  .main__section3 .section__button {
    width: 100%;
  }
  .main__sections .about__footer .footer__kucice {
    margin-left: 42px;
  }
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 20px;
  }
  .main__sections .about__footer .footer__social {
    margin-right: 45px;
  }
  .iconStyle {
    width: 4.438rem;
    height: 5rem;
}
}
@media only screen and (max-width:920px){
  .showServiceCards {
    padding-top: 10rem;
}
}
@media only screen and (max-width: 868px) {
  .active {
    width: 83%;
  }
  .active h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width:790px){
  .slider__buttons button {
    font-size: 18px;
  }
}
@media only screen and (max-width: 775px) {
  .main__sections {
    width: 90.33%;
  }
  .active {
    width: 85%;
  }
}
@media only screen and (max-width:765px){
  .showServiceCards{
    padding-top: 12rem;
    padding-bottom: 10rem;
  }
}
@media only screen and (max-width: 725px) {
  .active {
    width: 90%;
  }
}
@media only screen and (max-width: 670px) {
  .active {
    width: 95%;
  }
}
@media only screen and (max-width:635px){
  .section__icons p {
    font-size: 18px;
}
}
@media only screen and (max-width: 600px) {
  .main__sections {
    width: 97.33%;
  }
  .slider .slider__buttons {
    flex-wrap: wrap;
  }
  .slider__buttons button{
    width: 100%;
  }
  .slide__content p, .active .slide__content p {
    font-size: 3rem;
}
  .slider__buttons button:nth-child(2) {
    border: none !important;
  }
}
@media only screen and (max-width: 555px) {
  .main__sections .about__footer .footer__kucice {
    margin-left: 20px;
  }
  .main__sections .about__footer .footer__social {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 525px) {
  .slider__buttons {
    margin-bottom: 62.5px;
  }
  .active {
    width: 99%;
  }
  .section__services--icons, .section__about--icons{
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    width: fit-content;
  }
  .section__icons{
    width: fit-content;
    margin-bottom: 25px;
  }
  .section__icons p {
    font-size: 20px;
}
.iconStyle {
  width: 6.438rem;
  height: 8rem;
}
.showServiceCards {
  padding-bottom: 36rem;
}
}

@media only screen and (max-width: 505px) {
  .section__button button {
    font-size: 34px;
    width: 421px;
  }
}
@media only screen and (max-width: 485px) {
  .active {
    width: 95%;
  }
  .active .slide__content p {
    font-size: 15px;
  }
  .slider {
    width: 100%;
  }
  .illustration {
    font-size: 50px;
  }
  .main__sections .fontStyle__h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 455px) {
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 19px;
  }
  .main__section3 .section__spediteure .fontStyle__paragraph {
    font-size: 19px;
  }
  .section__button button {
    font-size: 30px;
    width: 397px;
  }
  .main__sections .fontStyle__h2 {
    font-size: 37px;
  }
  .main__sections .fontStyle__title {
    font-size: 18px;
  }
  .main__sections .about__footer .footer__social {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 426px) {
  .main__sections .about__footer .footer__kucice {
    margin-left: 7px;
  }
  .footer__kucice--single p {
    font-size: 13px;
  }

  .section__services--icons .section__icons p {
    font-size: 19px;
  }
  .main__section1 {
    margin-bottom: 25px;
}
}
@media only screen and (max-width: 416px) {
  .slider__buttons button:nth-child(2) {
    border-left: none !important;
  }
  .main__section2 {
    margin-bottom: 81px;
  }
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 16px;
  }
  .main__section3 .section__spediteure .fontStyle__paragraph {
    font-size: 16px;
  }
  .section__button button {
    font-size: 28px;
    width: 374px;
    height: 96px;
  }
}
@media only screen and (max-width:414px){
  .main__sections section .fontStyle__h2 .about__heading {
    font-size: 35px !important;
    width: 100%;
}
}
@media only screen and (max-width: 397px) {
  .main__sections .fontStyle__h2 {
    font-size: 33px;
  }
  .main__sections .fontStyle__title {
    font-size: 16px;
  }
  .main__sections .about__footer .footer__kucice {
    margin-left: 3px;
  }
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 375px) {
  .footer__kucice--single p {
    font-size: 11px;
  }
  .footer__kucice--single h3 {
    font-size: 11px;
  }
  .main__sections .about__footer .footer__kucice {
    margin-left: 16px;
  }
  .section__button button {
    font-size: 27px;
    width: 342px;
    height: 85px;
  }
  .main__section3 .section__spediteure .fontStyle__paragraph {
    font-size: 14px;
  }
  .main__section1 .section__about .fontStyle__paragraph {
    font-size: 14px;
  }
  .section__services--icons .section__icons,
  .section__services--icons .section__icons:nth-child(1) {
    margin-right: 0px;
  }
  .section__icons p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 354px) {
  .main__section1 .section__about {
    margin-bottom: 15px;
  }
  .main__sections .about__footer .footer__kucice {
    margin-left: 8px;
  }
  .main__sections .fontStyle__h2 {
    font-size: 31px;
  }
  .main__sections .fontStyle__title {
    font-size: 15px;
  }
  .section__services--icons .section__icons p {
    font-size: 17px;
  }
}
@media only screen and (max-width:340px){
.section__button button {
  width: 290px !important;
}
}
@media only screen and (max-width: 330px) {
  .main__sections .about__footer .footer__kucice {
    margin-left: 0px;
  }
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 12px;
  }
  .section__button button {
    font-size: 26px;
    width: 315px;
    height: 80px;
  }
  .active {
    padding: 46px 19px 20px 19px;
  }
  .main__sections .fontStyle__h2 {
    font-size: 29px;
  }
  .main__sections .fontStyle__title {
    font-size: 14px;
  }
  .slider {
    margin-bottom: 140px;
  }
  .main__section2 {
    margin-bottom: 55px;
  }
}
@media only screen and (max-width: 320px) {
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 9px;
  }
  .section__button button {
    font-size: 24px;
    width: 298px;
    height: 73px;
  }
}

.illustration {
  background: url("https://i.imgur.com/8Lnz5rJ.jpg"), no-repeat !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.sliderContent .cls-1 {
  fill: #2699fb;
}
