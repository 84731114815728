.pendingPaymentsCard {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.pendingPaymentsCard__name {
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
}
.pendingPaymentsCard__name--check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
.pendingPaymentsCard__content {
  width: 16rem;
  height: 19.938rem;
  border-radius: 1.25rem;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(6, 1, 108, 0.2);
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.pendingPaymentsCard__button {
  width: 100%;
  height: 2.6rem;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem;
  line-height: 1.36;
  letter-spacing: 0.088rem;
  color: #fff;
  background-color: #06016c;
  border: none;
  cursor: pointer;
}

.pendingPaymentsCard__top {
  width: 100%;
  height: 9.688rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.pendingPaymentsCard__bottom {
  width: 100%;
}
.pendingPaymentsCard__price {
  width: 100%;
  height: 1.875rem;
  background-color: #37e677;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Bold;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #fff;
}
.pendingPaymentsCard__bottomMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pendingPaymentsCard__carType {
  font-size: 1.125rem;
  line-height: 1.33;
  color: #2699fb;
  margin-top: 1rem;
  margin-bottom: 1.063rem;
  font-family: SF Pro Display Regular;
}
.pendingPaymentsCard__fromTo {
  margin-bottom: 1.688rem;
}
