.changePassword {
  width: 57rem;
}
.changePassword__content {
  width: 25rem;
  margin-top: 1rem;
  text-align: -webkit-right;
}
.changePassword__content .group {
  margin-bottom: 3.063rem;
  width: 100%;
}
.changePassword__content .group .form-input {
  width: 100%;
  /*border: none !important;*/
}
.changePassword__content .blueButton {
  margin: 0rem;
}
