.documentation{
    padding-top: 5.25rem;
}
.documentation_radioButton{
    display: flex;
    align-items: center;
    margin-bottom: 2.25rem;
    font-size: 1.25rem;
}
.documentation_radioButton input{
    margin-right: 1.2rem;
}

.documentation_radioButton label{
    font-family: SF Pro DisplayMedium;
}
.documentation .form__DocumentUpload,.documentation .dropzoneFile {
    width: 100%;
    min-height: 11.5rem;
    padding: 0rem 1rem;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width:500px){
    .documentation_radioButton {
        margin-bottom: 6.25rem;
        font-size: 4rem;
    }
    .documentation .form__DocumentUpload, .documentation .dropzoneFile {
        min-height: 46.5rem;
        padding: 0rem 4rem;
    }
    .dropzoneFile {
        background: none;
    }
    .fileUpload .uploadPlaceholder {   
        text-align: center;
    }
}