.chatContainer {
  position: absolute;
  bottom: 8px;
  left: 315px;
  box-sizing: border-box;
  overflow: auto;
  width: calc(100% - 300px - 50px);
  display: flex;
  align-items: center;
}

.inputSendMessage {
  width: calc(100% - 50px);
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  background: #f1f1f4;
  resize: none;
  line-height: 24px;
  overflow: auto;
  height: auto;
  font-size: 14px;
  outline: none;
  padding: 0 20px;
  border-radius: 30px;
  font-family: SF Pro Display Regular;
  color: #333 !important;
}

.inputSendMessage::placeholder {
  color: #333;
}

.chatContainer > div {
  background: #2699fb;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
}
