.paymentsShipper {
  background-color: white;
  width: 100%;
  min-height: 100vh;
}
.paymentsShipper__main {
  display: flex;
  height: calc(100vh - 7.5rem);
}

.paymentsShipper__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 6.063rem;
  padding-left: 6.438rem;
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
}

.paymentsShipper__section--heading p {
  margin-top: 1.125rem;
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.33;
  color: #06016c;
  width: 56.25rem;
}
.paymentsShipper__pandingPayments {
  overflow-x: hidden;
}
.paymentsShipper__pandingPayments .slick-list {
  padding-top: 53px;
  padding-bottom: 89px;
  padding-left: 40px;
}

.paymentsShipper__pandingPayments .slick-list {
  padding-top: 40px;
  padding-bottom: 79px;
  padding-left: 40px;
}
.paymentsShipper__pandingPayments .slick-slider {
  width: 94.6875rem;
}
.billingHistory__section--heading{
  padding-bottom: 4rem;
}
.billingHistory__section--heading p {
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.25rem;
  color: #06016c;
  margin-bottom: 2.688rem;
  margin-top: 1.125rem;
}

.billingTable {
  width: 86%;
}
.billingTableMobile {
  width: 86%;
  margin: 0px auto;
  display: none;
}
.billingTable table,
.billingTableMobile table {
  width: 100%;
  border-collapse: separate;
  /* border-spacing: 0 30px; */
  font-family: SF Pro Display Regular;
}
.billingTable table th,
.billingTableMobile table th {
  color: #2699fb;
  font-size: 1.25rem;
  text-align: left;
  line-height: 1.35;
  font-family: SF Pro Display Regular;
  font-weight: 100;
  width: fit-content;
}
.makePaymentAnimated {
  position: absolute;
  z-index: 99999;
  right: 0rem;
  bottom: 0rem;
  height: calc(100vh - 7.5rem);
  background-color: white;
}
