
    #outlook a {
        padding: 0;
      }
  
      /* Force Outlook to provide a "view in browser" message */
  
      .ReadMsgBody {
        width: 100%;
      }
  
      .ExternalClass {
        width: 100%;
      }
  
      /* Force HM to display emails at full width */
  
      .ExternalClass,
      .ExternalClass p,
      .ExternalClass span,
      .ExternalClass font,
      .ExternalClass td,
      .ExternalClass div {
        line-height: 100%;
      }
  
      /* Force HM to display normal line spacing */
  
      .outerWrapper body,
      .outerWrapper table,
      .outerWrapper td,
      .outerWrapper p,
      .outerWrapper a,
      .outerWrapper li,
      .outerWrapper blockquote {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
  
      /* Prevent WebKit and Windows mobile changing default text sizes */
  
      .outerWrapper table,
      .outerWrapper td {
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
  
      /* Remove spacing between tables in Outlook 2007 and up */
  
      .outerWrapper img {
        -ms-interpolation-mode: bicubic;
      }
  
      /* Allow smoother rendering of resized image in Internet Explorer */
  
      /* RESET STYLES */
  
      .body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
         -ms-text-size-adjust: 100%; 
         margin: 0;
          padding: 0;
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
        font-size: 16px; 
        height: 100vh;
         width: 100%; 
         min-width: 100%;
      }
  
      .outerWrapper img {
        border: 0 none;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
      }
  
      .outerWrapper a img {
        border: 0 none;
      }
  
      .imageFix {
        display: block;
      }
  
      .outerWrapper table,
      .outerWrapper td {
        border-collapse: collapse;
      }
  
      #bodyTable {
        height: 100% !important;
        margin: 0;
        padding: 0;
        width: 100% !important;
      }
  
      #footer a {
        color: #00a4bd;
        text-decoration: none;
      }
  
      .span{
        -webkit-text-size-adjust:100%;
         -ms-text-size-adjust:100%;
          color: transparent; 
          background: none;
           user-select: none;
            -moz-user-select: none;
             -ms-user-select:none;
              -webkit-user-select:none; 
              text-overflow: ellipsis; 
              opacity: 0; 
              width:100%;
               min-width: 100%; 
               height:1;
                overflow:hidden;
         margin: -1px 0 0 0; 
         padding:0;
          font-size: 0;
      }
      .ul{
        mso-line-height-rule:exactly;
         line-height:175%
      }
      .td-exception{
        background: linear-gradient(120deg, #2699fb 6%, #7426fb 96%);
         width: 100%; 
         padding: 20px;
         min-width:100%;
          color: #ffffff;
      }
      /* Responsive Styles */
  
      @media only screen and (max-width:660px){
        .emailContainer{
          width: 90%!important;
        }
      }
      @media only screen and (max-width: 480px) {
        .responsiveRow {
          width: 100% !important;
        }
        .responsiveColumn {
          display: block !important;
          width: 100% !important;
        }
      }