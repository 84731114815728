.fromToSection {
  display: flex;
}
.fromToSection .fromTo__country {
  text-align: center;
}
.fromToSection div:nth-child(1) .fromTo__country {
  text-align: center;
}
.fromToSection div:nth-child(1) .fromTo__city {
  text-align: center;
}
.fromTo__city {
  font-family: SFProDisplay-Bold;
  font-size: 1.5rem; /* 24px */
  line-height: 1.21;
  color: #06016c;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 5.75rem;
  white-space: nowrap;
  text-align: center;
}
.fromTo__country {
  font-family: SF Pro Display Regular;
  font-size: 0.75rem; /* 12px */
  line-height: 1.17;
  color: #7977a4;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 6rem;
  white-space: nowrap;
}
.fromToSection__arrow {
  margin-left: 0.9437rem; /* 15.1px */
  margin-right: 0.9125rem; /* 14.6px */
  width: 1.0625rem; /* 17px */
  margin-top: 0.375rem; /* 6px */
}
