.notifications {
  margin-bottom: 1.563rem;
  display: flex;
}
.notifications:nth-last-child(1) {
  margin-bottom: 0rem;
}
.notification__userPicture {
  width: 3.313rem;
  height: 3.313rem;
  border-radius: 99999999px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.notification__content {
  padding-bottom: 0.781rem;
  font-family: SF Pro Display Light;
  border-bottom: 1px solid rgba(119, 1, 252, 0.1);
  margin-left: 1.188rem;
  overflow: hidden;
}
.notification__contentMessage {
  font-size: 1rem;
  line-height: 1.35;
  color: #06016c;
  white-space: nowrap;
  position: relative;
  width:11.719rem;
  /* height: 1.5rem; */
  overflow: hidden;
}
.notification__contentMessage--paragr {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 12rem;
  white-space: nowrap;
  /* animation: 5s linear 2s infinite alternate animate; */
}
.notification__contentTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 11.5rem;
  white-space: nowrap;
  font-size: 1.25rem;
  line-height: 1.35;
  color: #2699fb;
  margin-bottom: 0.1rem;
  font-family: SF Pro DisplayMedium;
}
.notification__contentHour {
  font-size: 0.8rem;
  line-height: 1.36;
  color: #06016c;
}
@keyframes animate {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.notification__userPicture svg{
  width: 100%!important;
  height: 100%!important;
}