.group {
  position: relative;
  z-index: 999;
  background-color: white;
  border-radius: 1.563rem;
  width: fit-content;
  height: fit-content;
}
.form-input {
  width: 12.188rem;
  height: 3.125rem;
  border-radius: 1.563rem;
  border: solid 2px #7977a4;
  background-color: transparent;
  padding-left: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
.form-input:focus {
  border: 2px solid #2699fb !important;
}
.form-input--border {
  border: solid 2px rgba(121, 119, 164, 0.5);
}
.form-label {
  font-family: SF Pro Display Regular;
  color: #06016c;
  position: absolute;
  top: 1.12rem;
  font-size: 1.125rem;
  left: 1rem;
  display: block;
  z-index: -1;
  line-height: 0.89;
  transition: all 0.15s ease-in-out 0s;
}
.form-label span {
  font-size: 0.75rem;
}
.form-input:focus ~ .form-label,
.form-input:valid ~ .form-label {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
}

.form-label-exep {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
  font-family: SF Pro Display Regular;
  position: absolute;
  display: block;
  z-index: -1;
}
