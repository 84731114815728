.chatview-content {
  height: calc(100vh - 13.5rem);
  overflow: auto;
  padding: 25px;
  margin-left: 300px;
  box-sizing: border-box;
  overflow-y: scroll;
  top: 7.5rem;
  width: calc(100% - 300px);
  position: absolute;
  padding-bottom: 40px;
  background: white;
}

/* body {
    background: white !important;
} */

.chatview-header {
  width: calc(100% - 301px);
  height: 50px;
  background-color: #fff;
  position: fixed;
  margin-left: 301px;
  font-size: 18px;
  text-align: center;
  color: #2b2c33;
  padding-top: 10px;
  box-sizing: border-box;
  padding: 7px 10px 7px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SF Pro DisplayMedium;
  z-index: 9999999;
}

.userSent {
  float: right;
  clear: both;
  padding: 12px 15px;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-top: 10px;
  background-color: #2699fb;
  color: #fff;
  min-width: 50px;
  max-width: 450px;
  border-radius: 30px;
  margin-bottom: -9px;
  text-align: center;
  font-family: SF Pro Display Regular;
}

.friendSent {
  float: left;
  clear: both;
  padding: 12px 15px;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-top: 10px;
  background: #f1f1f4;
  color: #2b2c33;
  min-width: 50px;
  max-width: 450px;
  border-radius: 30px;
  text-align: center;
  font-family: SF Pro Display Regular;
}

.chatTimestamp {
  position: absolute;
  right: 0;
  bottom: -17px;
  color: #8a8d91;
  font-size: 11px;
  width: 100px;
  font-family: SF Pro Display Regular;
}

.friendSent .chatTimestamp {
  left: 0px;
}
