.addTransportInformation {
  padding: 2.25rem 7.375rem 0rem 6.75rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.addTransportInformation__content{
  overflow-y: auto;
  overflow-x: hidden;
    height: 78vh;
}
.addTransportInformation__content--heading {
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 0.313rem;
}
.addTransportInformation__content--paragraph {
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 2.125rem;
}
.addTransportInformation__content .group,
.addTransportInformation__content .form-input {
  width: 100%;
}
.addTransportInformation__input {
  height: 3.125rem;
  border: solid 2px #7977a4;
  background-color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  border-radius: 1.563rem;
  padding-left: 1.25rem;
  margin-bottom: 2.125rem;
}
.addTransportInformation__input:focus {
  border: solid 2px #2699fb !important;
}

.addTransportInformation__content .form-textareaInput {
  height: 8.438rem;
}
.addTransportInformation__content .group-textarea {
  margin-top: 4.25rem;
  margin-bottom: 1.9375rem;
}
.addTransportInformation__input--paragraph {
  font-size: 1.25rem;
  line-height: 1.35;
  text-align: center;
  color: rgba(6, 1, 108, 0.35);
  margin: 2.625rem 0rem 1.25rem 0rem;
}
.addTransportInformation__groupInput {
  margin: 2.125rem 0rem;
}
.addTransportInformation__groupInput2 {
  margin-top: 2.125rem;
}
.addTransportInformation__buttons{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.addTransportInformation__buttons input {
  width: 9.5rem;
  padding: 0rem;
  margin-bottom: 1rem;
  cursor: pointer;
  text-transform: capitalize;
}
.addTransportInformation__selectedButton {
  background-color: #7977a4;
  color: white;
  opacity: 0.9;
}
.addTransportInformation__buttons input:focus {
  border: none !important;
}
.addTransportInformation__content .blueButton {
  margin: 1.938rem auto 0rem auto;
}
.addTransportInformation .Toastify__toast-container--top-right {
  top: 8em;
}
@media only screen and (max-height: 1066px) {
  /* .addTransportInformation__content {
    overflow-y: scroll;
    height: 80.5vh;
    overflow-x:hidden;
  } */
}
@media only screen and (max-width:500px){
  .borderStyle, .DateInput_input {
    background-size: 5rem;
}
.addTransportInformation__content .group-textarea {
  margin-top: 8.25rem;
}
}