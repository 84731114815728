.scheduler-view,
#RBS-Scheduler-root {
  width: calc(100vw - 47rem) !important;
}
.scheduler {
  margin-left: 0px;
  width: calc(100vw - 47rem);
}
.resource-view {
  display: none;
}
.ant-radio-group {
  display: none;
}
table.resource-table th,
table.scheduler-table th,
table.resource-table td,
table.scheduler-bg-table td,
table.scheduler-table td {
  background-color: transparent !important;
}
table.scheduler-table tr {
  height: 26px !important;
}
#RBS-Scheduler-root th {
  background-color: transparent !important;
  color: #06016c !important;
  font-family: SFProDisplay-Bold !important;
  width: 5rem !important;
}
table.scheduler-bg-table tr,
table.scheduler-table tr,
table.scheduler-bg-table td {
  border-bottom: none !important;
  border-right: 2px solid rgba(6, 1, 108, 0.05);
  width: 5rem !important;
}
.scheduler-view > div {
  border-bottom: none !important;
}
table.scheduler-bg-table th {
  border-right: none !important;
}
.scheduler-view {
  border: none !important;
}
.header2-text {
  font-family: SF Pro Display Regular;
  font-size: 1.3rem;
  line-height: 1.33;
  color: #7977a4;
}
.event-item {
  /* background: linear-gradient(to right, rgba(38, 153, 251, 0.2), rgba(116, 38, 251, 0.2))!important; */
  background-color: #2699fb !important;
  height: 1.575rem !important;
}
.event-item > span {
  font-family: SF Pro DisplayMedium !important;
  font-size: 0.8rem;
  line-height: 1.575rem !important;
}
.header3-text {
  font-size: 0.75rem;
}
#RBS-Scheduler-root .anticon {
  vertical-align: -0.25em !important;
}
.ant-popover {
  z-index: 999999;
}
