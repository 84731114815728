.fontStyle__impressum {
  font-family: SFProDisplay-Bold;
}
.impressum__secondDiv {
  display: block;
  width: 800px;
}
@media only screen and (max-width: 886px) {
  .main__about {
    width: 100%;
  }
  .main__sections {
    width: 81.33%;
  }
  .impressum__secondDiv {
    width: 602px;
  }
}
@media only screen and (max-width: 675px) {
  .main__sections {
    width: 88.33%;
  }
  .impressum__secondDiv {
    width: 539px;
  }
  .main__sections section .fontStyle__paragraph {
    font-size: 19px;
  }
}
@media only screen and (max-width: 570px) {
  .main__sections {
    width: 96.33%;
  }
  .impressum__secondDiv {
    width: 424px;
  }
  .main__sections section .fontStyle__paragraph {
    font-size: 17px;
  }
}
@media only screen and (max-width: 495px) {
  .main__sections section .fontStyle__title {
    font-size: 17px;
  }
  .main__sections section .fontStyle__h2 {
    font-size: 33px;
  }
}
@media only screen and (max-width: 430px) {
  .main__sections {
    width: 96.33%;
  }
  .impressum__secondDiv {
    width: 424px;
  }
  .main__sections section .fontStyle__paragraph {
    font-size: 17px;
  }
}
@media only screen and (max-width: 428px) {
  .main__sections section .fontStyle__paragraph {
    font-size: 15px;
  }
  .main__sections section .fontStyle__h2 {
    font-size: 30px;
  }
  .main__sections section .fontStyle__title {
    font-size: 15px;
  }
  .impressum__secondDiv {
    width: 290px;
  }
  .main__sections .about__footer .footer__kucice {
    margin-left: 8px;
  }
}
@media only screen and (max-width: 380px) {
  .main__sections section .fontStyle__paragraph {
    font-size: 13px;
    margin-top: 33px;
  }
  .main__sections section .fontStyle__paragraph:nth-child(2) {
    margin-bottom: 33px;
  }
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 9px;
  }
}
@media only screen and (max-width: 328px) {
  .main__sections .about__footer .footer__kucice {
    margin-left: 0px;
  }
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 6px;
  }
  .impressum__secondDiv {
    width: 266px;
  }
}
