.shipper__acceptedBid {
  width: 100%;
  height: 25rem;
  background-color: rgba(55, 230, 119, 0.1);
  border-radius: 1.25rem;
  margin-bottom: 1.25rem;
}
.shipper__acceptedBid--heading {
  width: 100%;
  height: 3.75rem;
  background-color: #37e677;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.419rem;
  padding-right: 3.394rem;
  font-family: SFProDisplay-Bold;
  line-height: 1.33;
  color: #fff;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.shipper__header-left {
  font-size: 1.875rem;
  letter-spacing: 3px;
}
.shipper__header-right {
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.shipper__acceptedBid--content {
  width: 100%;
  height: calc(100% - 3.75rem);
  padding: 1.625rem 3.375rem 2.531rem 2.438rem;
  display: flex;
  justify-content: space-between;
}
.acceptedBid__contentRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 71%;
}
.acceptedBid__contentLeft {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  cursor: pointer;
}
.acceptedBid__contentRightMessage {
  width: 100%;
  height: 11.25rem;
  background-color: #fff;
  padding: 1.125rem 1.375rem 1.25rem 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  border-radius: 1.563rem;
  margin-bottom: 0.875rem;
  cursor: pointer;
}
.acceptedBid__contentRightMessage > div {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  height: 8.5rem;
}
.acceptedBid__dates {
  display: flex;
  font-family: SF Pro Display Regular;
  font-size: 1rem;
  line-height: 1.31;
  color: #7977a4;
  margin-bottom: 0.969rem;
}
.acceptedBid__dates label {
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
.acceptedBid__pickupDate {
  margin-right: 2.713rem;
}
.acceptedBid__button {
  width: 12.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  box-shadow: 0 5px 15px 0 rgba(6, 1, 108, 0.15);
  background-color: #37e677;
  border: none;
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  color: #fff;
}
.acceptedBid__contentLeft .CircularProgressbar {
  width: 9.375rem !important;
  height: 9.375rem !important;
}
.circularProgressbar__value {
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem;
  color: #06016c;
  position: absolute;
  top: 0.5rem;
  right: -1.875rem;
}
.circularProgressbar__value span {
  font-size: 1.375rem;
  line-height: 0.86;
  color: #2699fb;
}
.acceptedBid__check {
  width: 1.269rem;
  height: 1.313rem;
  margin-right: 0.831rem;
}

.acceptedBid__user {
  margin-top: 1.094rem;
  margin-bottom: 1.906rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
.acceptedBid__contentLeft .chatButton {
  background-color: #2699fb;
}
