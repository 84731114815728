.ReactTags__tagInputField {
  width: 100%;
  height: 3.125rem;
  border-radius: 1.563rem;
  border: solid 2px #7977a4;
  background-color: transparent;
  padding-left: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
.ReactTags__tagInputField:focus {
  border: 2px solid #2699fb !important;
}
.ReactTags__tagInput {
  margin-top: 0.625rem;
}
.tag-wrapper.ReactTags__tag {
  font-size: 1.1rem;
  display: inline-flex;
  align-items: center;
  border-radius: 25px;
  border: 0.125rem solid #7977a4;
  width: fit-content;
  padding: 0.01rem 0.5rem;
  margin-bottom: 0.4rem;
  margin-right: 0.4rem;
  color: #7977a4;
}
.ReactTags__remove {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}
