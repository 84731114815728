.pendingOffers__content {
  width: 15.625rem;
  height: 29.813rem;
  border-radius: 1.25rem;
  margin-bottom: 7.125rem;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.2);
}
.pendingOffers__button {
  width: 100%;
  height: 2.438rem;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem;
  line-height: 1.36;
  letter-spacing: 0.088rem;
  color: #fff;
  background-color: #06016c;
  border: none;
  cursor: pointer;
}
.pendingOffers__content .offerCard,
.pendingOffers__content .offerCard {
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 0rem;
  box-shadow: none;
}

.pendingOffers__name {
  font-family: SF Pro DisplayMedium;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 0.375rem;
  margin-left: 0.875rem;
}
.pendingOffers__name--check {
  margin-right: 0.625rem;
  width: 1.125rem;
  height: 1.125rem;
}
