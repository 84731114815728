.orders__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 6.063rem; /* 63px */
  padding-left: 6.438rem;
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
}
.orders__section--paragraph {
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.33;
  color: #06016c;
  margin: 1.125rem 0rem 1rem 0rem;
  width: 58%;
}

.orders__slider {
  overflow-x: hidden;
}
.orders__slider .slick-list {
  padding-top: 2.25rem;
  padding-bottom: 4.188rem;
  padding-left: 2.625rem;
}
.orders__slider .slick-slider {
  width: 82.3rem;
}
.orders__sliderExpired {
  opacity: 0.5;
}

.results__slider .shipmentsCard {
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
}

.results__slider {
  display: inline-block !important;
}

.filterSidebar .customBackButton {
  position: relative;
  left: unset;
}

.filterSidebar {
  width: 15rem;
  background: white;
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.addTruckSpecial {
  top: 7.4rem;
}

.searchFilterHolder {
  margin: 0 auto;
  width: 100%;
}

.searchFilterHolder .inputHolder input::placeholder {
  font-size: 1rem;
}

.searchFilterHolder .inputHolder {
  width: 100%;
}

.searchFilterHolder .inputHolder input {
  width: 100%;
  border: none;
  border-radius: 25px;
  background: rgba(6, 1, 108, 0.03);
  padding: 10px 10px;
  padding-left: 20px;
  color: #06016c;
  font-size: 1rem;
  font-family: SF Pro Display Regular;
}

.searchFilterHolder .inputHolder span {
  margin-bottom: 7px;
  color: #7977a4;
}

.searchFilterHolder .listPlaces {
  background: white;
  opacity: 1;
  border: 1px solid rgba(6, 1, 108, 0.03);
  border-radius: 10px;
  margin-top: 10px;
}

.resultsIconHolder div {
  width: 33%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.resultsIconHolder div span {
  margin-top: 8px;
  user-select: none;
}

.shipments__notFound {
  font-family: SFProDisplay-Bold;
  color: #9491da;
  font-size: 2.5rem;
  width: fit-content;
  margin: 100px auto;
}
