.mobileDownload{
    background: url('../../assets/images/mobileDownload.png');
    background-color: white;
    border-radius: 1.5rem;
    background-size: cover;
    position: fixed;
    top: 10.375rem;
    right: 1rem;
    z-index: 9999;
    box-shadow: 0 1.25rem 2.5rem 0 rgba(6, 1, 108, 0.2);
    padding: 1rem;
    min-width: 28.75rem;
    min-height: 17rem;
    width: fit-content;
    height: fit-content;
    
}
.download__closeButton{
  position: absolute;
  right: 1rem;
    top: 1rem;
    width: 2.4rem;
    height: 2.4rem;
    color: #06016c;
  cursor: pointer;
}
  .mobileDownload p {
    font-family: SF Pro Display Regular;
    font-size: 1.6rem;
    line-height: 1.33;
    color: #06016c;
    width: 45.25rem;
    margin-top: 1rem;
    width: 28.75rem;
  }
  .mobileDownload__downloadButtons {
    display: flex;
    margin-top: 1.1rem;
  }
  .mobileDownload__downloadButtons svg {
    display: block;
    height: 6.25rem;
    width: 12.725rem;
    cursor: pointer;
  }
  .mobileDownload__downloadButtons svg:nth-last-of-type(1) {
    margin-left: 1.963rem;
    width: 13.625rem;
  }
  .mobileDownload__text h2 {
    font-size: 1.5rem !important;
  }
  .mobileDownload__text .content__header--line {
    width: 19.188rem;
  }
  .mobileDownloadAnimation{
      position: relative;
      z-index: 99999;
  }
  @media only screen and (min-width:1201px){
    .mobileDownload{
      display: none;
    }
  }
  @media only screen and (max-width:500px){
    .mobileDownload{
      top: 14.375rem;
      right: 2rem;
      min-width: 47.75rem;
      min-height: 26rem;
    }
    .mobileDownload .content__header h2 {
        font-size: 3.875rem !important;
    }
  .mobileDownload p {
    width: 47.75rem;
    font-size: 2.6rem;
  }
  .mobileDownload__downloadButtons svg {
    height: 7.25rem;
    width: 19.725rem;
}
.mobileDownload__downloadButtons svg:nth-last-of-type(1) {
    width: 21.625rem;
}
.mobileDownload__downloadButtons {
    margin-top: 3.1rem;
}
  }

