.content__rowData {
  margin-bottom: 0.813rem;
  font-size: 1.2rem;
  line-height: 1.15;
  overflow-wrap: break-word;
}
.rowData__description {
  margin-bottom: 0.5rem;
  font-family: SF Pro Display Regular;
  color: #06016c;
}
.rowData__data {
  font-family: SFProDisplay-Bold;
  color: #00a3fa;
}
