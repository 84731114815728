.paragraph__list,
.paragraph__list--exeption {
  list-style-position: inside;
}
.paragraph__list,
.paragraph__list--nested {
  counter-reset: item;
}
.paragraph__list > li,
.paragraph__list--nested > li {
  list-style-type: none;
  counter-increment: item;
}
.paragraph__list--exeption > li {
  counter-increment: item + 5;
}
.paragraph__list > li,
.paragraph__list dd,
.paragraph__list p {
  margin-bottom: 60px;
}
.paragraph__list > li:nth-last-child(1),
.paragraph__list dd:nth-last-child(1) {
  margin-bottom: 0px;
}
.paragraph__list > li:before {
  font-weight: bold;
  content: counter(item) ". ";
}
.paragraph__list dt {
  font-weight: bold;
}
.paragraph__list--nested > li:before {
  font-weight: bold;
  content: counters(item, ".") " ";
}

.section--exeption {
  margin-bottom: 50px;
}
.main__sections--stand {
  font-size: 28px;
  line-height: 1.21;
  color: #06016c;
  font-family: SFProDisplay-Bold;
  margin-bottom: 80.5px;
}
.paragraph__list dd dl dd div {
  display: flex;
  align-items: center;
}
.paragraph__list dd hr {
  width: 15px;
  height: 5px;
  border-radius: 33px;
  background-color: #2699fb;
  margin-right: 7px;
  border: none;
}

@media only screen and (max-width: 990px) {
  .main__sections {
    width: 87.33%;
  }
  section .fontStyle__h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 777px) {
  .main__sections {
    width: 88.33%;
  }
  section .fontStyle__h2 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 414px) {
  .main__sections .about__footer .footer__kucice .footer__kucice--single {
    margin-right: 7px;
  }
  .paragraph__list > li,
  .paragraph__list dt,
  .paragraph__list dd,
  .main__sections section .fontStyle__paragraph:nth-child(2) {
    font-size: 3.5rem !important;
    width: 90%;
    margin: 0 auto;
  }
  .main__sections section .fontStyle__h2 {
    font-size: 2rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  #Component_7_1 {
    margin-left: 5%;
    margin-top: 10px;
  }
  .agb .fontStyle__title {
    margin-left: 5%;
    margin-bottom: 10px;
  }
  .paragraph__list dt,
  .main__sections section .fontStyle__paragraph:nth-child(2) {
    margin-top: 15px;
  }
  .main__sections--stand {
    margin-left: 5%;
    margin-bottom: 0px;
  }
  .agb .fontStyle__h2 {
    font-size: 32px !important;
}
}
@media only screen and (max-width:392px){
  .agb .fontStyle__h2 {
    font-size: 28px !important;
}
}
@media only screen and (max-width:385px){
  .paragraph__list > li,
  .paragraph__list dt,
  .paragraph__list dd,
  .main__sections section .fontStyle__paragraph:nth-child(2) {
    font-size: 3rem !important;
  }
}
@media only screen and (max-width: 370px) {
  .main__sections section .fontStyle__h2 {
    font-size: 23px !important;
  }
  .main__sections .main__sections--stand {
    font-size: 25px;
  }
}
@media only screen and (max-width: 340px) {
  .main__sections section .fontStyle__paragraph {
    font-size: 12px !important;
  }
}
@media only screen and (max-width:330px){
  .paragraph__list > li,
  .paragraph__list dt,
  .paragraph__list dd,
  .main__sections section .fontStyle__paragraph:nth-child(2) {
    font-size: 2.5rem !important;
  }
}