.ShipmentsMUAImport {
  padding: 6.625rem 8.125rem 1.9375rem 8.125rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.ShipmentsMUAImport__content--heading {
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 5px;
}
.ShipmentsMUAImport__content--paragraph {
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 0.5rem;
}
#inputurl {
  width: 100%;
  height: 3.125rem;
  border-radius: 1.563rem;
  border: solid 2px #7977a4 !important;
  background-color: #ffffff;
  margin-top: 0.313rem;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
#inputurl::placeholder {
  font-size: 1rem;
  line-height: 1.31;
  color: #7977a4;
}
.ShipmentsMUAImport__content--url .group,
.ShipmentsMUAImport__content--url .form-input {
  width: 100%;
}
.ShipmentsMUAImport__content--url {
  margin-top: 2.125rem;
}
.ShipmentsMUAImport__urlPreview--paragraph,
.ShipmentsMUAImport__url--paragraph {
  font-size: 1rem;
  line-height: 1.31;
  color: #7977a4;
}
.ShipmentsMUAImport__urlPreview--paragraph {
  margin-top: 0.5rem;
  margin-bottom: 0.9375rem;
}
.urlPreview {
  margin-bottom: 1rem;
}
.urlPreview__top {
  width: 100%;
  height: 11.875rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  position: relative;
  font-size: 0.688rem;
}
.urlPreview__top--condition {
  width: 6.113rem;
  height: 1.413rem;
  border-radius: 0.3125rem;
  background-color: #24fe83;
  position: absolute;
  right: 0.7rem;
  top: 0.538rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.urlPreview__top--price {
  width: 6.188rem;
  height: 2.063rem;
  border-radius: 0.3125rem;
  background-color: #203643;
  position: absolute;
  left: 0.85rem;
  bottom: 0.719rem;
  padding-left: 0.875rem;
  padding-top: 0.031rem;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.urlPreview__top--price span:nth-child(1) {
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem;
  line-height: 1.36;
  margin-bottom: 0.125rem;
}
.urlPreview__top--price span:nth-child(2) {
  font-size: 0.375rem;
  line-height: 1.33;
}
.urlPreview__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height:7.5rem; */
}
.urlPreview__bottom hr {
  width: 23.588rem;
  border-bottom: none;
  border-left: none;
  border-top: 2px solid rgba(6, 1, 108, 0.1);
}
.urlPreview__bottom--heading {
  font-family: SF Pro Display Light;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.33;
  padding: 0.594rem 0rem 0.513rem 0rem;
  text-align: center;
}
.urlPreview__bottom--heading span {
  font-family: SFProDisplay-Bold;
}
.urlPreview__bottom--desc {
  display: flex;
  justify-content: space-around;
  height: 4.813rem;
  align-items: center;
  width: 100%;
}
.urlPreview__bottom--desc hr {
  width: 1.5px;
  border-bottom: none;
  border-top: 1.425rem solid rgba(6, 1, 108, 0.1);
  display: block;
}
.urlPreview__bottom--desc p {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: SF Pro DisplayMedium;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.36;
  color: rgba(6, 1, 108, 0.25);
}
.urlPreview__bottom--desc p span {
  font-family: SFProDisplay-Bold;
  font-size: 0.875rem;
  line-height: 1.36;
  color: #06016c;
}
.ShipmentsMUAImport__content--OR {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2.1875rem;
  /*temp margin*/
  margin-top: 2.1875rem;
}
.ShipmentsMUAImport__content--OR hr {
  width: 9.5rem;
  border-bottom: none;
  border-left: none;
  border-top: 2px solid rgba(6, 1, 108, 0.1);
}
.ShipmentsMUAImport__content--OR p {
  font-size: 0.875rem;
  line-height: 1.36;
}
.ShipmentsMUAImport__content .blueButton {
  width: 100%;
  margin: 2.1875rem 0rem 0rem 0rem;
}
.ShipmentsMUAImport__content--url .form-label{
  white-space: nowrap;
  width: 88%;
  overflow: hidden;
}