.messengerHeader{
    padding: 0px 3.5rem;
}
.header__leftSideMobile{
  display: flex;
  align-items: center;
}
.header__leftSideMobile a{
    margin-right: 8rem;
}
.header__leftSideMobile svg{
    height: auto;
    fill:#06016C;
    width: 5rem;
}
.header__leftSideMobile p{
    font-family: SFProDisplay-Bold;
    color:#06016C;
    font-size: 4rem;
}