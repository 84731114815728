.offers {
  background-color: white;
  width: 100%;
  height: 100vh;
}
.offers__main {
  display: flex;
  height: calc(100vh - 7.5rem);
}
.offers__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 3.25rem; /* 52px */
  padding-left: 6.125rem; /* 98px */
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
}
.offers__section--heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:23.563rem;
}
.offers__section_numbers {
  border-radius: 9999px;
  padding: 0.75rem 1.18rem;
  font-family: SFProDisplay-Bold;
  font-size: 1.125rem;
  line-height: 1.33;
  border: solid 3px #7426fb;
  color: #7426fb;
}
.section__acceptedOffers .slick-list,
.section__pendingOffers .slick-list {
  padding-top: 53px;
  padding-bottom: 89px;
  padding-left: 40px;
}
.section__acceptedOffers,
.section__pendingOffers {
  overflow-x: hidden;
}
.section__acceptedOffers .slick-list,
.section__pendingOffers .slick-list {
  padding-top: 40px;
  padding-bottom: 79px;
  padding-left: 40px;
}
.section__acceptedOffers .slick-slider,
.section__pendingOffers .slick-slider {
  width: 94.6875rem;
}
.editBidAnimated {
  position: absolute;
  z-index: 99999;
  right: 0rem;
  bottom: 0rem;
  height: calc(100vh - 7.5rem);
  background-color: white;
}
@media only screen and (max-width:600px) and (max-height:400px){
  .offerCard__dates{
    padding-top:0.13rem;
  }
}
@media only screen and (max-width:500px){
  .offers__section .shipmentView__paragraph{
    padding-left: 6.125rem;
  }
}