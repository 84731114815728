.transportSection {
    height: 19.813rem;
    width: 100%;
    background-color: white;
    box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
    border-radius: 1.25rem;
    display: flex;
    margin-bottom:0.875rem;
  }
  .transportSection__left{
      width:29.875rem;
      height: 19.813rem;
      background-position: center;
      background-size: cover;
  background-repeat: no-repeat;
      border-top-left-radius: 1.25rem;
      border-bottom-left-radius: 1.25rem;
  }
  .transportSection__right{
      width: calc(100% - 29.875rem);
        display: flex;
        justify-content: space-around;
        padding-top: 1.313rem;
  }

  .transportSection__right--items{
      width: 28.188rem;
      display: flex;
      flex-direction: column;
      align-items:center;
      position: relative;
  }
  .transportSection__right--items p:nth-last-of-type(1){
    font-family: SF Pro Display Regular;
    font-size: 0.75rem;
    line-height: 1.33;
    color: #7977a4;
    position: absolute;
    bottom: 1.5rem;
  }
  .transportSection__right--items > div label{
    font-family: SF Pro Display Regular;
    font-size: 1rem;
    line-height: 1.31;
    color: #06016c;
    margin-left: 2.875rem;
  }
  .transportSection__right--notes{
    width: 21.875rem;
    display: flex;
    flex-direction: column;
    align-items:center;
}

  .transportSection__right--notes > div{
      width: 19.438rem;
      height: 14.75rem;
      border-radius: 1.25rem;
      background-color: rgba(6, 1, 108, 0.05);
      font-family: SF Pro Display Regular;
      font-size: 1.125rem;
      line-height: 1.33;
      color: #06016c;
      padding:1.25rem;
      cursor: pointer;
  }
  .transportSection__right--heading{
    font-family: SF Pro Display Regular;
    font-size: 1.125rem;
    line-height: 1.33;
    color: #7977a4;
    margin-bottom: 0.813rem;
  }

  .checkbox__group{
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 1.5rem;
      height: 2rem;
  }
  .transportSection input[type=checkbox] {
    cursor: pointer;
    font-size: 1rem;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    /* transform: scale(1.5); */
}

 .transportSection input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #00BFF0;
    width: 1.6rem;
    height: 1.4rem;
    visibility: visible;
    border:  solid 3px #06016c;
    border-radius: 4px;
}

.transportSection input[type=checkbox]:checked:after {
    content: "\2714";
    display: unset;
    font-weight: bold;
    padding:0.2rem 0.4rem;
}
.transportSection__right--notes > div > div{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    height: 11.5rem;
}
.transportSection__right--list{
  width: 100%;
  padding-left: 2rem;
}