.companyReviewsCard {
  /* width: 1231px; */
  width: 100%;
  height: 16.875rem;
  border-radius: 0.625rem;
  box-shadow: 0 20px 40px 0 rgba(6, 44, 77, 0.15);
  background-color: #f4fafa;
  margin-bottom: 4.063rem;
}
.companyReviewsCard__header {
  width: 100%;
  height: 2.5rem;
  background-color: #37e677;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1.25rem 0rem 1.875rem;
  font-family: SF Pro Display Regular;
  font-size: 0.875rem;
  line-height: 1.36;
  color: #ffffff;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}
.companyReviewsCard__header p:nth-child(1) {
  text-transform: uppercase;
  font-family: SFProDisplay-Bold;
  display: flex;
  align-items: center;
}
.companyReviewsCard__header p:nth-last-child(1) span {
  font-family: SFProDisplay-Bold;
}
.companyReviewsCard__content {
  width: 100%;
  height: calc(16.875rem - 2.5rem);
  display: flex;
}
.companyReviewsCard__contentLeft {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 25rem;
  height: 100%;
  border-bottom-left-radius: 0.625rem;
}
.companyReviewsCard__contentRight {
  width: calc(100% - 25rem);
  height: 100%;
}
.companyReviewsCard__contentRight--paragraph {
  width: 100%;
  height: 10.625rem;
  padding: 1.188rem 1.875rem 1.063rem 3.688rem;
  font-family: SF Pro Display Light;
  font-size: 1rem;
  line-height: 1.88;
  color: #06016c;
  width: 100%;
    word-break: break-all;
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}
.companyReviewsCard__contentRight--info {
  width: 100%;
  height: 3.75rem;
  background-color: rgba(116, 38, 251, 0.05);
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #7977a4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.688rem;
  padding-right: 2.063rem;
}
.contentRight__info--left {
  font-family: SF Pro Display Regular;
  font-size: 1.375rem;
  line-height: 1.36;
  color: #2699fb;
}
.contentRight__info--right {
  display: flex;
  font-size: 1.125rem;
}
.contentRight__info--left span {
  font-family: SFProDisplay-Bold;
}
.infoRight__transportPrice,
.infoRight__transportDate {
  font-family: SFProDisplay-Bold;
  line-height: 1.33;
  color: #7426fb;
}
.infoRight__transportPrice span,
.infoRight__transportDate span {
  font-family: SF Pro Display Regular;
  color: #7977a4;
}
.infoRight__transportPrice {
  padding-right: 2.063rem;
  border-right: 1px solid #9ed1fa;
}
.infoRight__transportPrice span {
  margin-right: 0.875rem;
}
.infoRight__transportDate {
  margin-left: 1.188rem;
}
.infoRight__transportDate span {
  margin-right: 0.563rem;
}
.companyReviewsCard__happyIcon {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 0.6rem;
}

@media only screen and (max-width: 1920px) {
  .companyReviewsCard__contentRight--paragraph {
    font-size: 0.9rem;
  }
  .contentRight__info--left {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1848px) {
  .companyReviewsCard__contentRight--paragraph {
    font-size: 0.85rem;
  }
  .contentRight__info--left {
    font-size: 0.89rem;
  }
}
@media only screen and (max-width: 1827px) {
  .contentRight__info--left {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 1848px) {
  .companyReviewsCard__contentRight--paragraph {
    font-size: 0.8rem;
  }
  .contentRight__info--right {
    font-size: 0.8rem;
  }
}
