.searchHeader {
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  background-color: #ffffff;
  width: 100%;
  height: 12.5rem;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999999;
}

@media only screen and (max-width: 500px) {
  .header__rightSide {
    margin-right: 2rem;
    width: 80%;
  }
  .header__rightSide input {
    width: 100%;
    height: 6.125rem;
    border-radius: 4.25rem;
    padding-left: 7.1875rem;
    font-size: 2.5rem;
  }
  .header__rightSide input::placeholder {
    font-size: 2.5rem;
  }
  .searchBox {
    background-position-x: 1.9563rem !important;
    background-size: 3.8rem !important;
  }
  .searchHeader svg {
    width: 5.277rem;
    height: 5.344rem;
    cursor: pointer;
  }
}
