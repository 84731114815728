.shipmentPosition {
  height: 19.813rem;
  /* width:71.938rem; */
  width: 100%;
  background-color: white;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  border-radius: 1.25rem;
  display: flex;
  margin-bottom: 3.625rem;
}
.shipmentPosition__left {
  width: 21.875rem;
  height: 100%;
  color: #cccccc;
  padding: 2.75rem 3.625rem 0rem 4.125rem;
  display: flex;
}
.shipmentPosition__right {
  width: calc(100% - 21.875rem);
  height: 100%;
  position: relative;
}
.shipmentPosition__right > div > div {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}
.gmnoprint {
  display: none !important;
}
.shipmentPosition__to {
  color: #7701fc;
}
.shipmentPosition__from {
  margin-bottom: 3.625rem;
}
.positionTo__city,
.positionFrom__city {
  font-family: SFProDisplay-Bold;
  font-size: 1.25rem;
  line-height: 1.35;
}
.positionTo__country,
.positionFrom__country {
  font-family: SF Pro Display Regular;
  font-size: 0.75rem;
  line-height: 1.33;
}
.positionTo__date,
.positionFrom__date {
  font-family: SFProDisplay-Bold;
  white-space: nowrap;
}
.shipmentPosition__inprogress {
  font-family: SF Pro Display Regular;
  font-size: 0.75rem;
  line-height: 1.33;
  margin-bottom: 3.75rem;
}
.shipmentPosition__leftLine {
  position: relative;
  margin-right: 2.281rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.currentPosition {
  color: #2699fb;
}
.start {
  top: 0.9rem;
}
.inProgress {
  top: 6.25rem;
}
.destination {
  top: 12.25rem;
}

.locationColor {
  color: #2699fb;
}
