.slider__buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.sliderContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin: 0rem /* 0px */ auto;
}
.sliderContent__slide {
  height: 32.5rem; /* 520px */
  width: 27%;
  background-color: #fff;
  border-radius: 1.25rem; /* 20px */
  box-shadow: 0 1.25rem 2.5rem 0 /* 20px 40px */ rgba(6, 1, 108, 0.1);
  padding: 2.125rem 1.875rem 1.875rem 1.875rem; /* 34px 30px 30px 30px */
  overflow: hidden;
  opacity: 0.15;
  position: static;
}
.sliderContent__slide h3 {
  font-family: SFProDisplay-Bold;
  font-size: 1.4375rem; /* 23px */
  color: #2699fb;
  line-height: 1.19;
  margin-bottom: 1.5rem; /* 24px */
}

.sliderContent__slide:nth-child(1) h3 {
  text-align: left;
}
.sliderContent__slide:nth-last-child(1) h3 {
  text-align: right;
}
.slide__content p {
  font-family: SF Pro Display Light;
  font-size:1.125rem;
  line-height: 2.22;
  text-align: left;
  padding-bottom: 2.2919rem; /* 36.67px */
}
.active {
  height: fit-content;
  width: 37.25%;
  background-color: #fff;
  border-radius: 1.25rem; /* 20px */
  box-shadow: 0 1.25rem 2.5rem 0 /* 20px 40px */ rgba(6, 1, 108, 0.1);
  opacity: 1;
  padding: 2.875rem 2.5rem 1.25rem 2.625rem; /* 46px 40px 20px 42px */
  position: absolute;
  z-index: 999;
}
.active .slide__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
}
.active h3 {
  font-family: SFProDisplay-Bold;
  font-size: 25px;
  margin-bottom: 1.9375rem; /* 31px */
  color: #2699fb;
  line-height: 1.19;
}
.active .slide__content p {
  line-height: 1.89;
  font-family: SF Pro Display Light;
  font-size: 18px;
  text-align: left;
}
.active .slide__content p:nth-last-child(1) {
  padding-bottom: 0.625rem; /* 10px */
}

.showServiceCards {
  visibility: visible;
  padding-bottom: 11.9375rem;
  padding-top: 3rem;
}
.hideServiceCards {
  visibility: hidden;
  height: 0px;
}
