.billingTableRow {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #203643;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
}
.billingTableRow td {
  border-bottom: 0.063rem solid rgba(6, 1, 108, 0.1);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.billingTableRow td:nth-of-type(1) {
  font-size: 1.25rem;
  line-height: 1.35;
  color: #2699fb;
  padding-right: 2rem;
}
.billingTableRow td:nth-of-type(6) > span {
  display: block;
  font-family: SF Pro Display SemiBold;
  width: 7.375rem;
  height: 1.75rem;
  border-radius: 0.313rem;
  /* background-color: #37e677; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.7rem;
  letter-spacing: 0.063rem;
  cursor: pointer;
  text-transform: uppercase;
}
.billingTableRowMobile td:nth-of-type(4) > span {
  font-family: SF Pro Display SemiBold;
  width: 17.375rem;
  height: 5.5rem;
  border-radius: 0.313rem;
  background-color: #37e677;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2.3rem;
  letter-spacing: 0.063rem;
  cursor: pointer;
  text-transform: uppercase;
}
.billingTableRow td:nth-of-type(7) a {
  text-decoration: none;
  font-family: SF Pro Display Regular;
  font-size: 1.25rem;
  line-height: 1.35;
  color: #7426fb;
  cursor: pointer;
}
.billingTableRow td:nth-last-of-type(1) {
  text-align: center;
  width: 150px;
}
.billingTableRowMobile {
  font-size: 3.25rem;
}
.billingTableRowMobile td {
  padding: 1.25rem 3rem;
}
.billingTableRowMobile svg {
  width: 5.719rem;
  height: 4rem;
}
/*
.dashboard__billing table tbody tr td:nth-of-type(6) button {
    box-shadow: none;
    border: none;
    background: white;
    font-family: SF Pro Display SemiBold;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #2699fb;
    outline: none;
    color: #2699fb;
    cursor: pointer;
    margin-top: 0px;
}
.dashboard__billing table tbody tr td:nth-of-type(6) button:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .1);
}
.dashboard__billing {
    overflow-y: scroll;
    height: 600px;
}
.dashboard__billing::-webkit-scrollbar {
    width: 8px;
    border-radius: 14px;
    background-color: rgba(0, 138, 255, 0.1);
}
.dashboard__billing::-webkit-scrollbar-thumb {
  border-radius: 14px;
  background-image: linear-gradient(126deg, #2699fb -50%, #00def8 112%);
} */

@media only screen and (max-width: 467px) {
  .billingTableRowMobile {
    font-size: 2.8rem;
  }
  .billingTableRow td:nth-of-type(1) {
    font-size: 2.7rem !important;
  }
  .billingTableRowMobile td:nth-of-type(4) > span {
    width: 16.375rem;
    height: 5rem;
  }
}
@media only screen and (max-width: 435px) {
  .billingTableRowMobile {
    font-size: 2.5rem;
  }
  .billingTableRowMobile td:nth-of-type(4) > span {
    width: 11.3rem;
    height: 4.7rem;
  }
  .billingTableRowMobile svg {
    height: 3.4rem;
  }
}
@media only screen and (max-width: 419px) {
  .billingTableRowMobile td {
    padding: 1.25rem 2.5rem;
  }
}
@media only screen and (max-width: 419px) {
  .billingTableRowMobile td {
    padding: 1.25rem 2.3rem;
  }
  .billingTableRow td:nth-of-type(1) {
    padding-right: 3rem !important;
  }
}
@media only screen and (max-width: 380px) {
  .billingTableRowMobile td {
    padding: 1.25rem 2.05rem;
  }
  .billingTableRow td:nth-of-type(1) {
    padding-right: 2.3rem !important;
  }
}
@media only screen and (max-width: 370px) {
  .billingTableRowMobile {
    font-size: 2.3rem;
  }
  .billingTableRow td:nth-of-type(1) {
    font-size: 2.5rem !important;
  }
}
@media only screen and (max-width: 355px) {
  .billingTableRowMobile svg {
    height: 3.2rem;
  }
  .billingTableRowMobile td {
    padding: 1.25rem 1.8rem;
  }
  .billingTableRowMobile {
    font-size: 2.1rem;
  }
  .billingTableRow td:nth-of-type(1) {
    font-size: 2.4rem !important;
  }
}
@media only screen and (max-width: 335px) {
  .billingTableRowMobile td:nth-of-type(4) > span {
    width: 10.3rem;
    height: 4.5rem;
  }
  .billingTableRowMobile td {
    padding: 1.25rem 1.6rem;
  }
}
