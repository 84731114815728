.offerCard {
  width: 15.625rem;
  height: 27.563rem;
  border-radius: 1.25rem;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.2);
  background-color: #fff;
}
.offerCard__top {
  width: 100%;
  height: 12.063rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}
.offerCard__bottom {
  width: 100%;
}
.offerCard__price {
  width: 15.625rem;
  height: 1.875rem;
  background-color: #37e677;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: SFProDisplay-Bold;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #fff;
}
.offerCard__bottomMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offerCard__carType {
  font-family: SFProDisplay-Bold;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #2699fb;
  margin-top: 1.006rem;
  margin-bottom: 0.619rem;
}
.offerCard__carType span {
  font-family: SF Pro Display Regular;
}
.offerCard__fromTo {
  margin-bottom: 0.5rem;
}
.offerCard__icon svg {
  width: 2.454rem;
  height: 3.035rem;
}
.offerCard__dates {
  background-color: rgba(116, 38, 251, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 0.813rem 2.625rem 1.188rem 2.313rem;
  height: 7.5rem;
  width: 100%;
  align-items: center;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}
.offerCard__Date {
  font-family: SFProDisplay-Bold;
  display: flex;
  flex-direction: column;
}
.offerCard__Date:nth-child(1) {
  margin-bottom: 0.875rem;
}
.offerCard__Date span {
  font-size: 1rem;
  line-height: 1.31;
  color: #2699fb;
}
.offerCard__Date span:nth-child(1) {
  font-size: 0.625rem;
  line-height: 1.4;
  color: #7977a4;
  margin-bottom: 0.125rem;
}
