.fuhrparkCardStyle {
  padding: 3.75rem 0rem 0.9375rem 1.3125rem; /* 60px 0px 15px 21px */
}
.fuhrparkCard__name {
  font-family: SFProDisplay-Bold;
  font-size: 1.5rem; /* 24px */
  line-height: 1.18;
  color: #06016c;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 11.75rem;
  height: 1.2em;
  white-space: nowrap;
}
.fuhrparkCard__year {
  font-family: SF Pro Display Regular;
  font-size: 0.75rem; /* 12px */
  line-height: 1.17;
  color: #7977a4;
  margin-bottom: 0.9375rem; /* 15px */
}
.fuhrparkCard__details {
  background: none;
  border: none;
  font-size: 1rem; /* 16px */
  font-family: SF Pro Display Regular;
  line-height: 1.19;
  color: #2699fb;
  cursor: pointer;
}
