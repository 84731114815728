.chatButton__chatIcon {
  width: 1.269rem;
  height: 1.313rem;
  margin-left: 0.831rem;
}
.chatButton {
  width: 15rem;
  height: 2.188rem;
  border-radius: 0.688rem;
  background-color: #7426fb;
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  text-align: center;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
