.mobileNavbar {
  width: 100%;
  height: 12.5rem;
  background-color: #ffffff;
  font-family: SF Pro Display Regular;
  position: relative;
  box-shadow: 1.25rem 0 2.5rem 0 /* 20px 40px */ rgba(6, 1, 108, 0.1);
  display: none;
  z-index: 99999;
  position: fixed;
  bottom: 0px;
}
.mobileNavbar .bottomSide__navmenu {
  display: flex;
  margin: 0px;
  justify-content: space-evenly;
}
.mobileNavbar .bottomSide__navmenu li a {
  width: fit-content;
  flex-direction: column;
}
.mobileNavbar .bottomSide__navmenu li a .listIcon {
  margin: 0px;
  width: fit-content;
  height: 4.5rem;
  margin-bottom: 1.3rem;
}
.mobileNavbar .bottomSide__navmenu li {
  font-size: 2.375rem;
}
.mobileNavbar__active {
  color: #2699fb !important;
}

.mobileNavbar .bottomSide__navmenu li a:hover {
  background-color: transparent;
}
