.DateRangePickerInput_arrow {
  display: none;
}
.DateInput {
  width: fit-content !important;
  position: relative;
  background: transparent !important;
}
.DateInput_input {
  width: 12.188rem !important;
  height: 3.125rem !important;
  border: solid 2px #7977a4 !important;
  font-size: 1rem !important;
  line-height: 1.31 !important;
  color: #06016c !important;
  border-radius: 1.563rem !important;
  padding: 0rem 1.25rem 0rem 1.25rem !important;
  background-image: url("../../assets/images/dateInputIcon.png");
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 9.375rem;
  background-color: transparent;
  background-size: 2rem;
}
.DateInput_input::placeholder {
  display: none;
}
.DateInput__label {
  font-size: 1rem;
  line-height: 1.31 !important;
  color: #06016c;
  position: absolute;
  display: block;
  top: 0.9rem;
  left: 1.25rem;
  z-index: -1;
}
.DateInput_input:focus ~ .DateInput__label {
  top: -1.5em;
  left: 0rem;
  color: #7977a4;
  cursor: pointer;
}
.dateInput__label--exp {
  top: -1.5em;
  left: 0rem;
  color: #7977a4;
  cursor: pointer;
  position: absolute;
}
.DateInput_input:focus {
  border: solid 2px #2699fb !important;
}
.DateRangePickerInput__withBorder {
  border: none;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.DateRangePickerInput {
  background: transparent;
}
.DateRangePicker {
  display: block;
}
.DayPickerKeyboardShortcuts_show__topRight{
  display: none!important;
}
.borderStyle {
  /*border: solid 2px rgba(121, 119, 164, 0.5) !important;*/
  border: none !important;
  width: 12.188rem !important;
  height: 3.125rem !important;
  font-size: 1rem !important;
  line-height: 1.31 !important;
  color: #06016c !important;
  border-radius: 25px !important;
  padding: 0rem 1.25rem 0rem 1.25rem !important;
  background-image: url("../../assets/images/dateInputIcon.png");
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 9.375rem;
  background-color: rgba(6, 1, 108, 0.03);
  background-size: 2rem;

}
.DateRangePicker_picker {
  z-index: 99999;
}

.CalendarDay__selected_span {
  background: #2699fb;
  border: 1px double #98abd0;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #06016c;
  border: 1px double #06016c;
  color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #06016c50 !important;
  border: 1px double #06016c50 !important;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #06016c50 !important;
  border: 1px double #06016c50 !important;
  color: #fff;
}
.DayPickerNavigation__verticalDefault {
  height: 42px !important;
}
.DayPickerNavigation_svg__vertical {
  height: 31px !important;
}
.DayPicker_transitionContainer__vertical{
  height: 33.813rem!important;
}
@media only screen and (max-width:1500px){
  .CalendarMonthGrid__vertical{
    width: 24.6rem!important;
  }
  .CalendarMonth_caption {
    font-size: 2.125rem;
  }
}
@media only screen and (max-width:1496px){
  .DayPicker_weekHeader_ul{
    font-size: 1.2rem!important;
  }
  .DayPicker_weekHeader_li{
    width: 3.2rem!important;
  }
  .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover, .CalendarDay{
    width: 2.98rem!important;
    height: 2.8rem!important;
  }
  .CalendarDay{
    font-size: 1.2rem!important;
  }
}
@media only screen and (max-width:1390px){
  .DayPicker_weekHeader__vertical {
    left: 58%!important;
}
}
@media only screen and (max-width:1235px){
  .DayPicker_weekHeader__vertical {
    left: 61%!important;
}
}
@media only screen and (max-width:1192px){
  .DayPicker_weekHeader__vertical {
    left: 66%!important;
}
}
@media only screen and (max-width:989px){
  .DayPicker_weekHeader__vertical {
    left: 68%!important;
}
}
@media only screen and (max-width:918px){
  .DayPicker_weekHeader__vertical {
    left: 73%!important;
}
}
@media only screen and (max-width:844px){
  .DayPicker_weekHeader__vertical {
    left: 75%!important;
}
}
@media only screen and (max-width:800px){
  .DayPicker_weekHeader__vertical {
    left: 83%!important;
}
}
@media only screen and (max-width:772px){
  .DayPicker_weekHeader__vertical {
    left: 86%!important;
}
.DayPicker_weekHeader_li {
  width: 3rem!important;
}
}
@media only screen and (max-width:745px){
  .DayPicker_weekHeader__vertical {
    left: 91%!important;
}
}
@media only screen and (max-width:700px){
.DateInput_input {
  width: 14.188rem !important;
}
}
@media only screen and (max-width:630px){
  .DayPicker_weekHeader__vertical {
    left: 97%!important;
}
.DayPickerNavigation__verticalDefault {
  height: 4.625rem !important;
}
.DayPickerNavigation_svg__vertical {
  height: 2.938rem !important;
}
}
@media only screen and (max-width: 576px) {
  .DayPicker_weekHeader__vertical {
    left: 100%!important;
}
}
@media only screen and (max-width: 545px) {
.DayPicker_weekHeader__vertical {
  left: 113%!important;
}
}
@media only screen and (max-width: 500px) {
  .DateInput {
    width: 100% !important;
    margin-bottom: 5rem;
  }
  .DateInput_input,
  .borderStyle {
    width: 100% !important;
    height: 8.125rem !important;
    font-size: 3rem !important;
    line-height: 1.31 !important;
    padding: 0rem 1.25rem 0rem 2.25rem !important;
    background-position-x: 92% !important;
    border-radius: 4.563rem !important;
  }
  .DateInput__label {
    font-size: 3rem !important;
    line-height: 2.1 !important;
    left: 2.25rem !important;
  }
  .dateInput__label--exp {
    font-size: 3rem !important;
  }
  .CalendarMonthGrid__vertical{
    width: 46.6rem!important;
  }
  .DayPicker_transitionContainer__vertical {
    height: 60.813rem!important;
}
.CalendarMonth_caption {
  font-size: 3.125rem;
}
.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover, .CalendarDay {
  width: 5.5rem!important;
  height: 5.8rem!important;
}
.CalendarDay {
  font-size: 2.2rem!important;
}
.DayPicker_weekHeader_ul {
  font-size: 2.5rem!important;
}
.DayPicker_weekHeader__vertical {
  left: 65%!important;
}
.DayPicker_weekHeader_li {
  width: 5.5rem!important;
}
}
@media only screen and (max-width:320px){
  .CalendarMonthGrid__vertical {
    width: 44.6rem!important;
}
}