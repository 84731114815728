* {
  margin: 0rem; /* 0px */
  padding: 0rem; /* 0px */
  box-sizing: border-box;
}
.nothingFound{
  display: flex;
  justify-content: center;
  margin:30px 0px;
}
.nothingFound svg{
    width: 35%;
    height: 100%;
}
.dashboard__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: /*3.9375rem*/ 63px;
  padding-left: /*5.5rem*/ 88px;
  position: relative;
  padding-right: 7.5rem;
  overflow-y: scroll;
  position: relative;
  /* overflow-x: hidden; */
}
.section__transport {
  /* padding-bottom: 1.375rem; 22px */
  /* display: flex;*/
  overflow: hidden;
}
.section__calendar {
  margin-top: 1.481rem;
  margin-bottom: 4.313rem;
}
.section__transport .slick-list {
  padding-top: /*3.5rem*/ 56px;
  padding-bottom: /*5rem*/ 80px;
  padding-left: /*1.875rem*/ 50px;
}
.slick-slider {
  box-shadow: inset -14.5rem -2rem 9.1875rem -0.375rem /* -200px 0px 163px 26px */
    rgb(242, 242, 248);
  width: 103.125rem;
}

.section__anfragen {
  margin-top: /*2.5625rem*/ 41px;
  display: flex;
  overflow-x: hidden;
  margin-bottom: 2.813rem;
}
.section__anfragen .slick-list {
  padding-left: /*1.875rem*/ 36px;
}
.section__anfragen .slick-slider {
  /* width: 77.6875rem; */
  width: 82.6875rem;
  position: relative;
}
.rightSide__shadow {
  /* width: 7%; */
  width: 7.5rem;
  z-index: 9999;
  position: absolute;
  top: 6.25rem; /* 100px */
  bottom: 0rem; /* 0px */
  right: 0rem; /* 70px */
  background: transparent;
  /* box-shadow: inset -179px 1px 256px 85px rgb(228, 228, 243); */
  box-shadow: -1rem -1rem 8rem 9.625rem rgb(242, 242, 248);
  /* box-shadow: -95px 6px 138px 16px rgb(242, 242, 248); */
}

.section__anfragen .slick-arrow{
  top: 3.5rem;
  z-index: 9999;
}
.section__anfragen .slick-next{
  right: -7rem;
}


@media only screen and (min-width: 2278px) {
  html {
    font-size: 109% !important;
  }
}

@media only screen and (min-width: 2735px) {
  html {
    font-size: 128% !important;
  }
}

@media only screen and (min-width: 2955px) {
  html {
    font-size: 122% !important;
  }
  .content__header--line {
    width: 10.188rem;
  }
}
@media only screen and (min-width: 3500px) {
  html {
    font-size: 136% !important;
  }
  #RBS-Scheduler-root {
    margin: 0px auto !important;
  }
}
@media only screen and (min-width: 3800px) {
  .content__header--line {
    width: 8.188rem;
  }
  html {
    font-size: 157% !important;
  }
}
@media only screen and (min-width: 4000px) {
  html {
    font-size: 165% !important;
  }
}

/***********************************************************/
@media only screen and (max-height: 1313px) {
  html {
    font-size: 124% !important;
  }
}
@media only screen and (max-height: 1258px) {
  html {
    font-size: 114% !important;
  }
}
@media only screen and (max-height: 1172px) {
  html {
    font-size: 108% !important;
  }
}
@media only screen and (max-height: 1105px) {
  html {
    font-size: 100% !important;
  }
}
@media only screen and (max-height: 1062px) {
  html {
    font-size: 94% !important;
  }
}
@media only screen and (max-height: 1000px) {
  html {
    font-size: 87% !important;
  }
}
@media only screen and (max-height: 920px) {
  html {
    font-size: 80% !important;
  }
}
@media only screen and (max-height: 830px) {
  html {
    font-size: 77% !important;
  }
}
@media only screen and (max-height: 786px) {
  html {
    font-size: 72% !important;
  }
}
@media only screen and (max-height: 737px) {
  html {
    font-size: 67% !important;
  }
}
@media only screen and (max-height: 692px) {
  html {
    font-size: 64% !important;
  }
}
@media only screen and (max-height: 654px) {
  html {
    font-size: 60% !important;
  }
}
/*****************************************************/
@media only screen and (max-width: 2500px) {
  html {
    font-size: 100% !important;
  }
}
@media only screen and (max-width: 2200px) {
  html {
    font-size: 97% !important;
  }
}
@media only screen and (max-width: 2010px) {
  html {
    font-size: 92% !important;
  }
}
@media only screen and (max-width: 1965px) {
  html {
    font-size: 88% !important;
  }
}
@media only screen and (max-width: 1735px) {
  html {
    font-size: 82% !important;
  }
}
@media only screen and (max-width: 1630px) {
  html {
    font-size: 78% !important;
  }
}
/*
@media only screen and (max-width: 1618px) {
  html {
    font-size: 82% !important;
  }
} */
@media only screen and (max-width: 1550px) {
  html {
    font-size: 76% !important;
  }
}
@media only screen and (max-width: 1495px) {
  html {
    font-size: 72% !important;
  }
}
@media only screen and (max-width: 1438px) {
  html {
    font-size: 68% !important;
  }
}
@media only screen and (max-width: 1390px) {
  html {
    font-size: 63% !important;
  }
  .form-input {
    height: 3.35rem!important;
  }
}
/*@media only screen and (max-width: 1288px) {
  html {
    font-size: 65% !important;
  }
} */
@media only screen and (max-width: 1236px) {
  html {
    font-size: 60% !important;
  }
}
@media only screen and (max-width: 1200px) {
  html {
    font-size: 61% !important;
  }
}

/*UNDER 1200px*/
@media only screen and (max-width: 1199px) {
  html {
    font-size: 55% !important;
  }
}
@media only screen and (max-width: 1084px){
  .section__anfragen .slick-next {
    right: -5rem;
}
}
@media only screen and (max-width: 1068px){
  .section__anfragen .slick-next {
    right: -3rem;
}
}
@media only screen and (max-width: 1055px) {
  .shipmentsContent__left {
    width: 59.65rem !important;
  }
  .shipmentsContent__right {
    width: calc(100% - 59.65rem) !important;
  }
}
@media only screen and (max-width: 1050px){
  .section__anfragen .slick-next {
    right: -2rem;
}
}
@media only screen and (max-width: 1040px){
  .section__anfragen .slick-next {
    right: 0rem;
}
}
@media only screen and (max-width: 1030px) {
  .shipmentsContent__left {
    width: 56.65rem !important;
  }
  .shipmentsContent__right {
    width: calc(100% - 56.65rem) !important;
  }
}
@media only screen and (max-width: 1024px){
  .section__anfragen .slick-next {
    right: 2rem;
}
}
@media only screen and (max-width: 1004px){
  .section__anfragen .slick-next {
    right: 3.9rem;
}
}
@media only screen and (max-width: 989px) {
  html {
    font-size: 53% !important;
  }
  .shipmentsContent__left {
    width: 53.65rem !important;
  }
  .shipmentsContent__right {
    width: calc(100% - 53.65rem) !important;
  }
  .shipmentsContent__getBid input {
    width: 18.625rem !important;
  }
  .shipmentsContent__getBid .inputHolder {
    margin-right: 1.25rem !important;
  }
}
@media only screen and (max-width: 963px) {
  .mobileDownload__mobile {
    width: 47rem;
  }
  .mobileDownload__text p {
    font-size: 1.9rem;
  }
  .mobileDownload__text h2 {
    font-size: 2.7rem !important;
  }
  .mobileDownload__text .content__header--line {
    width: 18rem;
  }
}
@media only screen and (max-width: 958px){
  .section__anfragen .slick-next {
    right: 4.9rem;
}
}
@media only screen and (max-width: 948px){
  .section__anfragen .slick-next {
    right: 5.9rem;
}
}
@media only screen and (max-width: 940px) {
  .shipmentsContent__left {
    width: 49.65rem !important;
  }
  .shipmentsContent__right {
    width: calc(100% - 49.65rem) !important;
  }
  .shipmentsContent__getBid input {
    width: 17.625rem !important;
  }
}
@media only screen and (max-width: 938px){
  .section__anfragen .slick-next {
    right: 7.9rem;
}
}
@media only screen and (max-width:925px){
  .dashboard__content {
    padding-left: /*5.5rem*/ 35px;
  }
  .section__anfragen .slick-next {
    right: 3.9rem;
}
}
@media only screen and (max-width: 918px) {
  html {
    font-size: 49% !important;
  }
  .ShipmentsMUAImport, .addVehicleInformation, .addTransportInformation, .addInformationFinished,
  .addTruck, .truckDetails  {
    width: 43.25rem!important;
}
.section__anfragen .slick-next {
  right: -4.1rem;
}
}
@media only screen and (max-width: 907px) {
  .section__anfragen .slick-arrow{
    display: none!important;
  }
}
@media only screen and (max-width: 887px) {
  .mobileDownload__mobile {
    width: 44rem;
  }
  .mobileDownload__text p {
    font-size: 1.7rem;
    width: 34.25rem;
  }
  .mobileDownload__text h2 {
    font-size: 2.5rem !important;
  }
  .mobileDownload__text .content__header--line {
    width: 16rem;
  }
}
@media only screen and (max-width: 844px) {
  html {
    font-size: 47% !important;
  }
  .mobileDownload__downloadButtons svg {
    width: 9.725rem;
  }
  .mobileDownload__downloadButtons svg:nth-last-of-type(1) {
    width: 10.625rem;
  }
  .mobileDownload__background img {
    width: 109%;
  }
}
@media only screen and (max-width: 800px) {
  html {
    font-size: 46% !important;
  }
}

@media only screen and (max-width: 793px) {
  .shipmentsContent__left {
    width: 47.65rem !important;
  }
  .shipmentsContent__right {
    width: calc(100% - 47.65rem) !important;
  }
  .shipmentsContent__getBid input {
    width: 16.625rem !important;
  }
}

@media only screen and (max-width: 772px) {
  html {
    font-size: 44% !important;
  }
  .getBid__button {
    margin-left: 0.563rem !important;
  }
}

@media only screen and (max-width: 745px) {
  html {
    font-size: 41% !important;
  }
  .dashboard__content {
    padding-right: 67px;
  }
  .rightSide__shadow {
    width: 61px;
  }
}
@media only screen and (max-width: 702px) {
  .shipmentsContent__left {
    width: 45.65rem !important;
  }
  .shipmentsContent__right {
    width: calc(100% - 45.65rem) !important;
  }
  .shipmentsContent__getBid input {
    width: 15.625rem !important;
  }
  .ShipmentsMUAImport, .addVehicleInformation, .addTransportInformation, .addInformationFinished, .addTruck, .truckDetails {
    width: 46.25rem!important;
}
}
@media only screen and (max-width: 680px) {
  .shipmentsContent__right {
    display: none !important;
  }
  .shipmentsContent__left {
    width: 100% !important;
  }
  .shipmentsContent__getBid input {
    width: 22.625rem !important;
  }
}
@media only screen and (max-width: 633px) {
  html {
    font-size: 38% !important;
  }
}
@media only screen and (max-width: 599px) {
  .shipmentsContent__getBid input {
    width: 21.625rem !important;
  }
}
@media only screen and (max-width: 576px) {
  html {
    font-size: 36% !important;
  }
}
@media only screen and (max-width: 545px) {
  html {
    font-size: 34% !important;
  }
}
@media only screen and (max-width: 545px) {
  html {
    font-size: 32% !important;
  }
}

/*MOBILE RESPONSIVE FROM 500px*/
@media only screen and (max-width: 500px) {
  /*DASHBOARD HEADER*/
  .dashboardHeader {
    display: none;
  }
  .customBackButton{
    display: none!important;
  }
  .searchHeader{
    display: flex!important;
  }
  .mobileHeader {
    display: flex !important;
  }
  /*SHIPMENTS*/
  .navbar__bottomSide {
    margin-top: 2rem;
  }
  .content__main {
    height: calc(100vh - 25rem) !important;
  }
  .shipments__content {
    height: calc(100vh - 25rem) !important;
    width: 100% !important;
    padding: 0rem !important;
  }
  .shipmentsContent__header {
    padding: 5rem 5rem 0rem 5rem;
  }
  /*.shipmentsContent__left*/
  .content__header h2 {
    font-size: 4.875rem !important;
  }
  /*.shipmentsContent__left*/
  .content__header--line {
    width: 22.188rem !important;
  }
  .shipmentsContent__paragraph,
  .shipmentView__paragraph,
  .shipmentViewShipper__paragraph,
  .orders__section--paragraph {
    font-size: 3.1rem !important;
    margin-top: 2.25rem !important;
  }
  .shipmentsContent__myShipments .shipmentsContent__paragraph {
    padding: 0rem 5rem;
  }

  .shipmentsContent__getBid > div {
    width: 100% !important;
    margin-bottom: 7rem;
  }
  .shipmentsContent__getBid input {
    width: 100% !important;
    height: 10.125rem !important;
    font-size: 3.125rem !important;
  }
  .shipmentsContent__getBid input::placeholder {
    font-size: 3rem;
  }
  .shipmentsContent__getBid .inputHolder {
    margin-right: 0rem !important;
  }
  .shipmentsContent__getBid {
    position: unset !important;
    width: 100% !important;
    flex-direction: column;
    margin-top: 7rem;
    padding: 0rem 5rem;
  }
  .getBid__button {
    margin-left: 0rem !important;
    width: 40% !important;
    height: 8.5rem !important;
    font-size: 2.1rem !important;
    margin-top: 5rem !important;
    align-self: center;
  }
  .listPlaces {
    font-size: 2.6rem;
  }
  .listPlaces li {
    padding: 1.7rem;
  }
  .listPlaces li:nth-child(1) {
    padding-top: 1.75rem;
  }
  .listPlaces li:nth-last-child(1) {
    padding-bottom: 1.75rem;
  }

  .shipmentsContent__myShipments .content__header {
    padding: 0rem 5rem;
  }
  /*DASHBOARD NAVBAR*/
  .mobileNavbar {
    display: flex !important;
  }
  .dashboardNavbar {
    display: none !important;
  }

  /*SHIPMENT VIEW*/
  .shipmentView__main {
    /* height: calc(100vh - 25rem) !important; */
    height: 100vh;
  }
  .shipmentView__content {
    /* height: calc(100vh - 25rem) !important; */
    height: 100vh !important;
    width: 100% !important;
    padding: 4rem !important;
  }

  .shipmentViewCard {
    height: 93.688rem !important;
    flex-direction: column;
    width: 100% !important;
    border-radius: 4.25rem !important;
    margin-bottom: 3.813rem !important;
  }
  .shipmentViewCard__left {
    height: 43.688rem !important;
    width: 100% !important;
  }
  .shipmentViewCard__right {
    width: 100% !important;
    padding: 3.375rem 1.063rem 2.438rem 1.063rem !important;
  }
  .shipmentViewCard__right .blueButton {
    display: none;
  }
  .shipmentViewCard__main > .blueButton{
    display: block!important;
  }
  .image-gallery-image,
  .image-gallery-slides {
    border-top-left-radius: 4.25rem !important;
    border-top-right-radius: 4.25rem !important;
    border-bottom-left-radius: 0rem !important;
  }
  .right__content--secondRow {
    max-width: 18rem !important;
  }
  .content__rowData {
    font-size: 2.5rem !important;
    margin-bottom: 3.813rem !important;
  }
  .right__content--thirdRow {
    max-width: 16rem;
  }
  .shipmentPosition {
    flex-direction: column-reverse;
    height: 80.813rem !important;
    border-radius: 4.25rem !important;
  }
  .shipmentPosition__right {
    width: 100% !important;
    height: 58% !important;
  }
  .shipmentPosition__left {
    width: 100% !important;
    height: 42% !important;
    padding: 2.75rem 3.625rem 0rem 9.125rem !important;
  }
  .shipmentPosition__right > div > div {
    border-top-right-radius: 4.25rem !important;
    border-top-left-radius: 4.25rem !important;
    border-bottom-right-radius: 0rem !important;
  }
  .positionTo__city,
  .positionFrom__city {
    font-size: 3.25rem !important;
  }
  .positionTo__country,
  .positionFrom__country {
    font-size: 2rem !important;
  }
  .shipmentPosition__inprogress {
    font-size: 2rem !important;
  }
  .shipmentPosition__leftLine {
    margin-right: 4.281rem !important;
  }
  .shipmentViewUserCard {
    flex-direction: column;
    height: 57.438rem !important;
  }
  .shipmentViewUserCard__left {
    width: 100% !important;
    margin-bottom: 3rem!important;
  }
  .shipment__userImage {
    width: 16.25rem !important;
    height: 16.25rem !important;
  }
  .shipments__notFound {
    font-size: 5rem !important;
  }
  .shipmentViewCard__main .blueButton {
    width: 21.5rem;
    height: 6.5rem;
    border-radius: 1.2rem;
    font-size: 2.75rem;
  }
  .shipmentViewCard__main {
    margin-bottom: 5rem;
  }
  .shipmentPosition__leftLine svg:nth-of-type(1) {
    width: 4rem !important;
    height: 3rem !important;
  }
  .shipmentPosition__leftLine svg:nth-of-type(2) {
    height: 23.969rem !important;
  }
  .start {
    top: 1.9rem !important;
  }
  .inProgress {
    top: 10.25rem !important;
  }
  .destination {
    top: 18.25rem !important;
  }

  /*ORDERS*/
  .orders__content {
    width: 100% !important;
    padding-left: 0rem !important;
    height: calc(100vh - 12.5rem) !important;
  }
  .orders__slider .slick-slide {
    margin-right: 1rem;
  }
  .orders__slider .slick-track {
    display: flex;
    align-items: center;
  }
  .orders__content .content__header {
    padding-left: 6.438rem;
  }
  .orders__section--paragraph {
    padding-left: 6.438rem;
  }
  .orders__slider .slick-slider {
    width: 100% !important;
  }
  .orders__section--paragraph {
    width: 95% !important;
  }
  /*OFFERS*/
  .offers__content {
    width: 100% !important;
    padding-left: 0rem !important;
    height: calc(100vh - 25rem) !important;
  }
  .offers__main {
    height: calc(100vh - 25rem) !important;
  }
  .offers__section--heading {
    width: 57.563rem !important;
    padding-left: 6.125rem;
  }
  .offers__section_numbers {
    padding: 1.3rem 2.18rem !important;
    font-size: 2.5rem !important;
  }
  .section__acceptedOffers .slick-slider,
  .section__pendingOffers .slick-slider {
    width: 100% !important;
  }
  .offers__section .slick-slide {
    margin-right: 1rem !important;
  }
  .offers__section .slick-track {
    display: flex;
    align-items: center;
  }
  .offerCard {
    width: 100% !important;
    height: 54.563rem !important;
    border-radius: 4.25rem !important;
  }
  .offerCard__top {
    border-top-left-radius: 4.25rem !important;
    border-top-right-radius: 4.25rem !important;
  }

  .fromTo__city,
  .fromTo__country {
    width: 14.1rem;
    padding: 0rem 0.5rem;
  }
  .fromTo__city {
    font-size: 3.5rem;
  }
  .fromTo__country {
    font-size: 1.75rem;
  }
  .fromToSection__arrow {
    width: 4.0625rem;
    margin-top: 1.375rem;
  }
  .offerCard__carType {
    font-size: 3.125rem !important;
  }
  .offerCard__price {
    width: 100% !important;
    height: 5.2rem !important;
    font-size: 3.125rem !important;
  }
  .offerCard__top {
    height: 22.063rem !important;
  }
  .offerCard__dates {
    height: 14.5rem !important;
    border-bottom-left-radius: 4.25rem !important;
    border-bottom-right-radius: 4.25rem !important;
    padding: 0.813rem 4.625rem 1.188rem 4.313rem !important;
  }
  .offerCard__fromTo {
    margin-bottom: 1rem !important;
  }
  .offerCard__icon svg {
    width: 6.454rem !important;
    height: 7.035rem !important;
  }
  .offerCard__Date span:nth-child(1) {
    font-size: 1.6rem !important;
  }
  .offerCard__Date span {
    font-size: 2rem !important;
  }
  .offers__section .slick-slide.slick-center > div > div {
    padding: 14.25rem 9.25rem;
  }
  .acceptedOffer__name {
    font-size: 2.125rem !important;
  }
  /*RATINGS*/

  .reviews__content {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
    padding-left: 0rem !important;
  }

  /*PAYMENTS*/
  .paymentsShipper__main {
    height: calc(100vh - 25rem) !important;
  }
  .paymentsShipper__content {
    width: 100% !important;
    padding-left: 0rem !important;
    height: calc(100vh - 25rem) !important;
  }
  .paymentsShipper__section--heading,
  .billingHistory__section--heading {
    padding-left: 6.438rem;
  }
  .paymentsShipper__section--heading p,
  .billingHistory__section--heading p {
    font-size: 3rem !important;
    width: 90% !important;
    /* margin-top: 3.125rem!important; */
  }
  .billingHistory__section--heading p {
    line-height: 3.25rem !important;
  }
  .pendingPaymentsCard__content {
    width: 100% !important;
    height: 54.938rem !important;
    border-radius: 4.25rem !important;
  }
  .pendingPaymentsCard__name {
    font-size: 2.125rem !important;
  }
  .pendingPaymentsCard__top {
    height: 23.688rem !important;
    border-top-left-radius: 4.25rem !important;
    border-top-right-radius: 4.25rem !important;
  }
  .pendingPaymentsCard__price {
    height: 4.875rem !important;
    font-size: 3.125rem !important;
  }
  .pendingPaymentsCard__carType {
    font-size: 3.125rem !important;
    margin-top: 2rem !important;
    margin-bottom: 2.063rem !important;
  }
  .pendingPaymentsCard__fromTo {
    margin-bottom: 5.688rem !important;
  }
  .pendingPaymentsCard__button {
    height: 6.6rem !important;
    border-bottom-left-radius: 4.25rem !important;
    border-bottom-right-radius: 4.25rem !important;
    font-size: 1.875rem !important;
  }
  .paymentsShipper__pandingPayments .slick-slider {
    width: 100% !important;
  }
  .paymentsShipper__section .slick-track {
    display: flex;
    align-items: center;
  }
  .paymentsShipper__pandingPayments .slick-list {
    padding: 0px !important;
  }
  .paymentsShipper__section .slick-slide {
    margin-right: 1rem !important;
  }
  .billingTable {
    display: none !important;
  }
  .billingTableMobile {
    display: block !important;
  }
  .billingTableRow td:nth-of-type(1) {
    font-size: 3.25rem !important;
    padding-right: 5rem !important;
  }
  .billingTableRow td:nth-last-of-type(1) {
    width: fit-content !important;
  }

  /*RATINGS*/
  .reviewContent__section--heading {
    width: 53.563rem !important;
    padding-left: 5.5rem;
  }
  .reviewContent__section--heading2 {
    width: 72.875rem !important;
    padding-left: 5.5rem;
  }
  .reviews__content .slick-slider {
    width: 100% !important;
  }
  .reviews__content .slick-slide {
    margin-left: 0rem !important;
    margin-right: 1rem !important;
  }
  .reviews__content .slick-track {
    align-items: center;
    display: flex;
  }
  .reviews__card {
    width: 100% !important;
    height: 57.25rem !important;
    border-radius: 4.25rem !important;
  }
  .leaveReviews__top {
    height: 20.063rem !important;
    border-top-left-radius: 4.25rem !important;
    border-top-right-radius: 4.25rem !important;
  }
  .leaveReviews__button {
    height: 6.125rem !important;
    border-bottom-left-radius: 4.25rem !important;
    border-bottom-right-radius: 4.25rem !important;
    font-size: 2.5rem !important;
  }
  .leaveReviews__bottom--emoticons .emoticonsImg {
    width: 5rem !important;
    height: 5rem !important;
  }
  .bottom__textarea {
    width: 28.625rem !important;
    height: 15.75rem !important;
    border-radius: 3.5rem !important;
  }
  .leaveReviews__name {
    font-size: 2.125rem !important;
  }
  .leaveReviews__name--check {
    margin-right: 1.625rem !important;
    width: 2.125rem !important;
    height: 2.125rem !important;
  }
  .reviewContent__section_numbers {
    padding: 0.95rem 2rem !important;
    font-size: 2.5rem !important;
  }
  .reviewsLeft__review {
    font-size: 2rem !important;
    width: 40rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .recievedReviewsCard__card {
    height: 27rem !important;
    width: 100% !important;
  }
  .recievedReviews__emoji {
    width: 5rem !important;
    height: 5rem !important;
  }
  .recievedReviews__person {
    font-size: 2.25rem !important;
  }
  .recievedReviews__check {
    width: 2.125rem !important;
    height: 2.125rem !important;
  }
  .recievedReviews__right {
    display: none !important;
  }
  .recievedReviews__left {
    width: 100% !important;
  }

  /*ACCOUNT*/
  .account__content {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
    padding-left: 0rem !important;
  }
  .accountSettings__nav {
    display: none !important;
  }
  .mainSettings {
    width: 100% !important;
    flex-direction: column !important;
  }
  .leftSide__profileImage {
    border-radius: 99999px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 28.688rem !important;
    height: 28.688rem !important;
  }
  .mainSettings__leftSide button {
    display: none;
  }
  .accountSettings {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 82% !important;
    margin-top: 7.188rem !important;
  }
  .account__header {
    padding-left: 5.438rem;
  }
  .mainSettings__rightSide {
    width: 100% !important;
    text-align: -webkit-center !important;
    margin-top: 7rem;
  }
  /*.account__content .group*/
  .form-input {
    height: 8.125rem !important;
    border-radius: 4.563rem !important;
    padding-left: 3.25rem !important;
    font-size: 3.125rem !important;
  }
  /*.account__content*/
  .group {
    border-radius: 4.563rem !important;
  }
  .form-textareaInput {
    border-radius: 4.563rem !important;
    height: 32.5rem !important;
    font-size: 3.125rem !important;
    padding: 1.688rem 1.25rem 0.5rem 3.063rem !important;
  }
  /*.account__content*/
  .blueButton {
    width: 20.5rem !important;
    height: 6.5rem !important;
    font-size: 2.75rem !important;
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .form-label {
    font-size: 3.125rem !important;
    left: 3rem !important;
    line-height: 1.89 !important;
  }
  .form-input:focus ~ .form-label, .form-input:valid ~ .form-label{
    left:0rem!important;
  }
  .form-label-exep {
    font-size: 3rem !important;
  }
  .form-input:focus ~ .form-label {
    top: -1.5em !important;
    font-size: 3rem !important;
  }
  .form-textareaInput:focus ~ .form-textareaLabel,
  .form-textareaInput:valid ~ .form-textareaLabel {
    font-size: 3rem !important;
    top: -1.5em !important;
    left: 0rem !important;

  }
  .phoneNumber__paragraph {
    font-size: 3rem !important;
  }
  .mainSettings__leftSide {
    width: fit-content !important;
    margin: 0px auto;
  }
  .phoneNumber__input::placeholder {
    font-size: 3rem;
  }
  /*.mainSettings__leftSide*/
  .form-textareaLabel {
    font-size: 3.125rem !important;
    top: 3.12rem !important;
    left: 2.5rem !important;
  }
  .account__content .group-textarea {
    margin-top: 8rem !important;
    border-radius: 4.563rem !important;
  }
  .personalSettings {
    width: 100% !important;
  }
  .personalSettings__leftSide {
    width: 100% !important;
    text-align: -webkit-center !important;
  }
  .personalSettings .group {
    margin-bottom: 7.063rem !important;
  }
  .changePassword {
    width: 100% !important;
  }
  .changePassword__content {
    width: 100% !important;
    text-align: -webkit-center !important;
  }
  .changePassword__content .group {
    margin-bottom: 7.063rem !important;
  }
  .companySettings form {
    flex-direction: column !important;
  }
  .companySettings {
    width: 100% !important;
  }
  .companySettings__leftSide {
    width: 100% !important;
  }
  .companySettings_rightSide {
    width: 100% !important;
    text-align: -webkit-center !important;
  }
  .companySettings .react-datepicker__input-container input {
    height: 8.125rem !important;
    border-radius: 4.563rem !important;
    padding-left: 3.25rem !important;
    font-size: 3.125rem !important;
  }
  .companySettings__labelClass {
    top: 1.9rem !important;
    font-size: 3.125rem !important;
    left: 3rem !important;
  }
  .companySettings .react-datepicker-wrapper {
    margin-bottom: 10.063rem !important;
  }
  .companySettings__leftSide .form__DocumentUpload {
    height: 17.5rem !important;
  }
  .form__DocumentUpload {
    border-radius: 5.125rem !important;
    margin-bottom: 7.1875rem !important;
  }

  /*IMPORT VEHICLE*/
  .addTruckAnimated {
    height: calc(100vh - 25rem) !important;
    width: 100%;
  }
  .ShipmentsMUAImport {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
  }
  .ShipmentsMUAImport__content--heading {
    font-size: 5.25rem !important;
    margin-bottom: 8px !important;
  }
  .ShipmentsMUAImport__content--paragraph {
    font-size: 3.7rem !important;
    margin-bottom: 5.5rem !important;
  }
  .ShipmentsMUAImport__content--OR {
    margin-bottom: 7.1875rem !important;
    margin-top: 7.1875rem !important;
  }
  .ShipmentsMUAImport__content--OR p {
    font-size: 2.75rem !important;
  }
  .ShipmentsMUAImport__content--OR hr {
    width: 38% !important;
  }
  .ShipmentsMUAImport__content .blueButton {
    width: 80% !important;
    margin: 2.1875rem auto 0rem auto !important;
  }

  .addVehicleInformation {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
    overflow-y: scroll;
  }
  .addVehicleInformation__content--heading {
    font-size: 5.25rem !important;
  }
  .addVehicleInformation__content--paragraph {
    font-size: 3.25rem !important;
  }
  .addVehicleInformation__content--select {
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    height: 75.75rem!important;
    margin-top: 7rem;
    margin-bottom: 5.125rem !important;
  }
  .customSelectAddShipment {
    width: 100% !important;
  }
  .addVehicleInformation__content--select > div {
    width: 100%;
  }
  .css-g1d714-ValueContainer {
    padding: 10px 8px !important;
  }
  .customSelectAddShipment > div {
    border-radius: 4.563rem !important;
  }
  .addVehicleInformation__content .form__ImageUpload {
    height: 60.875rem !important;
    border-radius: 6.125rem !important;
    margin-bottom: 4.125rem !important;
  }
  .imageUpload .thumbsSecondContainer {
    width: 100% !important;
    height: 55rem !important;
    margin: 2.313rem !important;
  }
  .thumbSecondImage,
  .thumbSecondImage img {
    width: 18.6rem !important;
    height: 16rem !important;
  }
  .customSelectAddShipment:hover ~ .form-label {
    top: -1.8em !important;
  }
  .firstImgPlaceholder {
    width: 46.688rem !important;
  }
  .imagePlaceholder {
    width: 31.313rem !important;
  }
  .addVehicleInformation__content--imageParagraph {
    font-size: 3.25rem!important;
    margin-bottom: 3rem;
  }
  .addTransportInformation {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
    overflow-y: scroll;
  }
  .DateRangePickerInput__withBorder {
    flex-direction: column;
  }
  .addTransportInformation__input--paragraph {
    font-size: 3.25rem !important;
    line-height: 2.35 !important;
  }
  .addTransportInformation__buttons input {
    width: 46% !important;
    margin-bottom: 2rem;
  }
  .addTransportInformation__input {
    height: 7.125rem !important;
    font-size: 2.85rem !important;
    border-radius: 4.563rem !important;
  }
  .ReactTags__tagInputField {
    height: 8.125rem !important;
    border-radius: 4.563rem !important;
    padding-left: 3.25rem !important;
    font-size: 3.125rem !important;
    line-height: 3.33 !important;
  }
  .ReactTags__tagInputField::placeholder {
    font-size: 3.125rem !important;
  }
  .tag-wrapper.ReactTags__tag {
    font-size: 3.1rem !important;
    padding: 0.5rem 1.5rem !important;
  }
  .ReactTags__remove {
    font-size: 4rem !important;
  }
  .addTransportInformation__content--heading {
    font-size: 5.25rem !important;
  }
  .addTransportInformation__content--paragraph {
    font-size: 3.25rem !important;
    margin-bottom: 7.125rem !important;
  }
  .addInformationFinished {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
  }
  .addInformationFinished__content .blueButton {
    width: 88% !important;
  }
  .addInformationFinished__content--heading {
    font-size: 5.25rem !important;
    margin-bottom: 2.3125rem !important;
  }
  .addInformationFinished__content--paragraph {
    font-size: 3.25rem !important;
  }
  .addInformationFinished__contentImage {
    width: 64.884rem !important;
    height: 28.563rem !important;
  }
  /*DASHBOARD*/
  .dashboard__content {
    padding-left: 0px !important;
    padding-top: 25px !important;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .rightSide__shadow {
    display: none;
  }
  .dashboard__content .content__header {
    padding-left: 25px;
  }
  .section__anfragen .slick-slider {
    width: 100% !important;
  }
  .section__anfragen .slick-slide {
    margin-left: 0rem !important;
    margin-right: 1rem !important;
  }
  .section__anfragen .slick-track {
    align-items: center;
    display: flex;
  }
  .anfragenCard .card {
    min-width: 100% !important;
    width: 100% !important;
    height: 52.375rem !important;
    border-radius: 4.5625rem;
    flex-direction: column-reverse;
  }
  .anfragenCard .card__rightSide {
    width: 100% !important;
    height: 29.375rem !important;
    border-top-right-radius: 4.5625rem !important;
    border-top-left-radius: 4.5625rem;
    border-bottom-right-radius: 0rem !important;
  }
  .anfragenCard .card__leftSide {
    width: -moz-fit-content;
    width: 100%;
    height: calc(100% - 29.375rem);
  }
  .card__rightSideDashboard {
    -webkit-clip-path: none !important;
    clip-path: none !important;
  }
  .anfragen__button {
    border-radius: 4.5625rem;
    font-size: 3rem;
    width: 21.5438rem;
    height: 7rem;
    margin-top: 2.6125rem;
    margin-top: 1.625rem;
  }
  .styleLeftSide {
    padding: 1.8125rem 0rem 0.625rem 1rem;
  }
  .anfragen__bids {
    font-size: 2.2rem;
    line-height: 1.7;
  }
  .anfragenCard__bottom button {
    display: none;
  }
  .anfragenCard__bottom .timeCounter {
    font-size: 2.125rem;
  }
  .anfragenCard__bottom {
    justify-content: center;
    margin-top: 1.625rem;
  }
  .scheduler-view,
  #RBS-Scheduler-root {
    width: 90vw !important;
  }
  .header2-text {
    font-size: 3.3rem;
  }
  .section__calendar {
    padding-left: 1rem;
  }
  .header3-text {
    font-size: 2.3rem;
  }
  .header2-text {
    font-size: 3.3rem;
  }
  /*VEHICLE PARK*/
  .fuhrpark__content {
    width: 100% !important;
    height: calc(100vh - 25rem) !important;
    padding-left: 6.5rem;
    padding-right: 6.5rem;
  }
  .section__top {
    flex-direction: column;
  }
  .section__top .blueButton {
    align-self: flex-end;
  }
  .cardFuhrpark {
    flex-direction: column-reverse;
    min-width: 100% !important;
    width: 100% !important;
    height: 52.375rem !important;
    border-radius: 4.5625rem;
    margin-left: 0rem !important;
  }
  .orders__slider {
    padding: 0rem 4.2rem 4rem 4.2rem;
  }
  .section__bottom {
    padding: 0px 5rem;
    flex-direction: column;
  }
  .section__top .sectionTop__paragraph {
    font-size: 3rem !important;
  }
  .card__rightSideFuhrpark {
    width: 100%;
    clip-path: none !important;
    height: 33rem;
    border-top-right-radius: 4.5625rem;
    border-top-left-radius: 4.5625rem;
    border-bottom-right-radius: 0rem;
  }
  .card__leftSideFuhrpark {
    margin-right: 0rem;
    height: calc(100% - 33rem);
  }
  .fuhrparkCardStyle {
    display: flex;
    flex-direction: column;
    padding-top: 2.75rem;
  }
  .fuhrparkCard__name {
    font-size: 3.7rem !important;
    text-overflow: unset !important;
    overflow: unset !important;
    width: 100% !important;
    padding-left: 4rem;
  }
  .fuhrparkCard__year {
    font-size: 2.7rem !important;
    margin-bottom: 1.9375rem !important;
    padding-left: 4rem;
  }
  .fuhrparkCard__details {
    font-size: 3.5rem !important;
  }

  /*OFFERS*/
  .pendingOffers__content {
    width: 100% !important;
    height: fit-content !important;
    border-radius: 4.25rem !important;
  }
  .pendingOffers__button {
    width: 100% !important;
    height: 6.438rem !important;
    border-bottom-left-radius: 4.25rem !important;
    border-bottom-right-radius: 4.25rem !important;
    font-size: 2.5rem !important;
  }
  .pendingOffers__name {
    font-size: 2.125rem !important;
  }
  .section__pendingOffers .slick-list {
    padding: 0px !important;
  }
}
@media only screen and (max-width: 434px) {
  /*RATINGS*/
  .reviews__content .content__header h2 {
    font-size: 4rem !important;
  }
  .reviewContent__section--heading2 {
    width: 64.875rem !important;
    padding-left: 4.5rem;
  }
  .reviewContent__section--heading {
    width: 41.563rem !important;
    padding-left: 4.5rem;
  }
}
@media only screen and (max-width: 380px) {
  /*RATINGS*/
  .reviews__content .content__header h2 {
    font-size: 3.5rem !important;
  }
  .reviewContent__section--heading2 {
    width: 60.875rem !important;
    padding-left: 4.5rem;
  }
  .reviewContent__section--heading {
    width: 45.563rem !important;
    padding-left: 4.5rem;
  }
}
@media only screen and (max-width: 370px) {
  /*RATINGS*/
  .reviewContent__section--heading2 {
    width: 59.875rem !important;
    padding-left: 3.5rem;
  }
  .reviewContent__section--heading {
    width: 40.563rem !important;
    padding-left: 3.5rem;
  }
}
@media only screen and (max-width: 355px) {
  /*RATINGS*/
  .reviewContent__section_numbers {
    padding: 0.95rem 1.8rem !important;
    font-size: 2.2rem !important;
  }
  .reviewContent__section--heading2 {
    width: 57.875rem !important;
  }
}
@media only screen and (max-width: 340px) {
  /*RATINGS*/
  .reviewContent__section--heading2 {
    width: 55.875rem !important;
  }
  .reviewContent__section--heading {
    width: 37.563rem !important;
  }
}
