.blueButton {
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 0.625rem;
  background-color: #06016c;
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  margin: 3.125rem auto 0rem auto;
  border: none;
  cursor: pointer;
  align-items: center;
  user-select: none;
}
