/*vertical slider*/
.shipmentViewShipper__listOfBids .slick-slider {
  width: 100%;
}
.shipmentViewShipper__listOfBids .slick-vertical .slick-slide {
  width: 100% !important;
}
.shipmentViewShipper__listOfBids .slick-slider .slick-track {
  padding: 0rem 3.125rem;
}
.shipmentViewShipper__paragraph {
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.33;
  color: #06016c;
  margin-top: 1.125rem;
  margin-bottom: 1.875rem;
}
.shipmentViewShipper__paragraph--exep {
  margin-bottom: 2.813rem;
}
.shipmentViewShipper__listOfBids ~ div div div div {
  height: fit-content;
  padding: 1.5rem 2.5rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
@media only screen and (max-width:1148px){
  .shipmentViewShipper__listOfBids ~ div div div div {
    font-size: 2.125rem;
  }
}
@media only screen and (max-width:690px){
  .shipmentViewShipper__listOfBids ~ div div div div {
    padding: 4.5rem 2.5rem;
  }
}
@media only screen and (max-width:500px){
  .shipper__acceptRejectedBid--heading, .shipper__acceptedBid--heading, .shipper__rejectedBid--heading{
    border-top-left-radius: 4.25rem;
    border-top-right-radius: 4.25rem;
    height: 6rem;
    padding-left: 4.419rem;
    padding-right: 4.394rem;
  }
  .shipper__acceptRejectedBid, .shipper__acceptedBid, .shipper__rejectedBid {
    height: 53rem;
    border-radius: 4.25rem;
}
.shipper__header-left {
  font-size: 2.75rem;
}
.shipper__header-right {
  font-size: 1.75rem;
}
.shipper__acceptRejectedBid--content, .shipper__acceptedBid--content, .shipper__rejectedBid--content{
  height: calc(100% - 6rem);
}
.acceptRejectedBid__contentRightMessage, .acceptedBid__contentRightMessage, .rejectedBid__contentRightMessage{
  height: 28.25rem;
  font-size: 3rem;
}
.acceptRejectedBid__contentRightMessage > div, .acceptedBid__contentRightMessage > div, .rejectedBid__contentRightMessage > div{
  height: 24.5rem;
}
.acceptRejectedBid__contentLeft .CircularProgressbar, .rejectedBid__contentLeft .CircularProgressbar,.acceptedBid__contentLeft .CircularProgressbar{
  width: 14.375rem !important;
  height: 14.375rem !important;
}
.acceptRejectedBid__user, .acceptedBid__user, .rejectedBid__user {
  margin-top: 2.094rem;
  font-size: 2.25rem;
}
.chatButton {
  width: 18rem;
  height: 4.188rem;
  font-size: 1.75rem;
}
.acceptRejectedBid__dates, .acceptedBid__dates, .rejectedBid__dates  {
  font-size: 2rem;
  margin-bottom: 2.969rem;
}
.acceptRejectedBid__dates label, .acceptedBid__dates label, .rejectedBid__dates label {
  font-size: 2.5rem;
}
.acceptRejectedBid__button--accept, .acceptRejectedBid__button-reject, .acceptedBid__button, .rejectedBid__button {
  width: 18.5rem;
  height: 6.5rem;
  border-radius: 1.625rem;
  font-size: 2.2rem;
}
.circularProgressbar__value{
  display: none;
}
.shipmentViewShipper__listOfBids ~ div > div > div:nth-of-type(1) {
  width: 75%!important;
}
.shipmentViewShipper__listOfBids ~ div div div div {
  font-size: 3.125rem;
}
}
@media only screen and (max-width:410px){
  .acceptRejectedBid__contentRight, .acceptedBid__contentRight, .rejectedBid__contentRight {
    width: 62%;
}
.acceptRejectedBid__button--accept, .acceptRejectedBid__button-reject, .acceptedBid__button, .rejectedBid__button {
  width: 16.5rem;
}
}
@media only screen and (max-width:350px){
.acceptRejectedBid__button--accept, .acceptRejectedBid__button-reject, .acceptedBid__button, .rejectedBid__button {
  width: 13.5rem;
}
.acceptRejectedBid__dates label, .acceptedBid__dates label, .rejectedBid__dates label {
  font-size: 2.1rem;
}
.shipmentViewShipper__listOfBids .slick-slider .slick-track {
  padding: 0rem 1.125rem;
}
}
@media only screen and (max-width:343px){
  .chatButton {
    width: 16rem;
    font-size: 1.5rem;
}
}