.background {
    position: fixed;
    width: 100vw;
    background:rgba(6, 1, 108,0.8);
    height: 100vh;
    z-index: -9;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    -moz-filter:blur(18px);
  }