.group-textarea {
  position: relative;
  z-index: 999;
  background-color: white;
  border-radius: 1.563rem;
  width: fit-content;
  height: fit-content;
  width: 100%;
  margin-bottom: 1.25rem; /* 20px */
}
.form-textareaInput {
  width: 100%;
  border-radius: 1.563rem;
  border: solid 2px #7977a4;
  background-color: transparent;
  padding-left: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
  height: 7.5rem; /* 120px */
  padding: 0.688rem 1.25rem 0.5rem 1.063rem;
  resize: none;
}
.form-textareaInput:focus {
  border: 2px solid #2699fb !important;
}

.form-textareaLabel {
  font-family: SF Pro Display Regular;
  color: #06016c;
  position: absolute;
  top: 1.12rem;
  font-size: 1.125rem;
  left: 1.5rem;
  display: block;
  z-index: -1;
  line-height: 0.89;
  transition: all 0.15s ease-in-out 0s;
}
.form-textareaInput:focus ~ .form-textareaLabel,
.form-textareaInput:valid ~ .form-textareaLabel {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
}
