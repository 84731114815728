/* .active{
    background-color: #06016c;
    color:#06016c;
} */
.updatedResultsForm{
  height: calc(100vh - 14rem)!important;
}
.resultsIconHolder div {
  cursor: pointer;
}
.resultsIconHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.resultsIconHolder svg {
  width: 50px;
  height: auto;
  cursor: pointer;
}

.resultsIconHolder div:nth-last-of-type(1) svg {
  width: 50px;
  height: 25px;
}

.resultsIconHolder div:nth-last-of-type(2) svg {
  width: 50px;
  height: 25px;
}

.resultsIconHolder div:nth-last-of-type(3) svg {
  width: 50px;
  height: 25px;
}

.order__contentPublic {
  width: 100%;
  height: calc(100vh - 115.59px);
}

.addTruckPublic {
  height: 100vh;
  top: 0px;
}

.results .blueButton {
  margin-top: 7rem !important;
}

.truckDetailsPublic {
  height: 100vh;
  padding-top: 8.125rem;
}

.results__slider .shipmentsCard {
  margin-bottom: 3.9rem !important;
}
@media only screen and (max-width:990px){
  .resultsFilter .inputHolder{
    top:60px;
  }
}
@media only screen and (max-width:700px){
  .resultsFilter .inputHolder:nth-child(1),  .resultsFilter  .inputHolder:nth-child(2){
    left:0px!important;
  }
  .addTruck, .truckDetails {
    width: 49.25rem!important;
}
}
@media screen and (max-width: 650px){
  .resultsFilter .inputHolder:nth-child(1),.resultsFilter .inputHolder:nth-child(2) {
      width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .addTruck, .truckDetails {
    width: 100%!important;
}
  .results .orders__content {
    padding-top: 0px;
  }
  .results .content__header {
    display: none;
  }
  .results .orders__section--paragraph {
    display: none;
  }
  .results__nav{
    display:none;
  }
  .addTruckPublic{
    height: calc(100vh - 12.5rem) !important;
  }
  .addTruckSpecial {
    top: 12.5rem;
    overflow-y: scroll;
    width: 70%;
  }
  .filterSidebar {
    display: none;
  }
  .results .fuhrpark__content-blur {
    display: none;
  }
  .results .inputHolder:nth-child(1),
  .results .inputHolder:nth-child(2) {
    left: 0.7% !important;
  }
  .results .searchFilterHolder .inputHolder input {
    padding: 12px 14px;
    font-size: 3rem;
  }
  .results .searchFilterHolder .inputHolder input::placeholder {
    font-size: 3rem;
  }
  .results .searchFilterHolder .inputHolder span {
    font-size: 3rem;
  }
  .results .updatedResultsForm > span {
    font-size: 3rem;
  }
  .results .resultsIconHolder div span {
    font-size: 2rem;
  }
  .results #output {
    font-size: 2rem;
  }

  .results__slider .shipmentsCard {
    width: 100%;
    border-radius: 4.25rem;
    margin-bottom: 0px !important;
  }
  .results__slider .shipmentsCard__card {
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
  }
  .results__slider .shipmentsCard__top {
    border-top-left-radius: 0rem;
    border-top-right-radius: 4.25rem;
    border-bottom-right-radius: 4.25rem;
    height: 22.063rem;
    width: 45%;
  }
  .results__slider .shipmentsCard__button {
    display: none;
  }
  .results__slider .shipmentsCard__bottom {
    border-top-left-radius: 4.25rem;
    border-bottom-left-radius: 4.25rem;
    width: 65%;
  }
  .results__slider .shipmentsCard__carType {
    margin-top: 1.569rem;
  }
  .results .DateInput_input {
    width: 100%!important;
}
}
@media only screen and (max-width:430px){
  .addTruckSpecial {
    width: 83%;
}
}
@media only screen and (max-width:320px){
  .addTruckSpecial {
    width: 85%;
}
}