.loginNew .row:nth-last-child(2) {
  margin-top: 20px;
}
.loginPopUp__container::placeholder {
  font-size: 1.1rem !important;
}
.loginPopUp__form--submit {
  font-size: 13px !important;
}
.login__forgetPassword {
  font-family: "SF Pro Display Regular", sans-serif;
  font-weight: 100;
  font-size: 16px;
  margin-top: 10px;
  text-align: end;
  padding-right: 14px;
}
.login__forgetPassword a {
  text-decoration: none;
  color: #06016c;
}
.row{
  position: relative;
}
@media only screen and (max-width:1585px){
  .loginPopUp__button {
    background-color: #06016c !important;
    width: 209px !important;
    height: 49px !important;
}
.loginPopUp__button span {
  font-size: 15px;
}
}
@media only screen and (max-width:1195px){
  .login__forgetPassword {
    font-size: 2rem;
  }
  .registration form input, .registration form input::placehodler {
    font-size: 2.5rem !important;
}
.loginPopUp__button span {
  font-size: 16px!important;
}
}
@media only screen and (max-width:994px){
  .login__forgetPassword {
    font-size: 1.8rem!important;
  }
.registration form input::placeholder{
  font-size: 1.7rem;
}
}
@media only screen and (max-width:765px){
.login__forgetPassword {
  font-size: 2.5rem!important;
}
.content h2:nth-last-of-type(1) {
  width: 100%!important;
}
}
@media only screen and (max-width:520px){
.loginPopUp__container .loginPopUp__holder input::placeholder {
  font-size: 3.1rem !important;
}
.footer{
  display: none;
}
 .content h1 {
  font-size: 29px;
}
.login .content h2:nth-last-of-type(1) {
  width: 82%!important;
}
.login__forgetPassword {
  font-size: 3rem!important;
}
.registration button[type="submit"], .secondMiddle__button {
  font-size: 3.2rem;
}
.registration form input::placeholder{
  font-size: 3rem!important;
}
}
@media only screen and (max-width:370px){
  .loginPopUp__button span {
    font-size: 15px!important;
}
.loginPopUp__button span {
  margin-right: 6.6px;
}
}
@media only screen and (max-width:335px){
  .register__googleFbButton .loginPopUp__button{
    padding: 0 2rem;
  }
}