.dashboardHeader {
  box-shadow: /*0 1.25rem 2.5rem 0*/ 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  background-color: #ffffff;
  width: 100%;
  height: 7.5rem /*120px*/;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 999999;
}
.header__logo img {
  width: /*176px*/ 11rem;
  height: /*36px*/ 2.25rem;
}
.header__leftSide {
  margin-left: 3.9375rem; /* 63px */
}
.header__rightSide {
  margin-right: 8.0938rem; /* 129.5px */
  display: flex;
  align-items: center;
}
.header__rightSide svg {
  width: 2.277rem;
  height: 2.344rem;
}
.header__rightSide input {
  width: 21.875rem;
  height: 3.125rem /*50px*/;
  border-radius: 1.25rem;
  background-color: rgba(6, 1, 108, 0.05) !important;
  display: flex;
  align-items: center;
  font-family: SF Pro Display Regular;
  padding-left: 4.1875rem /*67px*/;
  font-size: 1.25rem /*20px*/;
  /* margin-left: 2.706rem; */
  color: #06016c;
  border: none;
}
.header__rightSide input::placeholder {
  font-size: 1.125rem /*18px*/;
  font-family: SF Pro Display Regular;
  color: #7977a4;
}
.searchBox__none,
.header__rightSide input:focus {
  background-image: none !important;
  padding-left: 1.25rem /* 20px */ !important;
}
.header__rightSide input:focus::placeholder {
  opacity: 0;
}
.searchBox {
  background-image: url("../../../assets/images/HeaderIcons/search.png") !important;
  background-repeat: no-repeat !important;
  background-position-y: center !important;
  background-position-x: 0.9563rem !important; /* 15.3px */
  background-size: 1.8rem !important;
}
.notification {
  margin-left: 2.194rem;
  position: relative;
}
.notification .shipmentsContent__notifications {
  position: absolute;
  top: 3rem;
  left: -9rem;
  height: 27rem;
  background-color: #fff;
  padding: 2.313rem 1.656rem 1.719rem 2rem;
  width: 21.875rem;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(0, 0, 0, 0.25);
}
.notificationBell {
  width: 1.761rem;
  height: 2.406rem;
  cursor: pointer;
}
.notificationHeader__content {
  overflow-y: scroll;
  height: 19rem;
}

.hideNotification {
  display: none;
}

.header__searchResults {
  list-style: none;
  font-family: SF Pro Display Regular;
  font-size: 1.1rem;
  color: #06016c;
  text-align: center;
  position: absolute;
  width: 21.875rem;
  height: fit-content;
  background-color: white;
  border-radius: 1.5rem;
  overflow-y: scroll;
  box-shadow: 0 1.25rem 2.5rem 0 rgb(110 104 104 / 25%);
  padding-top: 1rem;
  padding-bottom: 1rem;
  top: 3.3rem;
  max-height: 27rem;
}

.header__searchResults li {
  padding: 1rem 0rem;
  border-radius: 1.5rem;
  margin-bottom: 0.5rem;
}
.searchResults__nothingFound{
  font-family: SFProDisplay-Bold;
  color:#9895b8;
}
.notificationDot{
  background-color: red;
  border-radius: 99999px;
  cursor: pointer;
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 99999px;
  top: -0.3rem;
  right: -0.5rem;
  border: 2px solid white;
}