.reviews__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 3.9375rem; /* 63px */
  padding-left: 5.5rem; /* 88px */
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: scroll;
}
.reviewContent__section--heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 28.563rem;
}
.reviewContent__section--heading2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 37.875rem;
}
.reviewContent__section--inner {
  display: flex;
}
.reviewContent__section_numbers {
  border-radius: 9999px;
  padding: 0.75rem 1.188rem;
  font-family: SFProDisplay-Bold;
  font-size: 1.125rem;
  line-height: 1.33;
}
.reviewContent__section_numOfReviews {
  border: solid 3px #7426fb;
  color: #7426fb;
}
.reviewContent__section_numOfPositive {
  border: solid 3px #37e677;
  margin-right: 0.813rem;
  color: #37e677;
}
.reviewContent__section_numOfAverage {
  border: solid 3px #e6bd37;
  margin-right: 0.813rem;
  color: #e6bd37;
}
.reviewContent__section_numOfNegative {
  border: solid 3px #e63737;
  color: #e63737;
}

.lineWidth {
  width: 12.563rem;
}

.section__leaveReviews {
  overflow-x: hidden;
}
.section__leaveReviews .slick-list {
  padding-top: 60px;
  padding-bottom: 89px;
  padding-left: 40px;
}
.section__recievedReviews,
.section__leftReviews {
  overflow-x: hidden;
}
.section__recievedReviews .slick-list,
.section__leftReviews .slick-list {
  padding-top: 40px;
  padding-bottom: 79px;
  padding-left: 40px;
}
.section__recievedReviews .slick-slider,
.section__leftReviews .slick-slider {
  width: 119.6875rem;
}
.reviews__rightSide--shadow {
  width: 0%;
  z-index: 9999;
  position: absolute;
  top: 6.25rem; /* 100px */
  bottom: 0rem; /* 0px */
  right: 0rem; /* 70px */
  background: transparent;
  /* box-shadow: inset -179px 1px 256px 85px rgb(228, 228, 243); */
  box-shadow: -1rem -1rem 8rem 9.625rem rgb(242, 242, 248);
  /* box-shadow: -95px 6px 138px 16px rgb(242, 242, 248); */
}

@media only screen and (min-width: 2021px) {
  .slick-slider {
    /* margin: 0px auto; */
    width: 121.125rem;
  }
  .reviewContent__section--heading {
    margin-left: 64px;
  }
  .reviewContent__section--heading2 {
    margin-left: 64px;
  }
}

@media only screen and (max-width:500px){
  .reviewContent__section .shipmentView__paragraph{
    padding-left: 5.5rem;
  }
}