.givBid {
  padding: 6.5rem 8.563rem 0rem 7.688rem;
  color: #06016c;
  font-family: SF Pro Display Regular;
  width: 41.25rem; /* 660px */
  height: 100%;
  box-shadow: 20px 0 40px 0 #06016c;
  position: relative;
}
.givBid__content{
  height: calc(100vh - 40%);
  overflow-y: scroll;
  overflow-x: hidden;
}
.givBid__content--heading {
  font-family: SFProDisplay-Bold;
  font-size: 2.25rem;
  line-height: 1.33;
  margin-bottom: 5px;
}
.givBid__content--paragraph {
  font-size: 1.25rem;
  line-height: 1.35;
  margin-bottom: 1rem;
}
.givBid__form .form-input,
.givBid__form .group {
  width: 100%;
}
.givBid__form .form-textareaInput {
  height: 11.25rem;
}
.form__inputField {
  margin-bottom: 0.5rem;
  margin-top: 2.125rem;
  position: relative;
}
.form__inputField p {
  font-family: SFProDisplay-Bold;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #7977a4;
  margin-top: 0.188rem;
  margin-left: 0.3rem;
}
.form__inputField p label {
  color: #2699fb !important;
}
.givBid__form .dropdownmaker__select {
  width: 100% !important;
  margin-top: 0px;
}
.givBid__form .dropdownmaker__label {
  line-height: 0.4;
}
.givBid__form .dropdownmaker__select:focus ~ .dropdownmaker__label {
  top: -1.45em;
}

.fromInput__description {
  height: 11.25rem;
}
.givBidAnimated {
  position: absolute;
  z-index: 99999999;
  right: 0rem;
  top: 0rem;
  bottom: 0rem;
  height: 100%;
  background-color: white;
}
.givBid__content .blueButton {
  margin: 3.375rem auto 0rem auto;
}
.react-datepicker-popper {
  z-index: 9999999;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container input {
  width: 100%;
  height: 3.125rem;
  border-radius: 1.563rem;
  border: solid 2px #7977a4;
  background-color: transparent;
  padding-left: 1.25rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}
.react-datepicker__input-container input:focus {
  border: 2px solid #2699fb !important;
}
.react-datepicker__input-container input:focus ~ .givBid__labelClass,
.react-datepicker__input-container input:valid ~ .givBid__labelClass {
  top: -1.5em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
}

.givBid__dateLabel {
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #06016c;
}

.givBid__labelClass {
  line-height: 1.31 !important;
  color: #06016c;
  position: absolute;
  top: 0.9rem;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  left: 1.5rem;
  display: block;
  z-index: -1;
}
.givBid__labelClass--exp {
  top: -1.5em;
  left: 0rem;
  color: #7977a4;
  cursor: pointer;
  position: absolute;
}
.givBid__borderStyle {
  /* border: solid 2px rgba(121, 119, 164, 0.5) !important; */
  font-size: 1rem !important;
  line-height: 1.31 !important;
  color: #06016c !important;
  border-radius: 25px !important;
  padding: 0rem 1.25rem 0rem 1.25rem !important;
  /* background-color: transparent; */
  background-color: rgba(6, 1, 108, 0.03) !important;
  border: none !important;
}
.givBid .customBackButton {
  bottom: 10.5rem;
}
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: white;
}
.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  color: #000;
  border: 1px solid #e1e1e1;
}

.react-datepicker__day {
  color: #666262;
  display: inline-block;
  width: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  margin: 0px;
  border: 1px solid #e1e1e1;
}
.react-datepicker__day-name {
  width: 2.2rem;
  line-height: 2.2rem;
  color: #666262;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__day--selected {
  border-radius: 0px;
  color: #fff;
  background-color: #06016c;
}
.react-datepicker__month {
  margin: 1rem;
}
.react-datepicker__header {
  padding-top: 19px;
}
.react-datepicker__navigation {
  top: 23px;
}
.react-datepicker__current-month {
  margin-bottom: 10px;
  color: #2e2d2d;
  font-size: 1.3rem;
}

.react-datepicker__day:hover {
  border-radius: 0px;
  background-color: #b1afd1;
  color: white;
}
.givBid__form .css-2b097c-container {
  width: 100%;
}


@media only screen and (max-width:500px){
  .givBid{
    width: 100%;
  }

  .givBidAnimated{
    width: 100%;
  }
  .givBid__content--heading {
    font-size: 7.25rem;
    margin-bottom: 11px;
}
.givBid__content--paragraph {
  font-size: 3.25rem;
  margin-bottom: 3rem;
}
.form__inputField p {
  font-family: SFProDisplay-Bold;
  font-size: 1.75rem;
}

  .givBid__labelClass{
    font-size: 3.125rem !important;
      left: 3rem !important;
      line-height: 1.89 !important;
  }
  .deliveryDate .form-label {
    line-height: 2.5 !important;
}
.givBid .form__inputField {
  margin-top: 7.125rem;
}
.givBid__labelClass--exp{
    font-size: 3.125rem;
}
.react-datepicker__input-container input {
  height: 8.125rem;
  border-radius: 4.563rem;
}

/*DATE PICKER*/
.react-datepicker__day {
  width: 5.5rem;
  line-height: 5.5rem;
}
.react-datepicker {
  font-size: 2.5rem;
}
.react-datepicker__day-name {
  width: 5.2rem;
  line-height: 5.2rem;
}
.react-datepicker__current-month {
  font-size: 3rem;
}
.react-datepicker__navigation {
  height: 16px;
  width: 15px;
}
.givBid__borderStyle {
  font-size: 3rem !important;
  padding: 0rem 1.25rem 0rem 2.25rem !important;
}
}
@media only screen and (max-height:825px){
  .givBid__content{
    height: 76vh;
  }
}
@media only screen and (max-height:736px){
  .givBid__content{
    height: 84vh;
  }
}
@media only screen and (max-height:667px){
  .givBid__content{
    height: 92vh;
  }
}
@media only screen and (max-height:568px){
  .givBid__content{
    height: 88vh;
  }
}