.mobileHeader {
  box-shadow: /*0 1.25rem 2.5rem 0*/ 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  background-color: #ffffff;
  width: 100%;
  height: 12.5rem;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 999999;
  display: none;
}

.mobileHeader .header__logo img {
  width: 30rem;
  height: 5.25rem;
}
.mobileHeader .header__rightSide {
  margin-right: 3.0938rem;
  justify-content: center;
}
.mobileHeader .header__rightSide svg {
  width: 9.277rem;
  height: 5.2rem;
}

.header__profile {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 9999px;
  background-color: rgb(177, 174, 174);
  margin-left: 4rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width:395px){
  .mobileHeader .header__rightSide svg {
    width: 5.277rem;
  }
  .mobileHeader .header__rightSide{
    justify-content: flex-end;
  }
}