.chatlist-root button{
    display: none;
}
.chatlist-mainlist{
    margin-top:0px;
}
@media only screen and (max-width:500px){
    .messenger{
        height:calc(100vh - 12.5rem)!important
    }
    .chatview-content {
        height: calc(100vh - 35rem);
        overflow: auto;
        padding: 25px;
        margin-left: 0px;
        box-sizing: border-box;
        overflow-y: scroll;
        top: 100px;
        width: 100%;
        position: unset;
        padding-bottom: 40px;
        background: white;
    }
    .chatlist-root {
        height: calc(100vh - 25rem);
        width: 100%;
    }
    .chatContainer {
        position: absolute;
        bottom: 13.5rem;
        left: 19px;
        box-sizing: border-box;
        overflow: auto;
        width: 92%;
        display: flex;
        align-items: center;
    }
    .chatlist-mainlist li {
        border-bottom: 1px solid #e3e3e3;
    }
    .inputSendMessage {
        font-size: 20px;
        padding: 5px 20px;
    }
    .inputSendMessage::placeholder{
        font-size: 16px;
        color:rgb(190, 188, 188);
    }
    .userSent, .friendSent {
        padding: 12px 14px;
        min-width: 65px;
        font-size: 3rem;
    }
}
