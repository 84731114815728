* {
  margin: 0rem; /* 0px */
  padding: 0rem; /* 0px */
  box-sizing: border-box;
}

.listPlaces {
  background-color: white;
  opacity: 0.95;
  font-family: SF Pro Display Regular;
  border-radius: 0.3125rem; /* 5px */
  position: absolute;
  z-index: 999999;
  width: 100%;
  font-size: 1.2rem;
  color: #06016c;
}
.listPlaces li {
  border-bottom: 1px solid lightgrey;
  padding: 0.7rem; /* 7px */
  padding-left: 2.5rem; /* 12px */
  list-style: none;
}
.listPlaces li:nth-child(1) {
  padding-top: 0.75rem; /* 12px */
}
.listPlaces li:nth-last-child(1) {
  padding-bottom: 0.75rem; /* 12px */
  border-bottom: none;
}
.listPlaces li:hover {
  cursor: pointer;
  background-color: rgb(233, 225, 225);
}

@media only screen and (max-width: 700px) {
  .listPlaces {
    opacity: 0.99;
  }
}
