.shipperProfile__content {
  width: calc(100% - 25rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding: 4.938rem 13.125rem 12.688rem 4.938rem;
  position: relative;
  height: calc(100vh - 7.5rem);
  overflow-y: scroll;
  overflow-x: hidden;
}
.shipperProfile__contentPublic {
  width: 100%;
}
.shipperProfile__main {
  display: flex;
}
.shipperProfile__section1 {
  width: 100%;
  display: flex;
  margin-bottom: 128px;
}
.shipperProfile__section1 .section1__left {
  width: /*31.875rem*/ 41.43%;
  height: 24.063rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.shipperProfile__section1 .section1__right {
  width:/*49.5rem*/ 58.57%;
  padding-top: 3.063rem;
  display: flex;
  flex-direction: column;
}
.section1Right__shipperInformation {
  width:/*49.5rem*/ 100%;
  height: 17.813rem;
  margin-bottom: 1rem;
  padding: 1.25rem 1.438rem 3rem 2.875rem;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1);
  background-color: #ffffff;
}
.shipperInformation__member {
  font-family: SF Pro Display Regular;
  font-size: 0.875rem;
  line-height: 1.36;
  color: #7977a4;
  text-align: end;
}
.shipperInformation__member span {
  font-family: SFProDisplay-Bold;
  letter-spacing: 0.088rem;
  color: #2699fb;
  text-transform: uppercase;
}
.shipperInformation__name {
  font-family: SFProDisplay-Bold;
  font-size: 1.875rem;
  line-height: 1.33;
  color: #06016c;
  margin-bottom: 1.5rem;
}
.shipperInformation__check {
  margin-left: 0.3rem;
  width: 1.125rem;
  height: 1.125rem;
}
.shipperInformation__description {
  font-family: SF Pro Display Regular;
  font-size: 1.25rem;
  line-height: 1.75;
  color: #06016c;
}
.shipperProfile__messageButton {
  align-self: flex-end;
}
.shipperProfile__chat {
  width: 1.269rem;
  height: 1.313rem;
  margin-left: 0.831rem;
}
.shipperProfile__section2 {
  width: 92%;
  position: relative;
  height: 11.956rem;
  margin-bottom: 3.794rem;
  padding-left: 5.556rem;
}
.shipperProfile__section2 .pendingPaymentsCard {
  background-color: white;
}
.shipperProfile__section2 .section2__truckList {
  background-color: rgb(242, 242, 248);
  width: 92.5%;
  height: 9.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 3.881rem;
  position: absolute;
  z-index: 9;
  padding-left: 1.5rem;
}
.shipperProfile__section2 .section2__truckList .section2__slide {
  align-self: flex-start;
  margin-top: -50px;
}
.shipperProfile__section2 .section2__truckList--paragraph {
  width: 16.688rem;
  font-family: SF Pro Display Regular;
  font-size: 1.563rem;
  line-height: 1.52;
  color: #06016c;
  background-color: rgb(242, 242, 248);
}
.shipperProfile__section2 .section2__slide .slick-slider {
  width: 28rem;
  display: flex;
  height: 23.375rem;
}
.shipperProfile__section2 .section2__slide .slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  width: 20.688rem !important;
  background-color: rgb(242, 242, 248);
}
.shipperProfile__section2 .section2__slide .slick-slider .slick-list {
  width: 20.688rem !important;
}
.shipperProfile__section2 .section2__slide .slick-arrow {
  background-color: transparent;
  border: none;
  color: rgba(6, 1, 108, 0);
  margin-top: 9.5rem;
  width: 1.213rem;
  height: 2.038rem;
  cursor: pointer;
  position: unset;
}

.shipperProfile__section2 .section2__slide .slick-prev {
  background: url("../../assets/images/leftArrow.svg");
  background-repeat: no-repeat;
  background-size: 1.213rem;
}
.shipperProfile__section2 .section2__slide .slick-next {
  background: url("../../assets/images/rightArrow.svg");
  background-repeat: no-repeat;
  background-size: 1.213rem;
}
.shipperProfile__section2 .section2__slide .card {
  width: 21.875rem;
  min-width: 0rem;
}

.shipperProfile__section2 .section2__truckList--paragraph span {
  font-size: 1.875rem;
  font-family: SFProDisplay-Bold;
  color: #2699fb;
}
.shipperProfile__section2 .section2__dashedContainer {
  width: 75.5%;
  height: 6.738rem;
  position: absolute;
  top: 5.219rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232699FB6B' stroke-width='4' stroke-dasharray='9%2c 19' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.shipperProfile__section--heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 19.563rem;
}
.shipperProfile__section3 {
  margin-bottom: 3.875rem;
}
.shipperProfile__section3 .section3__group {
  display: flex;
  justify-content: space-between;
  margin-top: 3.063rem;
  padding: 0rem 3.688rem;
}
.shipperProfile__section3 .section3__percentages {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.shipperProfile__section3 .percentages__group .slider {
  -webkit-appearance: none;
  outline: none;
  width: 37.5rem;
  height: 0.369rem;
  border-radius: 10px;
  margin-bottom: 2.3rem;
}
.shipperProfile__section3 .percentages__group .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.percentages__group .slider:focus {
  outline: none;
}
.shipperProfile__section3 .percentages__group .slider::-webkit-slider-thumb {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  border: solid 1px #ffffff;
  background-color: #7426fb;
  border-radius: 50%;
}

.shipperProfile__section3 .percentages__group {
  /* display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.5rem;
    margin-bottom: 1.688rem; */
  width: min-content;
}
.shipperProfile__section3 .percentages__group:nth-last-child(1) {
  margin-bottom: 0rem;
}
.shipperProfile__section3 .percentages__group--paragraph {
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #7977a4;
  margin-bottom: 1.688rem;
}
.shipperProfile__section3 .percentages__group--votes {
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #7977a4;
  min-width: 5.75rem;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1.313rem;
}
.shipperProfile__section3 .percentages__group--votes:nth-last-child(1) {
  margin-bottom: 0rem;
}
.shipperProfile__section3 .percentages__group--votes span {
  font-family: SFProDisplay-Bold;
  font-size: 1.375rem;
  line-height: 1.36;
  color: #2699fb;
}
.shipperProfile__section3 .section3__satisfaction--num {
  font-family: SFProDisplay-Bold;
  font-size: 3.938rem;
  line-height: 1.33;
  color: #2699fb;
}
.shipperProfile__section3 .section3__satisfaction--num span {
  color: #06016c;
  font-family: SF Pro Display Regular;
}
.shipperProfile__section3 .section3__satisfaction--text {
  font-family: SF Pro Display Light;
  font-size: 1.75rem;
  line-height: 1.32;
  color: #7977a4;
}
.shipperProfile__section2Mobile{
  display: none;
}
/*vertical slider*/
.shipperProfile__section4 .slick-slider {
  width: 100%;
}
.shipperProfile__section4 .slick-vertical .slick-slide {
  width: 100% !important;
}
.shipperProfile__section4 .slick-slider .slick-track {
  padding: 0rem 3.125rem;
}
.shipperProfile .pendingPaymentsCard__price{
  display: none;
}
.section3__satisfaction{
  display: flex;
    flex-direction: column;
    align-items: center;
}
@media only screen and (max-width:960px){
  .shipperProfile__section3 .section3__group{
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width:500px){
  .shipperProfile__content{
    width: 100%;
    height: 100vh;
  }
  .shipperProfile__section1{
    flex-direction: column;
    align-items: center;
  }
  .shipperProfile__section1 .section1__right{
    padding-top: 0rem;
    width: 100%;
  }
  .shipperInformation__name{
    font-size: 3.5rem;
    display: flex;
    align-items: center;
  }
  .companyInformation__check {
    margin-left: 1.3rem;
    width: 3.125rem;
    height: 3.125rem;
}
.shipperInformation__description{
  font-size: 2.25rem;
}
.shipperInformation__member{
  font-size: 1.875rem;
}
.shipperProfile__section1 .section1__left {
  width: 52.43%;
  height: 36.063rem;
  border-top-right-radius: 0.625rem;
  border-bottom-left-radius: 0rem;
}
.shipperProfile__content {
  padding: 6.938rem;
}
.shipperProfile__section2{
  display: none;
}
.shipperProfile__section2Mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section2__truckList--paragraph {
  width: fit-content;
  font-size: 2.563rem;
  margin-bottom: 2rem;
}
.section2__truckList--paragraph span {
  font-size: 2.875rem;
}

.shipperProfile__section2Mobile .slick-slider {
  width: 100vw !important;
  height: fit-content;
}
.shipperProfile__section2Mobile .slick-slide {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  width: fit-content!important;
}
.shipperProfile__section2Mobile .slick-track {
  align-items: center;
  display: flex;
}
.section2__slide .slick-slider .slick-list{
  width: 100%;
}
.shipperProfile__section2Mobile .pendingPaymentsCard__content {
  height: 49.938rem !important;
}
.shipperProfile__section3 .section3__group{
  flex-direction: column;
  align-items: center;
  padding: 0rem;
}
.shipperProfile__section3 .section3__percentages{
  width: 100%;
}
.shipperProfile__section3 .percentages__group--paragraph{
  font-size: 2.125rem;
  margin-bottom: 3.688rem;
}
.shipperProfile__section3 .percentages__group .slider{
  width: 50vw;
  height: 1rem;
  margin-bottom: 4.8rem;
  margin-top: 0.8rem;
}
.shipperProfile__section3 .percentages__group--votes{
  font-size: 2rem;
  min-width: 9.75rem;
  margin-bottom: 3.313rem;
}
.shipperProfile__section3 .percentages__group--votes span{
  font-size: 2.375rem;
}
.shipperProfile__section3 .section3__satisfaction--num{
  font-size: 5.938rem;
}
.shipperProfile__section3 .section3__satisfaction--text{
  font-size: 2.75rem;
}
.shipperProfile__section3 .percentages__group .slider::-webkit-slider-thumb {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border: solid 1px #ffffff;
  background-color: #7426fb;
  border-radius: 50%;
}
.companyReviewsCard {
  height: 75.875rem;
  border-radius: 4.625rem;
}
.companyReviewsCard__header {
  height: 6rem;
  padding: 0rem 3.25rem 0rem 3.875rem;
  font-size: 1.75rem;
  border-top-left-radius: 4.625rem;
  border-top-right-radius: 4.625rem;
}
.companyReviewsCard__happyIcon {
  width: 2.563rem;
  height: 2.563rem;
}
.companyReviewsCard__content{
  height: calc(75.875rem - 6rem);
  display: flex;
  flex-direction: column;
}
.companyReviewsCard__contentLeft,.companyReviewsCard__contentRight{
  width: 100%;
  height: 50%;
}
.companyReviewsCard__contentRight{
  display: flex;
  flex-direction: column-reverse;
 }
 .section1Right__shipperInformation{
  border-radius: 0.625rem;
 }
 .companyReviewsCard__contentRight--info{
  height: 6.75rem;
 }
 .companyReviewsCard__contentRight--paragraph{
  height: 28.625rem;
  font-size: 2.2rem;
    overflow-y: scroll;
 }
 .contentRight__info--left {
  font-size: 2.5rem;
}
.contentRight__info--right {
  font-size: 1.8rem;
}

}
@media only screen and (min-width:1800px){
  .shipperProfile nav{
    height:7.5rem;
  }
}