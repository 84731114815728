.dropdownmaker {
  display: flex;
  flex-direction: column;
  position: relative;
}
.dropdownmaker__select {
  width: 12.188rem;
  height: 3.125rem;
  border-radius: 25px;
  border: solid 2px #7977a4;
  background-color: transparent;
  color: #06016c;
  padding-left: 1.25rem;
  margin-top: 5px;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;

  -webkit-appearance: none;
  -moz-appearance: none;
}
.dropdownmaker__select--border {
  /*border: solid 2px rgba(121, 119, 164, 0.5);*/
  background-color: rgba(6, 1, 108, 0.03);
  border: none;
}
.dropdownmaker__select:focus {
  border: solid 2px #2699fb;
}
.dropdownmaker__option {
  color: #06016c;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
}

/*custom arrow*/
.dropdownmaker select::-ms-expand {
  display: none;
}
.dropdownmaker__arrow {
  color: #06016c;
  position: absolute;
  right: 1rem;
  bottom: 0.65rem;
  height: 1.6rem;
  width: 2.25rem;
  z-index: -1;
}

.dropdownmaker__label {
  font-family: SF Pro Display Regular;
  line-height: 1.33;
  color: #06016c;
  position: absolute;
  top: 1.32rem;
  font-size: 1.125rem;
  left: 1.5rem;
  display: block;
  z-index: -1;
  transition: all 0.15s ease-in-out 0s;
}
.dropdownmaker__select:focus ~ .dropdownmaker__label {
  top: -1.1em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
}
.dropdownmaker__label--show {
  top: -1.1em;
  left: 0rem;
  font-size: 1rem;
  color: #7977a4;
  line-height: 1.31;
  cursor: pointer;
}
