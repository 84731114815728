.dashboardNavbar {
  width: 25rem; /*400px*/
  background-color: #ffffff;
  font-family: SF Pro Display Regular;
  position: relative;
  box-shadow: 1.25rem 0 2.5rem 0 /* 20px 40px */ rgba(6, 1, 108, 0.1);
  height: calc(100vh - 7.5rem) /*calc(100vh - 120px)*/;
}

.userImage {
  width: 11.25rem; /* 180px */
  height: 11.25rem;
  object-fit: contain;
  /* box-shadow: 0 20px 40px 0 rgba(6, 1, 108, 0.1); */
  background-color: #584ffb;
  margin-top: 3.125rem; /* 50px */
  object-fit: cover;
  background-position: center;
  border-radius: 500px;
  background-size: cover;
  background-repeat: no-repeat;
}
.navbar__topSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.topSide__icon {
  margin-right: 1.063rem;
  width: 1.719rem;
  height: 1.612rem;
}
.navbar__topSide p {
  line-height: 1.2;
  font-size: 1.3125rem; /* 21px */
  font-weight: bold;
  color: #06016c;
  margin-top: 1.688rem; /* 27px */
  font-family: SFProDisplay-Bold;
  display: flex;
  align-items: center;
  line-height: 1.35;
}
.navbar__bottomSide {
  margin-top: 3.481rem /*55.7px*/;
  width: 100%;
}
.bottomSide__navmenu {
  margin-left: 3.75rem; /* 60px */
}
.bottomSide__navmenu li {
  list-style-type: none;
  text-decoration: none;
  font-size: 1.375rem; /* 22px */
  line-height: 1.36;
  color: #06016c;
  margin-bottom: 1.563rem; /* 25px */
  display: flex;
  align-items: center;
}
.bottomSide__navmenu li a {
  width: 100%;
  text-decoration: none;
  color: #06016c;
  display: flex;
  align-items: center;
}

.bottomSide__navmenu li a:hover,
.bottomSide__navmenu--active {
  background-color: rgba(119, 1, 252, 0.1);
  border-top-left-radius: 0.625rem; /*10px*/
  border-bottom-left-radius: 0.625rem;
  cursor: pointer;
}
.bottomSide__navmenu--active {
  border-right: 0.375rem /* 5px */ solid #7701fc;
}
.bottomSide__navmenu li a svg {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0rem 0.5905rem;
}
.navbar__logout {
  display: flex;
  align-items: center;
  padding-left: 5rem; /* 80px */
  /* margin-top: 196px;
    padding-bottom: 15px; */
  position: absolute;
  bottom: 2.875rem; /* 62px */
}
.navbar__logout svg {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0rem 0.5905rem;
}
.navbar__logout p {
  color: #06016c;
  font-size: 1.375rem; /* 22px */
  line-height: 1.36;
  margin-left: 1.5rem; /* 24px */
}
.listIcon {
  margin-right: 1.969rem; /* 31.5px */
  margin-left: 1.25rem;
}

@media only screen and (max-height:695px){  
  .navbar__logout {
    position: relative;
    bottom: 0rem;
}
}
@media only screen and (min-width:1520px) and (min-height:770px){  
  .navbar__logout {
    position: relative;
    bottom: 0rem;
}
}
@media only screen and (min-width:1286px) and (min-height:720px){
  .navbar__logout {
    position: relative;
    bottom: 0rem;
}
}