.shipmentView {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}
.shipmentView__main {
  display: flex;
  /* height: calc(100vh - 7.5rem); */
}
.shipmentView__content {
  width: calc(100% - 25rem);
  height: calc(100vh - 7.5rem);
  background-color: rgba(6, 1, 108, 0.05);
  padding-top: 6.063rem;
  padding-left: 6.438rem;
  padding-right: 16.625rem;
  position: relative;
  height: /*calc(100vh - 120px)*/ calc(100vh - 7.5rem);
  overflow-y: auto;
}
.shipmentView__content--bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.shipmentView__content--bottom .blueButton {
  width: 10.625rem;
  height: 2.5rem;
  margin: 0rem 0rem 20.188rem 0rem;
}
.shipmentView__paragraph {
  font-family: SF Pro Display Regular;
  font-size: 1.5rem;
  line-height: 1.33;
  color: #06016c;
  margin-top: 1.125rem;
  margin-bottom: 1.875rem;
}
html {
  overflow-x: hidden;
}

/*GLOBAL SLICK SLIDER*/
.slick-slide {
  margin-right: 60px;
  margin-right: 3.75rem;
}
.deliverySection{
  width: 100%;
  height: 4.5rem;
  border-radius: 1.25rem;
  box-shadow: 0 1.25rem 2.5rem 0 rgba(6, 1, 108, 0.05);
  background-color: #fff;
  font-family: SF Pro Display Regular;
  font-size: 1.125rem;
  line-height: 1.33;
  color: #7977a4;
  margin-top: 1.688rem;
  display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.625rem;
}
.shipmentView .Toastify__toast-container--top-right {
  top: 0rem;
  z-index: 999999999999;
  position: absolute;
}
@media only screen and (max-width:1020px){
  .shipmentView__content {
    padding-right: 6.625rem;
  }
}
@media only screen and (max-width:700px){
  .image-gallery-svg {
    width: 17px !important;
}
.shipmentViewCard__right {
  padding: 3.375rem 1.063rem 2.438rem 1.188rem;
  width: calc(100% - 32.44rem);
}
.shipmentViewCard__left {
  width: 32.44rem;
}
}
@media only screen and (max-width:650px){
  .getBid__button {
    height: 3rem;
  }
}
@media only screen and (max-width:600px){
  .shipmentViewCard__left {
    width: 29.44rem;
}
.shipmentViewCard__right {
  width: calc(100% - 29.44rem);
}
.shipmentPosition {
  height: 21.813rem;
}
.shipmentPosition__left {
  padding: 2.75rem 1.625rem 0rem 2.125rem;
}
}
@media only screen and (max-width:545px){
  .blueButton {
    height: 3.5rem;
  }
  .shipmentViewUserCard__rightButtons button {
    height: 3.38rem;
  }
  .shipment__whatsappIcon {
    width: 2.606rem;
  }
}
@media only screen and (max-width:525px){
  .shipmentViewUserCard__rightButtons button {
    width: 8.2rem;
  }
}
@media only screen and (max-width:500px){
  .shipmentViewUserCard__rightButtons button{
    width: 22.2rem;
    height: 9.38rem;
  }
}